import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import { StyledMDBCard } from '../../components/StyledCard';
import { getDataAction } from '../../actions';
import PartnerPanelTemplate from '../../templates/PartnerPanelTemplate';
import ImportantInfo from '../ImportantInfo';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

const StyledMDBIcon = styled(MDBIcon)`
  border-radius: 20px !important;
`;

class MainPanel extends Component {
  componentDidMount() {
    const { getData, token, userData } = this.props;
    if (!userData.id) {
      getData(token);
    }
  }

  // payInvoice = id => {};

  render() {
    const { userData } = this.props;
    const { partnerIncome } = userData;
    return (
      <PartnerPanelTemplate pageTitle="">
        <section>
          <MDBRow>
            <MDBCol xl="6" md="0" className="mb-5" />
            <MDBCol xl="6" md="12" className="mb-5">
              <StyledMDBCard cascade className="cascading-admin-card">
                <div className="admin-up d-flex align-items-center justify-content-center">
                  <StyledMDBIcon
                    icon="coins"
                    className="dark-purple mr-5 z-depth-2"
                    style={{ marginTop: '-40px' }}
                  />
                  <div className="data w-100 ">
                    <h3 className="font-weight-bold dark-grey-text text-left pt-3">
                      Saldo: {parseFloat(partnerIncome).toFixed(2)} zł
                    </h3>
                    <p className="text-left">Wpływy za pośrednictwem JAR</p>
                  </div>
                </div>
              </StyledMDBCard>
            </MDBCol>
          </MDBRow>
        </section>

        <ImportantInfo />
      </PartnerPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    userData: state.userData,
    errorMessage: state.error,
  };
};

export default connect(mapStateToProps, { getData: getDataAction })(ifAcceptRegulations(MainPanel));
