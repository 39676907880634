import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledMDBCard } from '../../components/StyledCard';
import UserPanelTemplate from '../../templates/UserPanelTemplate';
import { userGetPageAction } from '../../actions';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

class Support extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    const { getPage, token } = this.props;
    getPage(token, 2).then(page => {
      this.setState({
        text: page.content,
      });
    });
  }

  render() {
    const { text } = this.state;
    return (
      <UserPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5 p-5" dangerouslySetInnerHTML={{ __html: text }} />
        </section>
      </UserPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {
    getPage: userGetPageAction,
  },
)(ifAcceptRegulations(Support));
