import axios from 'axios';
import { GETINVOICES, GETDATA, TOKENEXPIRED, adminAPI } from './index';

export function getDataAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}user/data`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETDATA, userData: res.data.userData });
      return res.data.userData;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}

export function updateDataAction(token, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}user/data`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data;
    }
  };
}

export function getInvoicesAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}user/invoices`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETINVOICES, invoices: res.data.invoices });
      return res.data.userData;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}


export function payInvoiceAction(token, id, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}user/invoices/pay/${id}`, params, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}
