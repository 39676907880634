import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBNavbar,
  MDBRow,
  MDBView,
  ToastContainer,
} from 'mdbreact';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { resetAction } from '../actions';
import Logo from '../static/logo.png';
import Background from '../static/background.jpg';

const StyledImg = styled.img`
  height: 120px;
`;
const StyledWrapper = styled.div`
  background-image: url(${Background});
`;
const StyledMDBView = styled(MDBView)`
  background-image: url(${Background});
`;

class Thanks extends React.Component {
  state = {
    redirect: '',
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        redirect: '/panel',
      });
    }, 1000);
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <StyledWrapper className="classic-form-page" id="login">
        <MDBNavbar color="deep-purple darken-4" dark expand="md" fixed="top" scrolling transparent>
          <MDBContainer className="d-flex align-items-center justify-content-center">
            <StyledImg src={Logo} alt="" />
          </MDBContainer>
        </MDBNavbar>
        <StyledMDBView>
          <MDBMask className="d-flex justify-content-center align-items-center">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="9" xl="7" sm="12" className="mt-5 mx-auto">
                  <MDBCard style={{ borderRadius: '100px' }}>
                    <MDBCardBody className="d-flex align-items-center justify-content-center flex-column">
                      <div
                        className="form-header dark-purple px-5"
                        style={{ borderRadius: '100000px' }}
                      >
                        <h4>
                          <MDBIcon
                            icon="user"
                            className="mt-2 mb-2 mr-2 text-white font-weight-bold"
                          />
                          Dziękujemy za płatność
                        </h4>
                      </div>
                      <p>Za chwilę zostaniesz przekierowany do panelu</p>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </StyledMDBView>
        <ToastContainer hideProgressBar newestOnTop />
      </StyledWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    errorMessage: state.error,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { reset: resetAction },
  )(Thanks),
);
