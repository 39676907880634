import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'mdbreact';
import { createServiceAction } from '../../../actions';
import ServiceForm from './Form';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { StyledMDBCard } from '../../../components/StyledCard';

class ServiceCreate extends Component {
  state = {
    form: {
      data: {
        visible: 1,
        prices: [
          {
            price: 0,
            from: parseInt(new Date().getTime() / 1000, 0),
          },
        ],
      },
      errors: {},
    },
    redirect: null,
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeDate = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleVisibleChange = value => {
    this.setState(state => {
      const { form } = state;
      const newForm = { ...form };
      newForm.data.visible = value ? 1 : 0;
      return {
        form: newForm,
      };
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    const fd = new FormData();

    Object.keys(data).forEach(key => {
      fd.append(key, data[key]);
    });
    createItem(token, data).then(response => {
      if (response.id) {
        toast.success('Dodano usługę.');
        setTimeout(() => {
          this.setState({ redirect: `/panel/admin/uslugi` });
        }, 2000);
      } else {
        toast.error('Nie udało się dodać nowej usługi. Sprawdź błędy.');
        const newForm = { ...form };
        newForm.errors = response;

        this.setState({ form: newForm });
      }
    });
  };

  handleVatChanged = value => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.data.vat_id = value;
    this.setState({ form: newForm });
  };

  handleTypeChanged = value => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.data.type = value;
    this.setState({ form: newForm });
  };

  addPrice = () => {
    const { form } = this.state;
    const { data } = form;
    const { prices } = data;
    const newForm = { ...form };
    const newPrices = [...prices];

    newPrices.push({
      price: 0,
      from: parseInt(new Date().getTime() / 1000, 0),
    });

    newForm.data.prices = newPrices;
    this.setState({ form: newForm });
  };

  handleDeletePrice = deleteIndex => {
    const { form } = this.state;
    const { data } = form;
    const { prices } = data;
    const newForm = { ...form };
    let newPrices = [...prices];

    newPrices = newPrices.filter((value, index) => {
      return deleteIndex !== index;
    });

    newForm.data.prices = newPrices;
    this.setState({ form: newForm });
  };

  render() {
    const { form, redirect, date } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <AdminPanelTemplate>
        <section>
          <StyledMDBCard className="mb-5 p-4">
            <h3 className="py-2 color-dark-purple">Dodaj usługę</h3>
            <ServiceForm
              form={form}
              date={date}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onVisibleChange={this.handleVisibleChange}
              onChangeDate={this.handleChangeDate}
              onVatChanged={this.handleVatChanged}
              onAddPrice={this.addPrice}
              onDeletePrice={this.handleDeletePrice}
              onTypeChanged={this.handleTypeChanged}
            />
          </StyledMDBCard>
        </section>
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {
    createItem: createServiceAction,
  },
)(ServiceCreate);
