import React, { Component } from 'react';
import axios from 'axios';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBMask,
  MDBNavbar,
  MDBRow,
  MDBView,
} from 'mdbreact';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { adminAPI } from '../actions';
import Logo from '../static/logo.png';
import Background from '../static/background.jpg';

const StyledImg = styled.img`
  height: 120px;
`;
const StyledWrapper = styled.div`
  background-image: url(${Background});
`;
const StyledMDBView = styled(MDBView)`
  background-image: url(${Background});
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  :hover {
    color: #dadada;
  }
`;

class PrivacyPolicy extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    axios.get(`${adminAPI}privacy-policy`).then(response => {
      const { policy } = response.data;
      this.setState({
        text: policy.content,
      });
    });
  }

  render() {
    const { text } = this.state;
    return (
      <StyledWrapper className="classic-form-page" id="login">
        <MDBNavbar color="deep-purple darken-4" dark expand="md" fixed="top" scrolling transparent>
          <MDBContainer className="d-flex align-items-center justify-content-center">
            <StyledImg src={Logo} alt="" />
          </MDBContainer>
        </MDBNavbar>
        <StyledMDBView>
          <MDBMask className="d-flex justify-content-center align-items-center">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" lg="12" xl="12" sm="12" className="mt-5 mx-auto">
                  <MDBCard style={{ borderRadius: '100px' }}>
                    <MDBCardBody className="d-flex align-items-center justify-content-center flex-column">
                      <div
                        className="form-header dark-purple px-5"
                        style={{ borderRadius: '100000px' }}
                      >
                        <h4>
                          <MDBIcon
                            icon="user"
                            className="mt-2 mb-2 mr-2 text-white font-weight-bold"
                          />
                          Polityka prywatności
                        </h4>
                      </div>
                      <StyledLink to="/klient">Logowanie</StyledLink>
                      <div
                        style={{
                          color: '#ffffff',
                          height: '55vh',
                          overflow: 'scroll',
                          padding: '40px',
                        }}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </StyledMDBView>
      </StyledWrapper>
    );
  }
}

export default PrivacyPolicy;
