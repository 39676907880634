import React, { Component } from 'react';
import { MDBCardBody, MDBCol, MDBRow, MDBTable } from 'mdbreact';
import { connect } from 'react-redux';
import { StyledMDBCard } from '../../components/StyledCard';
import { StyledTr } from '../../components/StyledTr';
import { getDataAction } from '../../actions';
import UserPanelTemplate from '../../templates/UserPanelTemplate';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

class Services extends Component {
  componentDidMount() {
    const { getData, token, userData } = this.props;
    if (!userData.id) {
      getData(token);
    }
  }

  render() {
    const { userData } = this.props;
    return (
      <UserPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <h4 className="ml-4 mb-3">Twoje usługi</h4>
                  <MDBTable hover>
                    <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Usługa</th>
                        <th>Adres</th>
                        <th className="w-50">Opis</th>
                      </StyledTr>
                    </thead>
                    <tbody>
                      {userData.services.map(value => {
                        const { pivot, text, id, name } = value;
                        const { address } = pivot;
                        return (
                          <tr key={id} className="none-top-border">
                            <td>{name}</td>
                            <td>{address}</td>
                            <td>{text}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>

        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <h4 className="ml-4 mb-3">Inne usługi</h4>
                  <MDBTable hover>
                    <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Usługa</th>
                        <th className="w-75">Opis</th>
                      </StyledTr>
                    </thead>
                    <tbody>
                      {userData.otherServices.map(value => {
                        const { text, id, name } = value;
                        return (
                          <tr key={id} className="none-top-border">
                            <td>{name}</td>
                            <td>{text}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
      </UserPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    userData: state.userData,
    errorMessage: state.error,
  };
};

export default connect(
  mapStateToProps,
  { getData: getDataAction },
)(ifAcceptRegulations(Services));
