import React from 'react';
import { MDBDatePicker } from 'mdbreact';
import moment from 'moment';

const DatePicker = (props) => {
  return <MDBDatePicker
    locale={moment.locale('pl', {
      months: 'styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_pażdziernik_listopad_grudzień'.split('_'),
      monthsShort: 'st._lu._mar_kw._maj_czer_lip._sier_wrz._paż._lis._gru.'.split('_'),
      monthsParseExact: true,
      weekdays: 'wtorek_środa_czwartek_piątek_sobota_niedziela_poniedziałek'.split('_'),
      weekdaysShort: 'n._pon._wt._śr._czw._pt._so.'.split('_'),
      weekdaysMin: 'n._pon._wt._śr._czw._pt._so.'.split('_'),
      weekdaysParseExact: true,
      week: {
        dow: 1,
      },
    })}
    cancelLabel="Anuluj"
    {...props}/>;
};
export default DatePicker;
