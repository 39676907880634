import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

export default function(ComposedComponent) {
  class NotAuthentication extends Component {
    PropTypes = {
      router: PropTypes.object,
    };

    state = {
      redirect: null,
    };

    componentWillMount() {
      const { authenticated, scope } = this.props;
      if (authenticated) {

        if (scope.indexOf('user-dashboard') !== -1) {
          this.setState({
            redirect: '/panel',
          });
        } else if (scope.indexOf('partner-dashboard') !== -1) {
          this.setState({
            redirect: '/panel/partner',
          });
        } else if (scope.indexOf('admin-dashboard') !== -1) {
          this.setState({
            redirect: '/panel/admin',
          });
        } else {
          this.setState({
            redirect: '/klient',
          });
        }
      }
    }

    componentWillUpdate(nextProps) {
      console.log(this.props, nextProps);
      const { scope } = nextProps;
      if (nextProps.authenticated) {
        if (scope.indexOf('user-dashboard') !== -1) {
          this.setState({
            redirect: '/panel',
          });
        } else if (scope.indexOf('partner-dashboard') !== -1) {
          this.setState({
            redirect: '/panel/partner',
          });
        } else if (scope.indexOf('admin-dashboard') !== -1) {
          this.setState({
            redirect: '/panel/admin',
          });
        } else {
          this.setState({
            redirect: '/klient',
          });
        }
      }
    }

    render() {
      const { redirect } = this.state;
      if (redirect) return <Redirect to={redirect}/>;
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: !!state.token,
      token: state.token,
      scope: state.scope,
    };
  }

  return connect(mapStateToProps)(NotAuthentication);
}
