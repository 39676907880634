import React, {useEffect, useState} from 'react';
import {MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, toast} from "mdbreact";
import axios from "axios";
import {Button} from "../../../components/Button";
import {adminAPI} from "../../../actions";
import styled from 'styled-components';

const StyledMDBModalBody = styled(MDBModalBody)`
    display: flex;
    flex-direction: column;
`;

export const PiggyBankModal = ({id, modal, token, toggleModal, fetch}) => {
    const [piggyBank, setPiggyBank] = useState(null);
    const [invoiceAmount, setInvoiceAmount] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(()=>{
        if(modal){
            axios.get(
                `${adminAPI}admin/invoice/${id}/invoicePiggyBank`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            ).then((response) => {
                setPiggyBank(response.data.piggyBank);
                setInvoiceAmount(response.data.invoiceAmount);
                setStatus(response.data.status);
            })
        }
    },[token, modal, id])

    return <MDBModal
        isOpen={modal === `paypiggybank${id}`}
        toggle={toggleModal}
    >
        <MDBModalHeader toggle={toggleModal}>
            Zapłacić ze skarbonki?
        </MDBModalHeader>
        <StyledMDBModalBody>
            {status && (
                <span style={{color: status.color}}>{status.msg}</span>
            )}
            <span>Kwota w skarbonce: {piggyBank}</span>
            <span>Kwota do zapłaty: {invoiceAmount}</span>
        </StyledMDBModalBody>
        <MDBModalFooter>
            <Button
                className="btn-default"
                onClick={toggleModal}
            >
                Anuluj
            </Button>
            <Button
                className="btn-default"
                onClick={() => {
                    axios.post(
                            `${adminAPI}admin/invoice/${id}/payFromPiggyBank`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            },
                        )
                        .then(() => {
                            toast.success(
                                'Dodano płatność ze skarbonki',
                            );
                            fetch();
                            toggleModal(``);
                        })
                        .catch(e => {
                            toast.error(e.response.data.message);
                            toggleModal(``);
                        });
                }}
            >
                Tak
            </Button>
        </MDBModalFooter>
    </MDBModal>;
}
