import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {toast} from 'mdbreact';
import ServiceForm from './Form';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import {StyledMDBCard} from '../../../components/StyledCard';
import {createInvoiceAction, getInvoiceAction, updateInvoiceAction} from '../../../actions';

class AdminInvoiceEdit extends Component {
    state = {
        form: {
            data: {
                visible: 1,
                records: [{}],
            },
            errors: {},
        },
        itemId: null,
        redirect: null,
    };

    componentDidMount() {
      this.fetch();
    }

    fetch = () => {
        const {match, getItem, token} = this.props;
        this.setState({
            itemId: match.params.id,
        });

        getItem(token, match.params.id).then(item => {
            console.log(item.records);
            const {form} = this.state;
            const newForm = {...form};
            newForm.data.records = item.records;
            this.setState({form: newForm});
        });
    }

    handleChange = e => {
        const {form} = this.state;
        const {name, value} = e.target;
        const newForm = {...form};

        const splited = name.split('__');

        if (splited.length === 3) {
            if (newForm.data[splited[0]][splited[1]]) {
                newForm.data[splited[0]][splited[1]][splited[2]] = value;
            } else {
                newForm.data[splited[0]][splited[1]] = {};
            }
        } else {
            newForm.data[name] = value;
        }

        this.setState({form: newForm});
    };

    handleChangeName = (value, name) => {
        const {form} = this.state;
        const newForm = {...form};

        const splited = name.split('__');

        if (splited.length === 3) {
            if (newForm.data[splited[0]][splited[1]]) {
                newForm.data[splited[0]][splited[1]][splited[2]] = value;
            } else {
                newForm.data[splited[0]][splited[1]] = {};
            }
        } else {
            newForm.data[name] = value;
        }

        this.setState({form: newForm});
    };


    handleSubmit = e => {
        e.preventDefault();
        const {updateItem, token} = this.props;
        const {form, itemId} = this.state;
        const {data} = form;

        updateItem(token, itemId, data).then(response => {
            if (response === 'updated') {
                toast.success('Zaktualizowano dane');
                this.fetch();
            } else {
                toast.error('Nie udało się zapisać zmian. Sprawdź błędy.');
                const newForm = {...form};
                newForm.errors = response;
                this.setState({form: newForm});
            }
        });
    };


    handleAddRecord = () => {
        const {form} = this.state;
        const {data} = form;
        const {records} = data;
        const newForm = {...form};
        const newRecords = [...records];


        newRecords.push({});

        newForm.data.records = newRecords;
        this.setState({form: newForm});
    };

    handleRemoveRecord = () => {
        const {form} = this.state;
        const {data} = form;
        const {records} = data;
        const newForm = {...form};
        const newRecords = [...records];


        newRecords.pop();

        newForm.data.records = newRecords;
        this.setState({form: newForm});
    };

    render() {
        const {form, redirect, date} = this.state;
        if (redirect) {
            return <Redirect to={redirect}/>;
        }

        return (
            <AdminPanelTemplate>
                <section>
                    <StyledMDBCard className="mb-5 p-4">
                        <h3 className="py-2 color-dark-purple">Edytuj fakturę</h3>
                        <ServiceForm
                            form={form}
                            date={date}
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            onChangeName={this.handleChangeName}
                            addRecord={this.handleAddRecord}
                            removeRecord={this.handleRemoveRecord}
                            edit
                            canDelete
                            rerender={this.fetch}
                        />
                    </StyledMDBCard>
                </section>
            </AdminPanelTemplate>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.token,
    };
};

export default connect(
    mapStateToProps,
    {
        createItem: createInvoiceAction,
        getItem: getInvoiceAction,
        updateItem: updateInvoiceAction,
    },
)(AdminInvoiceEdit);
