import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  MDBContainer,
  MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle,
  MDBIcon,
  MDBNavbar,
  MDBNavbarNav, MDBNavItem,
  MDBSideNav,
  ToastContainer,
} from 'mdbreact';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { logOutAction } from '../actions';
import Logo from '../static/logo.png';
import Background from '../static/background.jpg';

const StyledMDBSideNav = styled(MDBSideNav)`
  color: #ffffff;
`;

class PanelTemplate extends Component {

  state = {
    toggleStateA: false,
    breakWidth: 1300,
    windowWidth: 0,
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  logOut = () => {
    const { onLogout, token } = this.props;
    onLogout(token);
  };

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    });

  handleToggleClickA = () => {
    this.setState((state) => (
      { toggleStateA: !state.toggleStateA }
    ));
  };


  render() {
    const { children, pageTitle, SideNav, TopMenu } = this.props;
    const { windowWidth, breakWidth, toggleStateA } = this.state;
    const navStyle = {
      paddingLeft:
        windowWidth > breakWidth ? '210px' : '16px',
    };

    const mainStyle = {
      margin: '0 3%',
      paddingTop: '5.5rem',
      paddingLeft:
        windowWidth > breakWidth ? '240px' : '0',
    };

    return (
      <>
        <div className="fixed-sn white-skin">
          <StyledMDBSideNav
            logo={Logo}
            triggerOpening={toggleStateA}
            breakWidth={breakWidth}
            bg={Background}
            fixed
          >
            {SideNav}
          </StyledMDBSideNav>
          <MDBNavbar style={navStyle} double expand="md" fixed="top" scrolling>
            <MDBNavbarNav left>
              <MDBNavItem>
                <div
                  onClick={this.handleToggleClickA}
                  key="sideNavToggleA"
                  style={{
                    lineHeight: '32px',
                    marginRight: '1em',
                    verticalAlign: 'middle',
                  }}
                >
                  <MDBIcon icon="bars" color="white" size="2x"/>
                </div>
              </MDBNavItem>
              {pageTitle && (
                <MDBNavItem className="d-none d-md-inline" style={{ paddingTop: 5 }}>
                  {pageTitle}
                </MDBNavItem>
              )}
            </MDBNavbarNav>
            <MDBNavbarNav right expand="sm" style={{ flexDirection: 'row' }}>
              {TopMenu}

              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="user"/> <span
                  className="d-none d-md-inline ml-2">Profil</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu right style={{ minWidth: '200px' }}>
                  <MDBDropdownItem onClick={this.logOut}>Wyloguj</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarNav>
          </MDBNavbar>
          <main style={mainStyle}>
            <MDBContainer fluid className="mt-3">
              {children}
              <ToastContainer hideProgressBar newestOnTop/>
            </MDBContainer>
          </main>
        </div>
      </>
    );
  }
}

PanelTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ token, scope }) => {
  return {
    token,
    scope,
  };
};

export default connect(
  mapStateToProps,
  { onLogout: logOutAction },
)(PanelTemplate);
