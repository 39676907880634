import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { StyledMDBCard } from '../../components/StyledCard';
import UserPanelTemplate from '../../templates/UserPanelTemplate';
import { adminAPI } from '../../actions';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

class PrivacyPolicy extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    const { token } = this.props;

    axios
      .get(`${adminAPI}privacy-policy`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { policy } = response.data;
        this.setState({
          text: policy.content,
        });
      });
  }

  render() {
    const { text } = this.state;
    return (
      <UserPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5 p-5" dangerouslySetInnerHTML={{ __html: text }} />
        </section>
      </UserPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {},
)(ifAcceptRegulations(PrivacyPolicy));
