import React, { Component } from 'react';
import {
  MDBBtnFixed,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSelect,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { connect } from 'react-redux';
import { Button } from '../../../components/Button';
import { getClientsAllAction } from '../../../actions';
import styled from 'styled-components';
import {deleteServiceInvoiceAction} from "../../../actions/invoiceActions";

const RemoveButtonWrapper = styled.div`
  .fixed-action-btn{
    right: 100px !important;
  }
`

class InvoiceForm extends Component {
  state = {
    activeItem: '1',
    options: [],
  };

  componentDidMount() {
    const { getClientsAll, token } = this.props;
    getClientsAll(token).then(() => {
      const { clientsAll } = this.props;
      const newOptions = clientsAll.map(item => {
        return {
          value: item.id,
          text: item.name,
        };
      });

      this.setState({
        options: newOptions,
      });
    });
  }

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem, options } = this.state;
    const { form, onSubmit, onChange, onChangeName, addRecord, removeRecord, edit, canDelete } = this.props;
    const { info, records } = form.data;
    const { errors } = form;

    const { info: info1, user_id: user_id1 } = errors;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              to="/asd1"
              active={activeItem === '1'}
              onClick={this.toggle('1')}
              role="tab"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              {edit || (
                <>
                  <MDBCol lg={6}>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Informacje dodatkowe"
                          name="info"
                          className={info1 === undefined ? '' : 'is-invalid'}
                          value={info}
                          onChange={onChange}
                        >
                          {info1 !== undefined && (
                            <div className="invalid-feedback">{info1[0]}</div>
                          )}
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <MDBSelect
                          options={options}
                          selected="Wybierz klienta"
                          label="Wybierz klienta"
                          getValue={value => {
                            onChangeName(value[0], `user_id`);
                          }}
                        />

                        {user_id1 !== undefined && (
                          <div className="invalid-feedback">{user_id1[0]}</div>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </>
              )}

              {Array(records.length)
                .fill(null)
                .map((value, index) => {
                  return (
                    <MDBCol lg={12}>
                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            label="Tytuł"
                            name="name"
                            required
                            value={records[index].name}
                            onChange={e => {
                              onChangeName(e.target.value, `records__${index}__name`);
                            }}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            label="Jednostka"
                            name="unit"
                            required
                            value={records[index].unit}
                            onChange={e => {
                              onChangeName(e.target.value, `records__${index}__unit`);
                            }}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            label="Ilość"
                            name="quantity"
                            required
                            value={records[index].quantity}
                            type="number"
                            min="1"
                            onChange={e => {
                              onChangeName(e.target.value, `records__${index}__quantity`);
                            }}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            label="Cena netto"
                            name="net"
                            required
                            value={records[index].net}
                            type="number"
                            min="1"
                            step="0.01"
                            onChange={e => {
                              onChangeName(e.target.value, `records__${index}__net`);
                            }}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            label="Stawka VAT"
                            name="vat"
                            required
                            value={records[index].vat}
                            type="number"
                            min="0"
                            onChange={e => {
                              onChangeName(e.target.value, `records__${index}__vat`);
                            }}
                          />
                        </MDBCol>
                        {canDelete === true && (
                            <div
                                style={{ cursor: 'pointer' }}
                                role="button"
                                onClick={() => {
                                  if(window.confirm(`Czy na pewno chcesz usunąć ${  records[index].name}`)){
                                    const { token, rerender } = this.props;
                                    deleteServiceInvoiceAction(token, records[index].id).then(() => {
                                      rerender()
                                    })
                                  }
                                }}
                                className="d-flex align-items-center justify-content-center flex-column color-dark-purple"
                            >
                              <MDBIcon icon="trash-alt" size="2x" />
                              <p>Usuń</p>
                            </div>
                        )}
                      </MDBRow>
                    </MDBCol>
                  );
                })}
            </MDBRow>

            {records.length > 1 && (
                <RemoveButtonWrapper>
                  <MDBBtnFixed
                      onClick={removeRecord}
                      floating
                      size="lg"
                      color="dark-purple"
                      icon="minus"
                  />
                </RemoveButtonWrapper>
            )}

            <MDBBtnFixed
              onClick={addRecord}
              floating
              size="lg"
              color="dark-purple"
              icon="plus"
            />
          </MDBTabPane>
        </MDBTabContent>

        <Button className="dark-purple" type="submit" color="dark-purple">
          {edit ? 'Zapisz zmiany' : 'Dodaj'}
        </Button>
      </form>
    );
  }
}

InvoiceForm.defaultProps = {};

InvoiceForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
    clientsAll: state.clientsAll,
  };
};

export default connect(mapStateToProps, { getClientsAll: getClientsAllAction })(InvoiceForm);
