import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  MDBBadge,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavItem,
  MDBNavLink,
  MDBSideNavLink,
  MDBSideNavNav,
} from 'mdbreact';
import PanelTemplate from './PanelTemplate';
import { deleteNotificationAction, getNotificationsAction } from '../actions';

class UserPanelTemplate extends Component {
  componentDidMount() {
    this.fetch();
    // setInterval(this.fetch, 5000);
  }

  fetch = () => {
    const { token, getItems } = this.props;
    getItems(token);
  };

  render() {
    const { children, notifications } = this.props;
    const SideNavChilds = [
      <p className="text-center">PANEL KLIENTA</p>,
      <MDBSideNavLink topLevel exact to="/panel">
        <MDBIcon fixed icon="th-large mr-2" />
        START
      </MDBSideNavLink>,
      <MDBSideNavLink topLevel exact to="/rozliczenia">
        <MDBIcon fixed icon="credit-card mr-2" />
        ROZLICZENIA
      </MDBSideNavLink>,
      <MDBSideNavLink topLevel exact to="/uslugi">
        <MDBIcon fixed icon="stream mr-2" />
        USŁUGI
      </MDBSideNavLink>,
      <MDBSideNavLink topLevel exact to="/dane">
        <MDBIcon fixed icon="user mr-2" />
        TWOJE DANE
      </MDBSideNavLink>,
    ];
    // eslint-disable-next-line react/no-array-index-key
    const SideNav = (
      <MDBSideNavNav>
        {SideNavChilds.map((item, index) => (
          <Fragment key={`${index}-index`}>{item}</Fragment>
        ))}
      </MDBSideNavNav>
    );

    const TopMenu = (
      <>
        <MDBDropdown>
          <MDBDropdownToggle nav caret>
            {notifications.length > 0 && (
              <MDBBadge color="dark-purple" pill className="mr-2 dark-purple">
                {notifications.length}
              </MDBBadge>
            )}
            <MDBIcon icon="bell" /> <span className="d-none d-md-inline">Powiadomienia</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu
            right
            style={{ minWidth: '400px', maxHeight: '500px', overflow: 'scroll' }}
          >
            {notifications.map(item => {
              return (
                <MDBDropdownItem href="#!">
                  <MDBIcon icon={item.icon || 'money-bill-alt'} className="mr-2" />
                  {item.title}

                  <MDBIcon
                    onClick={() => {
                      const { deleteItem, token } = this.props;
                      deleteItem(token, item.id).then(() => {
                        this.fetch();
                      });
                    }}
                    icon="times"
                    style={{ color: 'red' }}
                    className="float-right"
                  />

                  <span className="float-right">
                    <MDBIcon icon="clock" /> {item.time}
                  </span>
                </MDBDropdownItem>
              );
            })}
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBNavItem>
          <MDBNavLink to="/kontakt">
            <MDBIcon icon="envelope" />
            <span className="d-none d-md-inline ml-1">Kontakt</span>
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink to="/wsparcie">
            <MDBIcon icon="comments" />
            <span className="d-none d-md-inline ml-1">Wsparcie</span>
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink to="/rodo">
            <MDBIcon icon="comments" />
            <span className="d-none d-md-inline ml-1">RODO</span>
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink to="/panel/regulamin">
            <MDBIcon icon="comments" />
            <span className="d-none d-md-inline ml-1">Regulamin</span>
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink to="/panel/polityka-prywatnosci">
            <MDBIcon icon="comments" />
            <span className="d-none d-md-inline ml-1">Polityka prywatności</span>
          </MDBNavLink>
        </MDBNavItem>
      </>
    );

    return (
      <PanelTemplate SideNav={SideNav} TopMenu={TopMenu}>
        {children}
      </PanelTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    notifications: state.notifications,
  };
}

export default connect(
  mapStateToProps,
  {
    getItems: getNotificationsAction,
    deleteItem: deleteNotificationAction,
  },
)(UserPanelTemplate);
