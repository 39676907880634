import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'mdbreact';
import moment from 'moment';
import { getServiceAction, updateServiceAction } from '../../../actions';
import ServiceForm from './Form';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { StyledMDBCard } from '../../../components/StyledCard';

class ServiceEdit extends Component {
  state = {
    form: {
      data: {
        visible: 1,
        prices: [],
        vat_id: 0,
      },
      errors: {},
    },
    redirect: null,
    itemId: 0,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { match, getItem, token } = this.props;
    this.setState({
      itemId: match.params.id,
    });

    getItem(token, match.params.id).then(card => {
      const { form } = this.state;
      const newForm = { ...form };

      Object.keys(card).forEach(key => {
        if (key === 'prices') {
          newForm.data[key] = card[key].map(value => {
            return {
              ...value,
              from:
                moment(value.from)
                  .toDate()
                  .getTime() / 1000,
            };
          });
        } else {
          newForm.data[key] = card[key];
        }
      });

      this.setState({ form: newForm });
    });
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeDate = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleVisibleChange = value => {
    this.setState(state => {
      const { form } = state;
      const newForm = { ...form };
      newForm.data.visible = value ? 1 : 0;
      return {
        form: newForm,
      };
    });
  };

  handleVatChanged = value => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.data.vat_id = value;
    this.setState({ form: newForm });
  };

  handleTypeChanged = value => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.data.type = value;
    this.setState({ form: newForm });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { updateItem, token } = this.props;
    const { form, itemId } = this.state;
    const { data } = form;

    updateItem(token, itemId, data).then(response => {
      if (response === 'updated') {
        toast.success('Zaktualizowano dane');
      } else {
        toast.error('Nie udało się zapisać zmian. Sprawdź błędy.');
        const newForm = { ...form };
        newForm.errors = response;
        this.setState({ form: newForm });
      }
    });
  };

  render() {
    const { form, redirect, date } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <AdminPanelTemplate>
        <section>
          <StyledMDBCard className="mb-5 p-4">
            <h3 className="py-2 color-dark-purple">Edytuj usługę</h3>
            <ServiceForm
              form={form}
              date={date}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onVisibleChange={this.handleVisibleChange}
              onChangeDate={this.handleChangeDate}
              onVatChanged={this.handleVatChanged}
              edit
              onTypeChanged={this.handleTypeChanged}
            />
          </StyledMDBCard>
        </section>
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {
    getItem: getServiceAction,
    updateItem: updateServiceAction,
  },
)(ServiceEdit);
