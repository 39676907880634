import React, { useEffect, useState } from 'react';
import {
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBRow,
  MDBTable,
} from 'mdbreact';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { StyledMDBCard } from '../../../components/StyledCard';
import { Button } from '../../../components/Button';
import { adminAPI } from '../../../actions';
import { StyledTr } from '../../../components/StyledTr';
import DatePicker from '../../../components/DatePicker';

export const List = () => {
  const [search, setSearch] = useState('');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [page, setPage] = useState(1);
  const [reminds, setReminds] = useState({ data: [] });

  const token = useSelector(state => state.token);

  const fetch = (getPage = 0) => {
    axios
      .get(`${adminAPI}admin/reminds`, {
        params: {
          search,
          from,
          to,
          page: getPage ?? page,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setReminds(response.data.reminds);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetch(page);
    // eslint-disable-next-line
  }, [page, from, to, search]);

  const pagination = () => {
    const { last_page } = reminds;
    const pagesNum = last_page || 1;

    const pages = [];

    let isBreak = true;

    for (let i = 1; i <= pagesNum; i++) {
      if (i < 4 || i > pagesNum - 3 || (i > page - 2 && i < page + 2)) {
        isBreak = true;
        if (page === i) {
          pages.push(
            <MDBPageItem key={i} active>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  setPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        } else {
          pages.push(
            <MDBPageItem key={i}>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  setPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        }
      } else if (isBreak) {
        isBreak = false;
        pages.push(
          <MDBPageItem key={i}>
            <MDBPageNav>...</MDBPageNav>
          </MDBPageItem>,
        );
      }
    }

    return (
      <MDBPagination circle className="my-4 float-right">
        <MDBPageItem>
          <MDBPageNav
            className="page-link"
            aria-label="Previous"
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Poprzednia</span>
          </MDBPageNav>
        </MDBPageItem>
        {pages}
        <MDBPageItem>
          <MDBPageNav
            className="page-link"
            aria-label="Next"
            onClick={() => {
              if (page < pagesNum) {
                setPage(page + 1);
              }
            }}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Następna</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    );
  };

  return (
    <AdminPanelTemplate pageTitle="">
      <section>
        <StyledMDBCard className="mb-5">
          <MDBRow className="my-4">
            <MDBCol md="12">
              <MDBCardBody className="pt-0">
                <h3 className="py-2 color-dark-purple">Lista przypomnień</h3>
                <div>
                  <Button
                    color="dark-purple"
                    onClick={() => {
                      axios
                        .get(`${adminAPI}admin/reminds/export`, {
                          params: {
                            search,
                            from,
                            to,
                          },
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          responseType: 'blob',
                        })
                        .then(response => {
                          const { data } = response;
                          const url = window.URL.createObjectURL(
                            new Blob([data], {
                              type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            }),
                          );
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', `exportFV.xls`);
                          document.body.appendChild(link);
                          link.click();
                        });
                    }}
                  >
                    <MDBIcon icon="file-pdf" /> EXPORTUJ DO XLS
                  </Button>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <DatePicker
                    getValue={value => {
                      setFrom(value.getTime() / 1000);
                    }}
                  />
                  <DatePicker
                    getValue={value => {
                      setTo(value.getTime() / 1000);
                    }}
                  />

                  <MDBInput
                    label="Szukaj..."
                    name="search"
                    value={search}
                    onChange={e => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>

                <MDBTable hover>
                  <thead>
                    <StyledTr className="rgba-stylish-strong white-text">
                      <th>Użytkownik</th>
                      <th>Treść</th>
                      <th>Data wysłania</th>
                    </StyledTr>
                  </thead>
                  <tbody>
                    {reminds.data.map(({ content, user, created_at: createdAt }) => (
                      <tr className="none-top-border">
                        <td style={{ verticalAlign: 'middle' }}>{user ? user.name : ''}</td>
                        <td style={{ verticalAlign: 'middle' }}>{content}</td>
                        <td style={{ verticalAlign: 'middle' }}>{createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </MDBTable>
                {reminds.last_page && pagination()}
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </StyledMDBCard>
      </section>
    </AdminPanelTemplate>
  );
};
