import axios from 'axios';
import { GETPARTNERSALL,TOKENEXPIRED, adminAPI, GETPARTNERS, GETPARTNER } from './index';

const url = `admin/partner`;

export function getPartnersAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(res.data.partners);
      dispatch({ type: GETPARTNERS, partners: res.data.partners });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}
export function getPartnersAllAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/all`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(res.data.partners);
      dispatch({ type: GETPARTNERSALL, partners: res.data.partners });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function createPartnerAction(token, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.user;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function getPartnerAction(token, id, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(res.data.user);
      dispatch({ type: GETPARTNER, partner: res.data.user });
      return res.data.user;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return [];
    }
  };
}

export function updatePartnerAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.put(`${adminAPI}${url}/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function deletePartnerAction(token, id) {
  return async dispatch => {
    try {
      const res = await axios.delete(`${adminAPI}${url}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}


export function createPartnersContractAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${id}/contract`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.contract;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}


export function deletePartnersContractAction(token, id, contractId) {
  return async dispatch => {
    try {
      const res = await axios.delete(`${adminAPI}${url}/${id}/contract/${contractId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}
