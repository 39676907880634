import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button } from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';

class InfoForm extends Component {
  state = {
    activeItem: '1',
  };

  componentDidMount() {}

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange, onChangeName, edit } = this.props;
    const { title, date, contents } = form.data;
    const { errors } = form;
    const { contents: contents1, title: title1 } = errors;
    const { date: date1 } = errors;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              to="/asd1"
              active={activeItem === '1'}
              onClick={this.toggle('1')}
              role="tab"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Tytuł"
                      name="title"
                      className={title1 === undefined ? '' : 'is-invalid'}
                      value={title}
                      onChange={onChange}
                    >
                      {title1 !== undefined && <div className="invalid-feedback">{title1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    {edit && date && (
                      <DatePicker
                        valueDefault={moment(date).toDate()}
                        getValue={value => {
                          onChangeName(value.getTime() / 1000, `date`);
                        }}
                      />
                    )}

                    {edit || (
                      <DatePicker
                        getValue={value => {
                          onChangeName(value.getTime() / 1000, `date`);
                        }}
                      />
                    )}

                    {date1 !== undefined && (
                      <div className="invalid-feedback d-block">{date1[0]}</div>
                    )}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Treść"
                      name="contents"
                      className={contents1 === undefined ? '' : 'is-invalid'}
                      value={contents}
                      type="textarea"
                      onChange={onChange}
                    >
                      {contents1 !== undefined && (
                        <div className="invalid-feedback">{contents1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <Button className="dark-purple" type="submit" color="dark-purple">
          Zapisz
        </Button>
      </form>
    );
  }
}

InfoForm.defaultProps = {};

InfoForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(InfoForm);
