import React, { Component } from 'react';
import {
  MDBBadge,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBSideNavLink,
  MDBSideNavNav,
} from 'mdbreact';
import { connect } from 'react-redux';
import PanelTemplate from './PanelTemplate';
import { deleteNotificationAction, getNotificationsAction } from '../actions';
import { hasScope } from '../helpers';

class AdminPanelTemplate extends Component {
  componentDidMount() {
    this.fetch();
    // setInterval(this.fetch, 5000);
  }

  fetch = () => {
    const { token, getItems } = this.props;
    getItems(token);
  };

  render() {
    const { children, notifications, scope } = this.props;
    const SideNavChilds = [
      <p className="text-center">PANEL ADMINISTRATORA</p>,
      <MDBSideNavLink topLevel exact to="/panel/admin">
        <MDBIcon fixed icon="th-large mr-2"/>
        START
      </MDBSideNavLink>,
    ];

    if (hasScope(scope, 'admin-services')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/uslugi">
          <MDBIcon fixed icon="stream mr-2"/>
          USŁUGI
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-invoices')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/faktury">
          <MDBIcon fixed icon="file-alt mr-2"/>
          FAKTURY KLIENTÓW
        </MDBSideNavLink>,
      );
    }
      if (hasScope(scope, 'admin-invoices')) {
          SideNavChilds.push(
              <MDBSideNavLink topLevel to="/panel/admin/export">
                  <MDBIcon fixed icon="file-alt mr-2"/>
                  INSERT GT
              </MDBSideNavLink>,
          );
      }
    if (hasScope(scope, 'admin-clients')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/klienci">
          <MDBIcon fixed icon="users mr-2"/>
          KLIENCI
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-partners')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/partnerzy">
          <MDBIcon fixed icon="building mr-2"/>
          PARTNERZY
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-reports')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel exact to="/panel/admin/raporty">
          <MDBIcon fixed icon="file-medical-alt mr-2"/>
          RAPORTY
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-own-reports')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel exact to="/panel/admin/raporty/wlasne">
          <MDBIcon fixed icon="file-medical-alt mr-2"/>
          RAPORTY WŁASNE
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-admins')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/administratorzy">
          <MDBIcon fixed icon="user-shield mr-2"/>
          ADMINISTRATORZY
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-infos')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/informacje">
          <MDBIcon fixed icon="info-circle mr-2"/>
          INFORMACJE
        </MDBSideNavLink>,
      );
    }
    if (hasScope(scope, 'admin-sites')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/strony">
          <MDBIcon fixed icon="file-word mr-2"/>
          STRONY
        </MDBSideNavLink>,
      );
    }

    SideNavChilds.push(
      <MDBSideNavLink topLevel to="/panel/admin/przypomnienia">
        <MDBIcon fixed icon="bell mr-2"/>
        PRZYPOMNIENIA
      </MDBSideNavLink>,
    );

    if (hasScope(scope, 'admin-logs')) {
      SideNavChilds.push(
        <MDBSideNavLink topLevel to="/panel/admin/logi">
          <MDBIcon fixed icon="file-word mr-2"/>
          LOGI
        </MDBSideNavLink>,
      );
    }

    SideNavChilds.push(
      <MDBSideNavLink topLevel to="/panel/admin/regulaminy">
        <MDBIcon fixed icon="file-word mr-2"/>
        REGULAMINY
      </MDBSideNavLink>,
    );
    SideNavChilds.push(
      <MDBSideNavLink topLevel to="/panel/admin/rodo">
        <MDBIcon fixed icon="file-word mr-2"/>
        RODO
      </MDBSideNavLink>,
    );
    SideNavChilds.push(
      <MDBSideNavLink topLevel to="/panel/admin/polityka-prywatnosci">
        <MDBIcon fixed icon="file-word mr-2"/>
        POLITYKA PRYWATNOŚCI
      </MDBSideNavLink>,
    );


    const SideNav = <MDBSideNavNav>{SideNavChilds.map(item => item)}</MDBSideNavNav>;

    const TopMenu = (
      <>
        <MDBDropdown>
          <MDBDropdownToggle nav caret>
            {notifications.length > 0 && (
              <MDBBadge color="dark-purple" pill className="mr-2 dark-purple">
                {notifications.length}
              </MDBBadge>
            )}
            <MDBIcon icon="bell"/> <span className="d-none d-md-inline">Powiadomienia</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu
            right
            style={{ minWidth: '400px', maxHeight: '500px', overflow: 'scroll' }}
          >
            {notifications.map(item => {
              return (
                <MDBDropdownItem href="#!">
                  <MDBIcon icon={item.icon || 'money-bill-alt'} className="mr-2"/>
                  {item.title}

                  <MDBIcon
                    onClick={() => {
                      const { deleteItem, token } = this.props;
                      deleteItem(token, item.id).then(() => {
                        this.fetch();
                      });
                    }}
                    icon="times"
                    style={{ color: 'red' }}
                    className="float-right"
                  />

                  <span className="float-right">
                    <MDBIcon icon="clock"/> {item.time}
                  </span>
                </MDBDropdownItem>
              );
            })}
          </MDBDropdownMenu>
        </MDBDropdown>
      </>
    );

    return (
      <PanelTemplate SideNav={SideNav} TopMenu={TopMenu}>
        {children}
      </PanelTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    notifications: state.notifications,
    scope: state.scope,
  };
}

export default connect(
  mapStateToProps,
  {
    getItems: getNotificationsAction,
    deleteItem: deleteNotificationAction,
  },
)(AdminPanelTemplate);
