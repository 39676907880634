import axios from 'axios';
import { GETNOTIFICATIONS, TOKENEXPIRED, adminAPI } from './index';

const url = `notifications`;

export function getNotificationsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETNOTIFICATIONS, notifications: res.data.notifications });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}
export function deleteNotificationAction(token, id) {
  return async dispatch => {
    try {
      const res = await axios.delete(`${adminAPI}${url}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}
