import React, {Component} from 'react';
import {
    MDBCardBody,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBPageItem,
    MDBPageNav,
    MDBPagination,
    MDBRow,
    MDBTable,
} from 'mdbreact';
import {connect} from 'react-redux';
import _ from 'lodash';
import {StyledMDBCard} from '../../../components/StyledCard';
import {StyledTr} from '../../../components/StyledTr';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import {getExportsAction} from '../../../actions';
import {Button} from "../../../components/Button";

class ExportList extends Component {
    state = {
        page: 1,
        perPage: 10,
        search: '',
        modal: false,
    };

    search = _.debounce(() => {
        this.fetch();
    }, 1000);

    componentDidMount() {
        this.fetch();
    }

    fetch = (getPage = 0) => {
        const {getData, token} = this.props;
        const {page, perPage, search} = this.state;
        if (getPage) {
            getData(token, {page: getPage, perPage, search});
        } else {
            getData(token, {page, perPage, search});
        }
    };

    getPrevPage = () => {
        const {page} = this.state;
        if (page > 1) {
            this.setState({
                page: page - 1,
            });
            this.fetch(page - 1);
        }
    };

    getNextPage = () => {
        const {page} = this.state;
        const {items} = this.props;
        if (page < items.last_page) {
            this.setState({
                page: page + 1,
            });
            this.fetch(page + 1);
        }
    };

    getBuildingsPage = newPage => {
        const {page} = this.state;
        if (page !== newPage) {
            this.setState({
                page: newPage,
            });

            this.fetch(newPage);
        }

    };

    toggle = (modal = null) => {
        this.setState({
            modal
        });
    };

    toJson = (value) => {
        try {
            return JSON.stringify(JSON.parse(value), null, 2)
        } catch (e) {

          return value;
        }
    }


    pagination = () => {
        const {items} = this.props;
        const {page} = this.state;
        const {last_page} = items;
        const pagesNum = last_page || 1;


        const pages = [];

        let isBreak = true;

        for (let i = 1; i <= pagesNum; i++) {
            if ((i < 4) || (i > pagesNum - 3) || (i > page - 2 && i < page + 2)) {
                isBreak = true;
                if (page === i) {
                    pages.push(
                        <MDBPageItem key={i} active>
                            <MDBPageNav
                                className="page-link"
                                onClick={() => {
                                    this.getBuildingsPage(i);
                                }}
                            >
                                {i}
                            </MDBPageNav>
                        </MDBPageItem>,
                    );
                } else {
                    pages.push(
                        <MDBPageItem key={i}>
                            <MDBPageNav
                                className="page-link"
                                onClick={() => {
                                    this.getBuildingsPage(i);
                                }}
                            >
                                {i}
                            </MDBPageNav>
                        </MDBPageItem>,
                    );
                }
            } else if (isBreak) {
                isBreak = false;
                pages.push(
                    <MDBPageItem key={i}>
                        <MDBPageNav>
                            ...
                        </MDBPageNav>
                    </MDBPageItem>,
                );
            }

        }

        return (
            <MDBPagination circle className="my-4 float-right">
                <MDBPageItem>
                    <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Poprzednia</span>
                    </MDBPageNav>
                </MDBPageItem>
                {pages}
                <MDBPageItem>
                    <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Następna</span>
                    </MDBPageNav>
                </MDBPageItem>
            </MDBPagination>
        );
    };

    render() {
        const {search, modal} = this.state;
        const {items} = this.props;
        return (
            <AdminPanelTemplate pageTitle="">
                <section>
                    <StyledMDBCard className="mb-5">
                        <MDBRow className="my-4">
                            <MDBCol md="12">
                                <MDBCardBody className="pt-0">

                                    <h3 className="py-2 color-dark-purple">Export</h3>

                                    <div>
                                        <MDBInput
                                            label="Szukaj..."
                                            name="search"
                                            value={search}
                                            getValue={this.search}
                                            onChange={(e) => {
                                                this.setState({search: e.target.value});
                                            }}
                                        />
                                    </div>

                                    <MDBTable hover>
                                        <thead>
                                        <StyledTr className="rgba-stylish-strong white-text">
                                            <th>ID</th>
                                            <th>Nazwa</th>
                                            <th>Zrobione</th>
                                            <th>Do zrobienia</th>
                                            <th>Nie udane</th>
                                            <th>Status</th>
                                            <th>Zakres</th>
                                            <th>Utworzony</th>
                                            <th>Akcje</th>
                                        </StyledTr>
                                        </thead>
                                        <tbody>

                                        {items.data.map((value) => {
                                            const {
                                                id,
                                                name,
                                                done,
                                                to_do,
                                                failed,
                                                statusName,
                                                start,
                                                end,
                                                created_at,
                                                message
                                            } = value;
                                            return <>
                                                <tr>
                                                    <td style={{verticalAlign: 'middle'}}>{id}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{name}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{done}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{to_do}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{failed}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{statusName ?? 'brak'}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{`${start} - ${end}`}</td>
                                                    <td style={{verticalAlign: 'middle'}}>{created_at}</td>
                                                    <td style={{verticalAlign: 'middle'}}>
                                                        <Button
                                                            title="Wyślij do Insert"
                                                            pay
                                                            onClick={() => {
                                                                this.toggle(`insert${id}`);
                                                            }}
                                                        >
                                                            <MDBIcon icon="file-export"/>
                                                        </Button>
                                                        <MDBModal
                                                            isOpen={modal === `insert${id}`}
                                                            toggle={this.toggle}
                                                        >
                                                            <MDBModalHeader toggle={this.toggle}>
                                                                Status
                                                            </MDBModalHeader>
                                                            <MDBModalBody>
                                                                <div>
                                                                    {message?.map(item => (
                                                                        <div>
                                                                            {item.name} - {item.message}
                                                                            <br/>
                                                                            {item.log !== "" && (
                                                                                <pre>{
                                                                                    this.toJson(item.log)
                                                                                }</pre>
                                                                            )}

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </MDBModalBody>
                                                            <MDBModalFooter>
                                                                <Button
                                                                    className="btn-default"
                                                                    onClick={this.toggle}
                                                                >
                                                                    Anuluj
                                                                </Button>
                                                            </MDBModalFooter>
                                                        </MDBModal>
                                                    </td>
                                                </tr>
                                            </>;
                                        })}
                                        </tbody>
                                    </MDBTable>
                                    {this.pagination()}
                                </MDBCardBody>
                            </MDBCol>
                        </MDBRow>
                    </StyledMDBCard>
                </section>

            </AdminPanelTemplate>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.token,
        items: state.logs,
    };
};

export default connect(
    mapStateToProps,
    {
        getData: getExportsAction,
    },
)(ExportList);

