import axios from 'axios';
import {
  getDataAction,
  updateDataAction,
  getInvoicesAction,
  payInvoiceAction,
} from './userActions';
import {
  getServicesAction,
  getServiceAction,
  createServiceAction,
  deleteServiceAction,
  updateServiceAction,
  getAllServicesAction,
} from './serviceActions';
import { getDashboardDataAdminAction } from './adminActions';
import {
  getInvoicesAdminAction,
  remindInvoiceAction,
  deleteInvoiceAction,
  createInvoiceAction,
  getInvoiceAction,
  updateInvoiceAction,
} from './invoiceActions';
import {
  getClientsAction,
  deleteClientAction,
  updateClientAction,
  getClientAction,
  createClientAction,
  getClientsAllAction,
  endClientsServiceAction,
} from './clientsActions';
import {
  getPartnerAction,
  createPartnerAction,
  deletePartnerAction,
  getPartnersAction,
  updatePartnerAction,
  createPartnersContractAction,
  getPartnersAllAction,
} from './partnersActions';
import {
  getAdminAction,
  createAdminAction,
  deleteAdminAction,
  getAdminsAction,
  updateAdminAction,
} from './adminsActions';
import {
  getAllInfosAction,
  createInfoAction,
  deleteInfoAction,
  getInfoAction,
  getInfosAction,
  updateInfoAction,
} from './infoActions';
import { getPaymentsAction, exportPaymentsAction, exportPaymentsCancelAction } from './partnersPaymentActions';

import { getPageAction, updatePageAction, getPagesAction, userGetPageAction } from './pageActions';
import { deleteNotificationAction, getNotificationsAction } from './notificationActions';

export const GETNOTIFICATIONS = 'GETNOTIFICATIONS';
export const TOKENEXPIRED = 'TOKENEXPIRED';
export const GETDATA = 'GETDATA';
export const GETINVOICES = 'GETINVOICES';
export const GETSERVICE = 'GETSERVICE';
export const GETSERVICES = 'GETSERVICES';
export const GETVAT = 'GETVAT';
export const GETDATADASHBOARD = 'GETDATADASHBOARD';
export const GETINVOICESADMIN = 'GETINVOICESADMIN';
export const GETCLIENT = 'GETCLIENT';
export const GETCLIENTS = 'GETCLIENTS';
export const GETSERVICESALL = 'GETSERVICESALL';
export const GETPARTNERS = 'GETPARTNERS';
export const GETPARTNER = 'GETPARTNER';
export const GETADMIN = 'GETADMIN';
export const GETADMINS = 'GETADMINS';
export const GETPAYMENTS = 'GETPAYMENTS';
export const GETCLIENTSALL = 'GETCLIENTSALL';
export const GETPARTNERSALL = 'GETPARTNERSALL';
export const GETPAGE = 'GETPAGE';
export const GETPAGES = 'GETPAGES';
export const GETLOGS = 'GETLOGS';

export const GETREGULATIONS = 'GETREGULATIONS';
export const GETREGULATION = 'GETREGULATION';

export const GETRODOS = 'GETRODOS';
export const GETRODO = 'GETRODO';

export const GETPOLICIES = 'GETPOLICIES';
export const GETPOLICY = 'GETPOLICY';

export const GETINFOS = 'GETINFOS';
export const GETINFO = 'GETINFO';
export const GETINFOALL = 'GETINFOALL';

export const FETCH_ERROR = 'FETCH_ERROR';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const ACCEPT_REGULATIONS = 'ACCEPT_REGULATIONS';
export const mainURL = `${process.env.REACT_APP_API_URL}`;
export const adminAPI = `${mainURL}/api/panel/`;
export {
  getDataAction,
  updateDataAction,
  getInvoicesAction,
  getServicesAction,
  getServiceAction,
  createServiceAction,
  deleteServiceAction,
  updateServiceAction,
  getDashboardDataAdminAction,
  getInvoicesAdminAction,
  remindInvoiceAction,
  getInvoiceAction,
  getClientsAction,
  deleteClientAction,
  updateClientAction,
  getClientAction,
  getAllServicesAction,
  createClientAction,
  getPartnerAction,
  createPartnerAction,
  deletePartnerAction,
  getPartnersAction,
  updatePartnerAction,
  getAdminAction,
  createAdminAction,
  deleteAdminAction,
  getAdminsAction,
  updateAdminAction,
  getAllInfosAction,
  createInfoAction,
  deleteInfoAction,
  getInfoAction,
  getInfosAction,
  updateInfoAction,
  payInvoiceAction,
  getPaymentsAction,
  exportPaymentsAction,
  exportPaymentsCancelAction,
  createPartnersContractAction,
  getClientsAllAction,
  getPartnersAllAction,
  getPageAction,
  updatePageAction,
  getPagesAction,
  userGetPageAction,
  deleteInvoiceAction,
  createInvoiceAction,
  updateInvoiceAction,
  deleteNotificationAction,
  getNotificationsAction,
  endClientsServiceAction,
};

export const logInAction = (login, password) => dispatch => {
  const fd = {
    login,
    password,
  };
  axios
    .post(`${adminAPI}login`, fd)
    .then(response => {
      const { access_token, scope, ifAcceptRegulations } = response.data;
      dispatch({
        type: LOGIN,
        payload: {
          scope,
          token: access_token,
          ifAcceptRegulations,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Niepoprawny email lub hasło',
      });
    });
};

export function resetAction(data = {}) {
  return async dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: null,
    });
    try {
      const res = await axios.post(`${adminAPI}resetPassword`, data);
      return res.data.status;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Niepoprawny email lub hasło',
      });

      return error.response.data;
    }
  };
}

export function logOutAction(/* token */) {
  return async dispatch => {
    try {
      // todo: Call Api to revoke token
      /* const res = await axios.post(`${mainURL}logout`,{},{
              headers: { Authorization: `Bearer ${token}` },
            }); */
      dispatch({ type: LOGOUT });
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Niepoprawny email lub hasło',
      });
    }
  };
}

export function getVatAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}admin/vat`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETVAT, vats: res.data.vats });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function getLogsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}admin/logs`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETLOGS, logs: res.data.logs });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function getExportsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}admin/insert`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETLOGS, logs: res.data.exports });
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function acceptAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}accept/regulations`, params, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ACCEPT_REGULATIONS });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return null;
    }
  };
}
