import React, { Component } from 'react';
import {
  MDBCardBody,
  MDBCol, MDBInput, MDBPageItem, MDBPageNav, MDBPagination,
  MDBRow,
  MDBTable,
} from 'mdbreact';
import { connect } from 'react-redux';
import _ from 'lodash';
import { StyledMDBCard } from '../../../components/StyledCard';
import { StyledTr } from '../../../components/StyledTr';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { getLogsAction } from '../../../actions';

class LogList extends Component {
  state = {
    page: 1,
    perPage: 10,
    search: '',
  };

  search = _.debounce(() => {
    this.fetch();
  }, 1000);

  componentDidMount() {
    this.fetch();
  }

  fetch = (getPage = 0) => {
    const { getData, token } = this.props;
    const { page, perPage, search } = this.state;
    if (getPage) {
      getData(token, { page: getPage, perPage, search });
    } else {
      getData(token, { page, perPage, search });
    }
  };

  getPrevPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setState({
        page: page - 1,
      });
      this.fetch(page - 1);
    }
  };

  getNextPage = () => {
    const { page } = this.state;
    const { items } = this.props;
    if (page < items.last_page) {
      this.setState({
        page: page + 1,
      });
      this.fetch(page + 1);
    }
  };

  getBuildingsPage = newPage => {
    const { page } = this.state;
    if (page !== newPage) {
      this.setState({
        page: newPage,
      });

      this.fetch(newPage);
    }

  };


  pagination = () => {
    const { items } = this.props;
    const { page } = this.state;
    const { last_page } = items;
    const pagesNum = last_page || 1;


    const pages = [];

    let isBreak = true;

    for (let i = 1; i <= pagesNum; i++) {
      if ((i < 4) || (i > pagesNum - 3) || (i > page - 2 && i < page + 2)) {
        isBreak = true;
        if (page === i) {
          pages.push(
            <MDBPageItem key={i} active>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        } else {
          pages.push(
            <MDBPageItem key={i}>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        }
      } else if (isBreak) {
        isBreak = false;
        pages.push(
          <MDBPageItem key={i}>
            <MDBPageNav>
              ...
            </MDBPageNav>
          </MDBPageItem>,
        );
      }

    }

    return (
      <MDBPagination circle className="my-4 float-right">
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Poprzednia</span>
          </MDBPageNav>
        </MDBPageItem>
        {pages}
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Następna</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    );
  };

  render() {
    const { search } = this.state;
    const { items } = this.props;
    return (
      <AdminPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">

                  <h3 className="py-2 color-dark-purple">Lista logów</h3>

                  <div>
                    <MDBInput
                      label="Szukaj..."
                      name="search"
                      value={search}
                      getValue={this.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value });
                      }}
                    />
                  </div>

                  <MDBTable hover>
                    <thead>
                    <StyledTr className="rgba-stylish-strong white-text">
                      <th>Użytkownik</th>
                      <th>Treść</th>
                      <th>Data</th>
                    </StyledTr>
                    </thead>
                    <tbody>

                    {items.data.map((value) => {
                      const { content, user, created_at } = value;
                      return <>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>{user}</td>
                          <td style={{ verticalAlign: 'middle' }}>{content}</td>
                          <td style={{ verticalAlign: 'middle' }}>{created_at}</td>
                        </tr>
                      </>;
                    })}
                    </tbody>
                  </MDBTable>
                  {this.pagination()}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>

      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    items: state.logs,
  };
};

export default connect(
  mapStateToProps,
  {
    getData: getLogsAction,
  },
)(LogList);

