import {
  LOGIN,
  LOGOUT,
  FETCH_ERROR,
  GETDATA,
  GETINVOICES,
  GETSERVICES,
  GETVAT,
  GETDATADASHBOARD,
  GETINVOICESADMIN,
  GETCLIENT,
  GETCLIENTS,
  GETSERVICESALL,
  GETPARTNER,
  GETPARTNERS,
  GETADMINS,
  GETCLIENTSALL,
  GETADMIN,
  GETINFOALL,
  GETINFOS,
  GETINFO,
  GETPAYMENTS,
  GETPARTNERSALL,
  GETPAGES,
  GETPAGE,
  GETNOTIFICATIONS,
  GETLOGS,
  ACCEPT_REGULATIONS,
  GETREGULATIONS,
  GETREGULATION,
  GETRODOS,
  GETRODO, GETPOLICIES, GETPOLICY,
} from '../actions';

const initialState = {
  token: localStorage.getItem('token'),
  scope: localStorage.getItem('scope'),
  ifAcceptRegulations: localStorage.getItem('ifAcceptRegulations'),
  error: '',

  userData: {
    balance: 0,
    partnerIncome: 0,
    lastInvoices: [],
    services: [],
    contracts: [],
    otherServices: [],
    account: {},
  },

  dashboardData: {
    paid: [],
    notPaid: [],
  },

  invoices: {
    data: [],
  },
  clients: {
    data: [],
  },
  services: {
    data: [],
  },
  partners: {
    data: [],
  },
  admins: {
    data: [],
  },
  infos: {
    data: [],
  },
  payments: {
    data: [],
  },
  logs: {
    data: [],
  },
  regulations: {
    data: [],
  },
  rodos: {
    data: [],
  },
  policies: {
    data: [],
  },

  client: {},
  partner: {},
  admin: {},
  info: {},
  regulation: {},
  rodo: {},
  policy: {},

  pages: [],
  page: {},
  vats: [],
  servicesAll: [],
  infosAll: [],
  clientsAll: [],
  partnersAll: [],
  notifications: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      // eslint-disable-next-line no-case-declarations
      const { token, scope, ifAcceptRegulations } = action.payload;
      localStorage.setItem('token', token);
      localStorage.setItem('scope', scope);
      localStorage.setItem('ifAcceptRegulations', ifAcceptRegulations);
      return { ...state, token, scope, ifAcceptRegulations };
    case FETCH_ERROR:
      return { ...state, error: action.payload };
    case GETDATA:
      return { ...state, userData: action.userData };
    case GETINVOICES:
      return { ...state, invoices: action.invoices };
    case GETINVOICESADMIN:
      return { ...state, invoices: action.invoices };
    case GETSERVICES:
      return { ...state, services: action.services };
    case GETSERVICESALL:
      return { ...state, servicesAll: action.services };
    case GETVAT:
      return { ...state, vats: action.vats };
    case GETCLIENTS:
      return { ...state, clients: action.clients };
    case GETCLIENTSALL:
      return { ...state, clientsAll: action.clients };
    case GETCLIENT:
      return { ...state, client: action.client };
    case GETPARTNERS:
      return { ...state, partners: action.partners };
    case GETPARTNERSALL:
      return { ...state, partnersAll: action.partners };
    case GETPARTNER:
      return { ...state, partner: action.partner };
    case GETADMINS:
      return { ...state, admins: action.admins };
    case GETADMIN:
      return { ...state, admin: action.admin };
    case GETINFO:
      return { ...state, info: action.info };
    case GETINFOS:
      return { ...state, infos: action.infos };
    case GETINFOALL:
      return { ...state, infosAll: action.infos };
    case GETPAGES:
      return { ...state, pages: action.pages };
    case GETPAGE:
      return { ...state, page: action.page };
    case GETPAYMENTS:
      return { ...state, payments: action.payments };
    case GETDATADASHBOARD:
      return { ...state, dashboardData: action.dashboardData };
    case GETNOTIFICATIONS:
      return { ...state, notifications: action.notifications };
    case GETLOGS:
      return { ...state, logs: action.logs };
    case ACCEPT_REGULATIONS:
      localStorage.setItem('ifAcceptRegulations', 'true');
      return { ...state, ifAcceptRegulations: 'true' };
    case GETREGULATIONS:
      return { ...state, regulations: action.regulations };
    case GETREGULATION:
      return { ...state, regulation: action.regulation };
    case GETRODOS:
      return { ...state, rodos: action.rodos };
    case GETRODO:
      return { ...state, rodo: action.rodo };
    case GETPOLICIES:
      return { ...state, policies: action.policies };
    case GETPOLICY:
      return { ...state, policy: action.policy };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('scope');
      localStorage.removeItem('ifAcceptRegulations');
      return { ...initialState, token: '', scope: '', ifAcceptRegulations: false };
    default:
      return state;
  }
};

export default rootReducer;
