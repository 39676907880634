import styled, { css } from 'styled-components';
import { MDBBtn } from 'mdbreact';

export const Button = styled(MDBBtn)`
  background-color: #6b1d54!important;
  border-radius: 10000px;
  &:not([disabled]):not(.disabled):active{
  background-color:#5b1c45!important;
  }
  &:hover{
  background-color: ${({ pay }) => pay ? 'red' : '#6b1d54'}!important;
  color: #ffffff;
  }
  color: #ffffff;
  
  ${({ pay }) =>
  pay ? css`
padding-top: 10px;
padding-bottom: 10px;
` : ''
  }
`;
