import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'mdbreact';
import { Redirect } from 'react-router-dom';
import Form from './Form';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { StyledMDBCard } from '../../../components/StyledCard';
import { createPolicyAction } from '../../../actions/privacyPolicyActions';

class PrivacyPolicyAdminCreate extends Component {
  state = {
    form: {
      data: {
        isValidFrom: new Date().getTime() / 1000,
      },
      errors: {},
    },
    redirect: null,
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeName = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    createItem(token, data).then(response => {
      if (response === 'created') {
        toast.success('Dodano politykę prywatności.');
        setTimeout(() => {
          this.setState({ redirect: `/panel/admin/polityka-prywatnosci` });
        }, 2000);
      } else {
        toast.error('Nie udało się dodać nowej polityki prywatnosci. Sprawdź błędy.');
        const newForm = { ...form };
        newForm.errors = response;
        this.setState({ form: newForm });
      }
    });
  };

  render() {
    const { form, date, redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <AdminPanelTemplate>
        <section>
          <StyledMDBCard className="mb-5 p-4">
            <h3 className="py-2 color-dark-purple">Dodaj politykę prywatności</h3>
            <Form
              form={form}
              date={date}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onChangeName={this.handleChangeName}
            />
          </StyledMDBCard>
        </section>
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  createItem: createPolicyAction,
})(PrivacyPolicyAdminCreate);
