import React, { Component } from 'react';
import {
  MDBBtn,
  MDBBtnFixed,
  MDBCol,
  MDBFileInput,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSelect,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button } from '../../../components/Button';
import { adminAPI, createPartnersContractAction } from '../../../actions';
import { deletePartnersContractAction } from '../../../actions/partnersActions';

class PartnerForm extends Component {
  state = {
    activeItem: '1',
    modal: false,
    fileName: '',
    file: null,
  };

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  toggleModal = () => {
    this.setState(state => {
      return {
        modal: !state.modal,
        fileName: '',
        file: null,
      };
    });
  };

  registerP24 = (userId) => {
    const { token } = this.props;
    axios
      .post(
        `${adminAPI}admin/partner/${userId}/createP24Account`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then(({ data: { url } }) => {
        if (url) {
          window.location.replace(url);
        }
      })
      .catch(() => {});
  };

  render() {
    const { activeItem, modal, fileName, file } = this.state;
    const { form, onSubmit, onChange, edit, onFetch } = this.props;
    const {
      name,
      login,
      email,
      password,
      password_confirmation,
      client,
      street,
      nip,
      buildingNumber,
      flatNumber,
      postCode,
      city,
      percent,
      paymentId,
      contracts,
      user_id: userId,
      p24,
    } = form.data;
    const { errors } = form;

    const {
      password_confirmation: password_confirmation1,
      street: street1,
      password: password1,
      email: email1,
      login: login1,
      nip: nip1,
      postCode: postCode1,
      name: name1,
      buildingNumber: buildingNumber1,
      flatNumber: flatNumber1,
      city: city1,
      paymentId: paymentId1,
      percent: percent1,
    } = errors;

    let clientSecret = '';
    let id = '';
    if (client) {
      const { clientSecret: clientSecret1, id: id1 } = client;
      clientSecret = clientSecret1;
      id = id1;
    }
    return (
      <>
        <form onSubmit={onSubmit} id="form">
          <MDBNav tabs className="my-3 dark-purple">
            <MDBNavItem>
              <MDBNavLink
                to="/asd1"
                active={activeItem === '1'}
                onClick={this.toggle('1')}
                role="tab"
              >
                <MDBIcon icon="database" /> Dane
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem>
              <MDBNavLink
                to="/asd1"
                active={activeItem === '2'}
                onClick={this.toggle('2')}
                role="tab"
              >
                <MDBIcon icon="price" /> Płatności
              </MDBNavLink>
            </MDBNavItem>

            {edit && (
              <>
                <MDBNavItem>
                  <MDBNavLink
                    to="/asd1"
                    active={activeItem === '3'}
                    onClick={this.toggle('3')}
                    role="tab"
                  >
                    <MDBIcon icon="file-signature" /> Umowy
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to="/asd4"
                    active={activeItem === '4'}
                    onClick={this.toggle('4')}
                    role="tab"
                  >
                    <MDBIcon icon="file-signature" /> Dane P24
                  </MDBNavLink>
                </MDBNavItem>
              </>
            )}
          </MDBNav>
          <MDBTabContent activeItem={activeItem}>
            <MDBTabPane tabId="1" role="tabpanel">
              <MDBRow>
                <MDBCol lg={6}>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Nazwa"
                        name="name"
                        className={name1 === undefined ? '' : 'is-invalid'}
                        value={name}
                        onChange={onChange}
                      >
                        {name1 !== undefined && <div className="invalid-feedback">{name1[0]}</div>}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Login"
                        name="login"
                        className={login1 === undefined ? '' : 'is-invalid'}
                        value={login}
                        type="text"
                        onChange={onChange}
                      >
                        {login1 !== undefined && (
                          <div className="invalid-feedback">{login1[0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Email"
                        name="email"
                        className={email1 === undefined ? '' : 'is-invalid'}
                        value={email}
                        type="email"
                        onChange={onChange}
                      >
                        {email1 !== undefined && (
                          <div className="invalid-feedback">{email1[0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Ulica"
                        name="street"
                        className={street1 === undefined ? '' : 'is-invalid'}
                        value={street}
                        onChange={onChange}
                      >
                        {street1 !== undefined && (
                          <div className="invalid-feedback">{street1[0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Numer budynku"
                        name="buildingNumber"
                        className={buildingNumber1 === undefined ? '' : 'is-invalid'}
                        value={buildingNumber}
                        onChange={onChange}
                      >
                        {buildingNumber1 !== undefined && (
                          <div className="invalid-feedback">{buildingNumber1[0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Numer mieszkania"
                        name="flatNumber"
                        className={flatNumber1 === undefined ? '' : 'is-invalid'}
                        value={flatNumber}
                        onChange={onChange}
                      >
                        {flatNumber1 !== undefined && (
                          <div className="invalid-feedback">{flatNumber1[0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Kod pocztowy"
                        name="postCode"
                        className={postCode1 === undefined ? '' : 'is-invalid'}
                        value={postCode}
                        onChange={onChange}
                      >
                        {postCode1 !== undefined && (
                          <div className="invalid-feedback">{postCode1[0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Miejscowość"
                        name="city"
                        className={city1 === undefined ? '' : 'is-invalid'}
                        value={city}
                        onChange={onChange}
                      >
                        {city1 !== undefined && <div className="invalid-feedback">{city1[0]}</div>}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="NIP"
                        name="nip"
                        className={nip1 === undefined ? '' : 'is-invalid'}
                        value={nip}
                        onChange={onChange}
                      >
                        {nip1 !== undefined && <div className="invalid-feedback">{nip1[0]}</div>}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>

                  {!edit && (
                    <>
                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            label="Hasło"
                            name="password"
                            className={password1 === undefined ? '' : 'is-invalid'}
                            value={password}
                            type="password"
                            onChange={onChange}
                          >
                            {password1 !== undefined && (
                              <div className="invalid-feedback">{password1[0]}</div>
                            )}
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            label="Powtórz hasło"
                            name="password_confirmation"
                            className={password_confirmation1 === undefined ? '' : 'is-invalid'}
                            value={password_confirmation}
                            type="password"
                            onChange={onChange}
                          >
                            {password_confirmation1 !== undefined && (
                              <div className="invalid-feedback">{password_confirmation1[0]}</div>
                            )}
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBTabPane>

            <MDBTabPane tabId="2" role="tabpanel">
              <MDBRow>
                <MDBCol lg={6}>
                  <MDBRow>
                    <MDBCol>
                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            label="Id płatności"
                            name="paymentId"
                            className={paymentId1 === undefined ? '' : 'is-invalid'}
                            value={paymentId}
                            onChange={onChange}
                          >
                            {paymentId1 !== undefined && (
                              <div className="invalid-feedback">{paymentId1[0]}</div>
                            )}
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            label="Procent dla JAR"
                            name="percent"
                            className={percent1 === undefined ? '' : 'is-invalid'}
                            value={percent}
                            type="number"
                            min="0"
                            step="0.1"
                            onChange={onChange}
                          >
                            {percent1 !== undefined && (
                              <div className="invalid-feedback">{percent1[0]}</div>
                            )}
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>

                      {edit && (
                        <>
                          <p>Client ID: {id}</p>
                          <p>Client SECRET: {clientSecret}</p>
                        </>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBTabPane>

            <MDBTabPane tabId="3" role="tabpanel">
              <MDBRow>
                <MDBCol lg={12}>
                  {contracts.map(item => {
                    const { id: itemId, name: itemName, path } = item;
                    return (
                      <p>
                        <a key={itemId} href={path} target="_blank" rel="noopener noreferrer">
                          {itemName}
                        </a>
                        <MDBBtn
                          floating
                          size="sm"
                          color="red"
                          onClick={() => {
                            const { deleteContract, token } = this.props;
                            deleteContract(token, userId, itemId).then(() => {
                              onFetch();
                            });
                          }}
                        >
                          <MDBIcon icon="times" />
                        </MDBBtn>
                      </p>
                    );
                  })}

                  <MDBBtnFixed
                    onClick={this.toggleModal}
                    floating
                    size="lg"
                    color="dark-purple"
                    icon="plus"
                    style={{ bottom: '45px', right: '24px' }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
            <MDBTabPane tabId="4" role="tabpanel">
              <MDBRow>
                <MDBCol lg={6}>
                  <MDBSelect
                    options={[
                      {
                        text: 'Jednoosobowa działalność gospodarcza',
                        value: 2,
                        checked: parseInt('2', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółka cywilna',
                        value: 3,
                        checked: parseInt('3', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółka jawna',
                        value: 4,
                        checked: parseInt('4', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółka komandytowa',
                        value: 5,
                        checked: parseInt('5', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółka komandytowo-akcyjna',
                        value: 6,
                        checked: parseInt('6', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółka akcyjna',
                        value: 7,
                        checked: parseInt('7', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółka z ograniczoną odpowiedzialnością',
                        value: 8,
                        checked: parseInt('8', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Stowarzyszenie, fundacja, organizacja pożytku publicznego',
                        value: 9,
                        checked: parseInt('9', 10) === parseInt(p24?.business_type, 10),
                      },
                      {
                        text: 'Spółdzielnia',
                        value: 10,
                        checked: parseInt('10', 10) === parseInt(p24?.business_type, 10),
                      },
                    ]}
                    selected="Wybierz rodzaj działaności"
                    label="rodzaj działaności"
                    getValue={value => {
                      if (value[0] && value[0] !== p24?.business_type) {
                        onChange({ target: { value: value[0], name: 'p24__business_type' } });
                      }
                    }}
                  />

                  <MDBSelect
                    options={[
                      {
                        text: 'AGD',
                        value: 'agd',
                        checked: p24?.trade === 'agd',
                      },
                      {
                        text: 'AGD i RTV',
                        value: 'agdrtv',
                        checked: p24?.trade === 'agdrtv',
                      },
                      {
                        text: 'Alkohole',
                        value: 'alkoh',
                        checked: p24?.trade === 'alkoh',
                      },
                      {
                        text: 'Apteki',
                        value: 'apteki',
                        checked: p24?.trade === 'apteki',
                      },
                      {
                        text: 'Artykuły laboratoryjne',
                        value: 'artlab',
                        checked: p24?.trade === 'artlab',
                      },
                      {
                        text: 'Artykuły medyczne',
                        value: 'artmed',
                        checked: p24?.trade === 'artmed',
                      },
                      {
                        text: 'Artykuły spożywcze',
                        value: 'artspoz',
                        checked: p24?.trade === 'artspoz',
                      },
                      {
                        text: 'Aukcje',
                        value: 'aukcje',
                        checked: p24?.trade === 'aukcje',
                      },
                      {
                        text: 'BHP',
                        value: 'behape',
                        checked: p24?.trade === 'behape',
                      },
                      {
                        text: 'Bielizna',
                        value: 'blizna',
                        checked: p24?.trade === 'blizna',
                      },
                      {
                        text: 'Bilety',
                        value: 'bilety',
                        checked: p24?.trade === 'bilety',
                      },
                      {
                        text: 'Bukmacher',
                        value: 'buki',
                        checked: p24?.trade === 'buki',
                      },
                      {
                        text: 'Biżuteria i zegarki',
                        value: 'biz',
                        checked: p24?.trade === 'biz',
                      },
                      {
                        text: 'Budownictwo',
                        value: 'budow',
                        checked: p24?.trade === 'budow',
                      },
                      {
                        text: 'Chemia',
                        value: 'chemia',
                        checked: p24?.trade === 'chemia',
                      },
                      {
                        text: 'Czasopisma',
                        value: 'czaspis',
                        checked: p24?.trade === 'czaspis',
                      },
                      {
                        text: 'Dekoracje',
                        value: 'dekor',
                        checked: p24?.trade === 'dekor',
                      },
                      {
                        text: 'Dewocjonalia',
                        value: 'dewoc',
                        checked: p24?.trade === 'dewoc',
                      },
                      {
                        text: 'Dom i ogród',
                        value: 'domiogr',
                        checked: p24?.trade === 'domiogr',
                      },
                      {
                        text: 'Dziecko',
                        value: 'dziecko',
                        checked: p24?.trade === 'dziecko',
                      },
                      {
                        text: 'Elektronika',
                        value: 'elektronika',
                        checked: p24?.trade === 'elektronika',
                      },
                      {
                        text: 'E-papierosy',
                        value: 'epapier',
                        checked: p24?.trade === 'epapier',
                      },
                      {
                        text: 'Ezoteryka',
                        value: 'ezoter',
                        checked: p24?.trade === 'ezoter',
                      },
                      {
                        text: 'Filatelistyka',
                        value: 'filatel',
                        checked: p24?.trade === 'filatel',
                      },
                      {
                        text: 'Finanse',
                        value: 'finanse',
                        checked: p24?.trade === 'finanse',
                      },
                      {
                        text: 'Fotografia',
                        value: 'fotogr',
                        checked: p24?.trade === 'fotogr',
                      },
                      {
                        text: 'Fundacja',
                        value: 'fundacja',
                        checked: p24?.trade === 'fundacja',
                      },
                      {
                        text: 'Galanteria',
                        value: 'galant',
                        checked: p24?.trade === 'galant',
                      },
                      {
                        text: 'Gadżety',
                        value: 'gadzet',
                        checked: p24?.trade === 'gadzet',
                      },
                      {
                        text: 'Gry',
                        value: 'gry',
                        checked: p24?.trade === 'gry',
                      },
                      {
                        text: 'Hosting',
                        value: 'komphost',
                        checked: p24?.trade === 'komphost',
                      },
                      {
                        text: 'Hotelarstwo',
                        value: 'hotel',
                        checked: p24?.trade === 'hotel',
                      },
                      {
                        text: 'Instytucje',
                        value: 'instyt',
                        checked: p24?.trade === 'instyt',
                      },
                      {
                        text: 'Komputery',
                        value: 'komputery',
                        checked: p24?.trade === 'komputery',
                      },
                      {
                        text: 'Książki',
                        value: 'ksiazki',
                        checked: p24?.trade === 'ksiazki',
                      },
                      {
                        text: 'Kosmetyki',
                        value: 'kosmetyki',
                        checked: p24?.trade === 'kosmetyki',
                      },
                      {
                        text: 'Księgarnia',
                        value: 'ksieg',
                        checked: p24?.trade === 'ksieg',
                      },
                      {
                        text: 'Kwiaty i prezenty',
                        value: 'kip',
                        checked: p24?.trade === 'kip',
                      },
                      {
                        text: 'Masowi Wystawcy Faktur',
                        value: 'mwf',
                        checked: p24?.trade === 'mwf',
                      },
                      {
                        text: 'Maszyny',
                        value: 'maszyny',
                        checked: p24?.trade === 'maszyny',
                      },
                      {
                        text: 'Materiały biurowe',
                        value: 'matbiur',
                        checked: p24?.trade === 'matbiur',
                      },
                      {
                        text: 'Materiały foliowe',
                        value: 'matfol',
                        checked: p24?.trade === 'matfol',
                      },
                      {
                        text: 'Materiały papierowe',
                        value: 'matpap',
                        checked: p24?.trade === 'matpap',
                      },
                      {
                        text: 'Militaria',
                        value: 'militaria',
                        checked: p24?.trade === 'militaria',
                      },
                      {
                        text: 'Motoryzacja',
                        value: 'motoryz',
                        checked: p24?.trade === 'motoryz',
                      },
                      {
                        text: 'Multimedia i muzyka',
                        value: 'mim',
                        checked: p24?.trade === 'mim',
                      },
                      {
                        text: 'Nagrobki',
                        value: 'nagrob',
                        checked: p24?.trade === 'nagrob',
                      },
                      {
                        text: 'Narzędzia',
                        value: 'narzedzia',
                        checked: p24?.trade === 'narzedzia',
                      },
                      {
                        text: 'Nauka i szkolnictwo',
                        value: 'nis',
                        checked: p24?.trade === 'nis',
                      },
                      {
                        text: 'Numizmatyka',
                        value: 'numiz',
                        checked: p24?.trade === 'numiz',
                      },
                      {
                        text: 'Obuwie',
                        value: 'obuwie',
                        checked: p24?.trade === 'obuwie',
                      },
                      {
                        text: 'Odzież',
                        value: 'odziez',
                        checked: p24?.trade === 'odziez',
                      },
                      {
                        text: 'Ogłoszenia',
                        value: 'ogl',
                        checked: p24?.trade === 'ogl',
                      },
                      {
                        text: 'Ogród',
                        value: 'ogrod',
                        checked: p24?.trade === 'ogrod',
                      },
                      {
                        text: 'Oprogramowanie',
                        value: 'oprogra',
                        checked: p24?.trade === 'oprogra',
                      },
                      {
                        text: 'Oświetlenie',
                        value: 'oswietl',
                        checked: p24?.trade === 'oswietl',
                      },
                      {
                        text: 'Pasmanteria',
                        value: 'pasman',
                        checked: p24?.trade === 'pasman',
                      },
                      {
                        text: 'Podróże',
                        value: 'podroze',
                        checked: p24?.trade === 'podroze',
                      },
                      {
                        text: 'Portal randkow',
                        value: 'randki',
                        checked: p24?.trade === 'randki',
                      },
                      {
                        text: 'Portfel elektroniczny',
                        value: 'portfel',
                        checked: p24?.trade === 'portfel',
                      },
                      {
                        text: 'Prasa',
                        value: 'prasa',
                        checked: p24?.trade === 'prasa',
                      },
                      {
                        text: 'Prawo',
                        value: 'prawo',
                        checked: p24?.trade === 'prawo',
                      },
                      {
                        text: 'Przesyłki kurierskie',
                        value: 'kurier',
                        checked: p24?.trade === 'kurier',
                      },
                      {
                        text: 'Reklama',
                        value: 'reklama',
                        checked: p24?.trade === 'reklama',
                      },
                      {
                        text: 'Rękodzieło',
                        value: 'rekodz',
                        checked: p24?.trade === 'rekodz',
                      },
                      {
                        text: 'Rodzice',
                        value: 'rodzice',
                        checked: p24?.trade === 'rodzice',
                      },
                      {
                        text: 'RTV',
                        value: 'rtv',
                        checked: p24?.trade === 'rtv',
                      },
                      {
                        text: 'Serwis internetowy',
                        value: 'serint',
                        checked: p24?.trade === 'serint',
                      },
                      {
                        text: 'Sklep muzyczny',
                        value: 'sklmuz',
                        checked: p24?.trade === 'sklmuz',
                      },
                      {
                        text: 'Sport i wypoczynek',
                        value: 'siw',
                        checked: p24?.trade === 'siw',
                      },
                      {
                        text: 'Suplementy diety',
                        value: 'suplem',
                        checked: p24?.trade === 'suplem',
                      },
                      {
                        text: 'Szkło',
                        value: 'szklo',
                        checked: p24?.trade === 'szklo',
                      },
                      {
                        text: 'Szkolenia',
                        value: 'szkol',
                        checked: p24?.trade === 'szkol',
                      },
                      {
                        text: 'Sztuka',
                        value: 'sztuka',
                        checked: p24?.trade === 'sztuka',
                      },
                      {
                        text: 'Ślubne',
                        value: 'slubne',
                        checked: p24?.trade === 'slubne',
                      },
                      {
                        text: 'Teatr i film',
                        value: 'tif',
                        checked: p24?.trade === 'tif',
                      },
                      {
                        text: 'Telekomunikacja',
                        value: 'telek',
                        checked: p24?.trade === 'telek',
                      },
                      {
                        text: 'Tkaniny',
                        value: 'tkan',
                        checked: p24?.trade === 'tkan',
                      },
                      {
                        text: 'Tworzenie stron WWW',
                        value: 'twstrwww',
                        checked: p24?.trade === 'twstrwww',
                      },
                      {
                        text: 'Ubezpieczenia',
                        value: 'ubezp',
                        checked: p24?.trade === 'ubezp',
                      },
                      {
                        text: 'Usługi',
                        value: 'uslugi',
                        checked: p24?.trade === 'uslugi',
                      },
                      {
                        text: 'Wielobranżowość',
                        value: 'wielob',
                        checked: p24?.trade === 'wielob',
                      },
                      {
                        text: 'WP - conceptshop.pl',
                        value: 'wpc',
                        checked: p24?.trade === 'wpc',
                      },
                      {
                        text: 'Wyposażenie mieszkania',
                        value: 'wypmiesz',
                        checked: p24?.trade === 'wypmiesz',
                      },
                      {
                        text: 'Wyposażenie sklepów',
                        value: 'wypsklep',
                        checked: p24?.trade === 'wypsklep',
                      },
                      {
                        text: 'Wyroby tytoniowe',
                        value: 'wyrtyt',
                        checked: p24?.trade === 'wyrtyt',
                      },
                      {
                        text: 'Wzrok i okulary',
                        value: 'wio',
                        checked: p24?.trade === 'wio',
                      },
                      {
                        text: 'Vod',
                        value: 'vod',
                        checked: p24?.trade === 'vod',
                      },
                      {
                        text: 'Zabawki',
                        value: 'zabawki',
                        checked: p24?.trade === 'zabawki',
                      },
                      {
                        text: 'Zdrowie i kosmetyki',
                        value: 'zik',
                        checked: p24?.trade === 'zik',
                      },
                      {
                        text: 'Zwierzęta',
                        value: 'zwierz',
                        checked: p24?.trade === 'zwierz',
                      },
                    ]}
                    selected="Wybierz rodzaj działaności"
                    label="rodzaj działaności"
                    getValue={value => {
                      if (value[0] && value[0] !== p24?.trade) {
                        onChange({ target: { value: value[0], name: 'p24__trade' } });
                      }
                    }}
                  />

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Nazwa"
                        name="p24__name"
                        className=""
                        value={p24?.name}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Email"
                        name="p24__email"
                        className=""
                        value={p24?.email}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Numer telefonu"
                        name="p24__phone_number"
                        className=""
                        value={p24?.phone_number}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Numer konta bankowego"
                        name="p24__bank_account"
                        className=""
                        value={p24?.bank_account}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Reprezentant - imię i nazwisko"
                        name="p24__representative_name"
                        className=""
                        value={p24?.representative_name}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Reprezentant - pesel"
                        name="p24__representative_pesel"
                        className=""
                        value={p24?.representative_pesel}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Osoba kontaktowa - imię i nazwisko"
                        name="p24__contact_person_name"
                        className=""
                        value={p24?.contact_person_name}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Osoba kontaktowa - email"
                        name="p24__contact_person_email"
                        className=""
                        value={p24?.contact_person_email}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Osoba kontaktowa - numer telefonu"
                        name="p24__contact_person_phone_number"
                        className=""
                        value={p24?.contact_person_phone_number}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Adres - miasto"
                        name="p24__address_city"
                        className=""
                        value={p24?.address_city}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Adres - kod pocztowy"
                        name="p24__address_post_code"
                        className=""
                        value={p24?.address_post_code}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Adres - ulica"
                        name="p24__address_street"
                        className=""
                        value={p24?.address_street}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Email do FV"
                        name="p24__invoice_email"
                        className=""
                        value={p24?.invoice_email}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Opis"
                        name="p24__services_description"
                        className=""
                        value={p24?.services_description}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="krs"
                        name="p24__krs"
                        className=""
                        value={p24?.krs}
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="nip"
                        name="p24__nip"
                        className=""
                        value={p24?.nip}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="regon"
                        name="p24__regon"
                        className=""
                        value={p24?.regon}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                <MDBCol>
                  <Button className="dark-purple" type="button" onClick={()=>{
                    this.registerP24(p24?.user_id);
                  }}>
                    Załóż konto przelewy 24
                  </Button>
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
          </MDBTabContent>

          <Button className="dark-purple" type="submit" color="dark-purple">
            Zapisz
          </Button>
        </form>

        <MDBModal isOpen={modal} toggle={this.toggleModal}>
          <MDBModalHeader toggle={this.toggleModal}>Dodaj umowę</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  label="Nazwa"
                  name="name"
                  className={percent1 === undefined ? '' : 'is-invalid'}
                  value={fileName}
                  getValue={value => {
                    this.setState({
                      fileName: value,
                    });
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBFileInput
                  getValue={value => {
                    this.setState({
                      file: value[0],
                    });
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <Button className="btn-default" onClick={this.toggleModal}>
              Anuluj
            </Button>
            <Button
              className="btn-default"
              onClick={() => {
                const { addContract, token } = this.props;
                const data = {
                  name: fileName,
                  file,
                };

                const fd = new FormData();

                Object.keys(data).forEach(key => {
                  fd.append(key, data[key]);
                });
                addContract(token, userId, fd).then(() => {
                  onFetch();
                  this.toggleModal();
                });
              }}
            >
              Dodaj
            </Button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

PartnerForm.defaultProps = {};

PartnerForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  addContract: createPartnersContractAction,
  deleteContract: deletePartnersContractAction,
})(PartnerForm);
