import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { MDBCardBody, MDBCol, MDBCollapse, MDBIcon, MDBRow, MDBTable, toast } from 'mdbreact';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Button } from '../../components/Button';
import { StyledMDBCard } from '../../components/StyledCard';
import { StyledTr } from '../../components/StyledTr';
import { getDataAction, payInvoiceAction } from '../../actions';
import UserPanelTemplate from '../../templates/UserPanelTemplate';
import ImportantInfo from '../ImportantInfo';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

const StyledMDBIcon = styled(MDBIcon)`
  border-radius: 20px !important;
  cursor: pointer;
`;
const StyledP = styled.p`
  margin: 0;
  padding: 0;
`;

class MainPanel extends Component {
  state = {
    redirect: null,
    collapseID: '',
    disabled: false,
  };

  componentDidMount() {
    const { getData, token, userData } = this.props;
    if (!userData.id) {
      getData(token);
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }));
  };

  render() {
    const { redirect, collapseID, disabled } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { userData } = this.props;
    const { InvoicesNotPaidCount, balance, servicesName, lastInvoices, serviceCount } = userData;
    return (
      <>
        <UserPanelTemplate pageTitle="">
          <section>
            <MDBRow>
              <MDBCol xl="6" md="12" className="mb-5">
                <StyledMDBCard cascade className="cascading-admin-card">
                  <div className="admin-up d-flex align-items-center justify-content-center">
                    <StyledMDBIcon
                      onClick={() => {
                        this.setState({
                          redirect: '/rozliczenia',
                        });
                      }}
                      icon="coins"
                      className="dark-purple mr-5 z-depth-2"
                      style={{ marginTop: '-40px' }}
                    />
                    <div className="data w-100 ">
                      <h3 className="font-weight-bold dark-grey-text text-left pt-3">
                        Saldo: {parseFloat(balance).toFixed(2)} zł
                      </h3>
                      <p className="text-left">Nieopłaconych faktur: {InvoicesNotPaidCount}</p>
                    </div>
                  </div>
                </StyledMDBCard>
              </MDBCol>
              <MDBCol xl="6" md="12" className="mb-5">
                <StyledMDBCard cascade className="cascading-admin-card">
                  <div className="admin-up d-flex align-items-center justify-content-center">
                    <StyledMDBIcon
                      onClick={() => {
                        this.setState({
                          redirect: '/uslugi',
                        });
                      }}
                      icon="stream"
                      className="dark-purple mr-5 z-depth-2"
                      style={{ marginTop: '-40px' }}
                    />
                    <div className="data w-100 ">
                      <h3 className="font-weight-bold dark-grey-text text-left pt-3">
                        Liczba usług: {serviceCount}
                      </h3>
                      <p className="text-left">Twoje usługi: {servicesName}</p>
                    </div>
                  </div>
                </StyledMDBCard>
              </MDBCol>
            </MDBRow>
          </section>

          <section>
            <StyledMDBCard className="mb-5">
              <MDBRow className="my-4">
                <MDBCol md="12">
                  <MDBCardBody className="pt-0">
                    <MDBTable hover>
                      <thead>
                        <StyledTr className="rgba-stylish-strong white-text">
                          <th>Usługa</th>
                          <th>Numer fakruty</th>
                          <th>Status</th>
                          <th>Termin płatności</th>
                          <th>Szczegóły</th>
                          <th>Akcja</th>
                        </StyledTr>
                      </thead>
                      <tbody>
                        {lastInvoices.map(value => {
                          const {
                            id,
                            number,
                            paymentDeadline,
                            isPaid,
                            serviceName,
                            status,
                            amount,
                            created_at,
                            period,
                            serviceName: serviceName1,
                          } = value;
                          return (
                            <>
                              <tr key={id} className="none-top-border">
                                <td style={{ verticalAlign: 'middle' }}>{serviceName}</td>
                                <td style={{ verticalAlign: 'middle' }}>{number}</td>
                                <td style={{ verticalAlign: 'middle' }}>{status}</td>
                                <td style={{ verticalAlign: 'middle' }}>{paymentDeadline}</td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.toggleCollapse(`basicCollapse${id}`)}
                                  >
                                    Zobacz <MDBIcon icon="angle-double-right" />
                                  </span>
                                </td>
                                <td>
                                  {isPaid ? (
                                    ''
                                  ) : (
                                    <Button
                                      title="Zapłać"
                                      pay
                                      disabled={disabled}
                                      onClick={() => {
                                        this.setState({
                                          disabled: true,
                                        });
                                        const { payInvoice, token } = this.props;
                                        payInvoice(token, id).then(response => {
                                          if (response.url) {
                                            const win = window.open(response.url, '_blank');
                                            win.focus();
                                          } else {
                                            toast.error(response.data.message);
                                          }
                                          this.setState({
                                            disabled: false,
                                          });
                                        });
                                      }}
                                    >
                                      {disabled ? (
                                        <MDBIcon icon="spinner" spin />
                                      ) : (
                                        <MDBIcon icon="credit-card" />
                                      )}
                                    </Button>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={6}
                                  style={{
                                    borderTop: 'none',
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  }}
                                >
                                  <MDBCollapse
                                    id={`basicCollapse${id}`}
                                    isOpen={collapseID === `basicCollapse${id}`}
                                  >
                                    <div className="d-flex justify-content-between mr-5 py-3 pr-5 align-items-center">
                                      <div>
                                        <StyledP>Kwota faktury: {amount}</StyledP>
                                        <StyledP>Data wystawienia faktury: {created_at}</StyledP>
                                        <StyledP>Termin płatności: {paymentDeadline}</StyledP>
                                        <StyledP>Okres: {period}</StyledP>
                                        <StyledP>Usługa: {serviceName1}</StyledP>
                                      </div>
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        key={`invoice-${id}`}
                                        role="button"
                                        onClick={() => {
                                          const { token } = this.props;
                                          axios
                                            .get(value.url, {
                                              headers: {
                                                Authorization: `Bearer ${token}`,
                                              },
                                              responseType: 'blob',
                                            })
                                            .then(response => {
                                              const { data } = response;
                                              const url = window.URL.createObjectURL(
                                                new Blob([data]),
                                              );
                                              const link = document.createElement('a');
                                              link.href = url;
                                              link.setAttribute('download', `${number}.pdf`);
                                              document.body.appendChild(link);
                                              link.click();
                                            });
                                        }}
                                        className="d-flex align-items-center justify-content-center flex-column color-dark-purple"
                                      >
                                        <MDBIcon icon="file-alt" size="4x" />
                                        <p>Pobierz fakturę</p>
                                      </div>
                                    </div>
                                  </MDBCollapse>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </StyledMDBCard>
          </section>

          <ImportantInfo />
        </UserPanelTemplate>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    userData: state.userData,
    errorMessage: state.error,
  };
};

export default connect(mapStateToProps, {
  getData: getDataAction,
  payInvoice: payInvoiceAction,
})(ifAcceptRegulations(MainPanel));
