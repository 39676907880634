import React, { Component } from 'react';
import {
  MDBBtn,
  MDBBtnFixed,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSelect,
  MDBSwitch,
  MDBTooltip,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from '../../../components/Button';
import { getVatAction } from '../../../actions';
import DatePicker from '../../../components/DatePicker';

// import PropTypes from 'prop-types';

const QuestionBtn = styled(MDBBtn)`
  background-color: #00000000 !important;
  color: #000 !important;
  height: auto;
  box-shadow: none;
  padding: 0;
`;

class ServiceForm extends Component {
  state = {
    activeItem: '1',
    options: [],
    types: [
      {
        value: 'I',
        text: 'inwestycje',
      },
      {
        value: 'N',
        text: 'nieruchomości',
      },
      {
        value: 'PKS',
        text: 'faktury za dworzec autobusowy',
      },
      {
        value: 'RKL',
        text: 'reklama i promocja',
      },
      {
        value: 'UPI',
        text: 'Poręczenia i usługi informatyczne',
      },
      {
        value: 'SIT',
        text: 'internet/TV',
      },
    ],
  };

  componentDidMount() {
    const { getVat, token } = this.props;
    getVat(token).then(() => {
      const { vats } = this.props;
      this.setState({
        options: vats.map(value => {
          return {
            value: value.id,
            text: value.name,
          };
        }),
      });
    });
  }

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem, options, types } = this.state;
    const {
      form,
      onSubmit,
      onChange,
      onVisibleChange,
      onChangeDate,
      onVatChanged,
      onAddPrice,
      onDeletePrice,
      onTypeChanged,
      edit,
    } = this.props;
    const { name, text, visible, prices, vatName, type } = form.data;
    const { errors } = form;

    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              to="/asd1"
              active={activeItem === '1'}
              onClick={this.toggle('1')}
              role="tab"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink
              to="/asd1"
              active={activeItem === '2'}
              onClick={this.toggle('2')}
              role="tab"
            >
              <MDBIcon icon="price" /> Ceny
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa usługi"
                      name="name"
                      className={errors.name === undefined ? '' : 'is-invalid'}
                      value={name}
                      onChange={onChange}
                    >
                      {errors.name !== undefined && (
                        <div className="invalid-feedback">{errors.name[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Opis usługi"
                      name="text"
                      className={errors.text === undefined ? '' : 'is-invalid'}
                      value={text}
                      onChange={onChange}
                    >
                      {errors.text !== undefined && (
                        <div className="invalid-feedback">{errors.text[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {edit ? (
                      vatName ? (
                        <MDBSelect
                          options={options}
                          selected={vatName}
                          getValue={value => {
                            onVatChanged(value[0]);
                          }}
                        />
                      ) : (
                        <MDBSelect
                          options={options}
                          selected="Wybierz VAT"
                          label="VAT"
                          getValue={value => {
                            onVatChanged(value[0]);
                          }}
                        />
                      )
                    ) : (
                      <MDBSelect
                        options={options}
                        selected="Wybierz VAT"
                        label="VAT"
                        getValue={value => {
                          onVatChanged(value[0]);
                        }}
                      />
                    )}

                    {errors.vat_id !== undefined && (
                      <div className="invalid-feedback d-block">{errors.vat_id[0]}</div>
                    )}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {edit ? (
                      type ? (
                        <MDBSelect
                          options={types}
                          selected={
                            types.find(t => {
                              return t.value === type;
                            }).text
                          }
                          getValue={value => {
                            onTypeChanged(value[0]);
                          }}
                        />
                      ) : (
                        <MDBSelect
                          options={types}
                          selected="Wybierz typ"
                          label="Typ"
                          getValue={value => {
                            onTypeChanged(value[0]);
                          }}
                        />
                      )
                    ) : (
                      <MDBSelect
                        options={types}
                        selected="Wybierz typ"
                        label="Typ"
                        getValue={value => {
                          onTypeChanged(value[0]);
                        }}
                      />
                    )}

                    {errors.vat_id !== undefined && (
                      <div className="invalid-feedback d-block">{errors.vat_id[0]}</div>
                    )}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol style={{ display: 'flex' }}>
                    <MDBSwitch
                      className=""
                      labelLeft="Niewidoczna"
                      labelRight="Widoczna"
                      checked={visible}
                      getValue={onVisibleChange}
                    />

                    <MDBTooltip placement="top">
                      <QuestionBtn color="transparent">
                        <MDBIcon icon="question" />
                      </QuestionBtn>
                      <div>INFO</div>
                    </MDBTooltip>

                    {errors.visible !== undefined && (
                      <div className="invalid-feedback d-block">{errors.visible[0]}</div>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>

          <MDBTabPane tabId="2" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    {prices.map((item, index) => {
                      return (
                        <div style={{ position: 'relative' }}>
                          <MDBInput
                            label="Cena usługi netto"
                            name={`prices__${index}__price`}
                            className={errors.text === undefined ? '' : 'is-invalid'}
                            value={item.price}
                            type="number"
                            onChange={onChange}
                          >
                            {errors.text !== undefined && (
                              <div className="invalid-feedback">{errors.text[0]}</div>
                            )}
                          </MDBInput>

                          <div style={{ display: 'flex' }}>
                            <DatePicker
                              valueDefault={moment.unix(item.from).toDate()}
                              getValue={value => {
                                onChangeDate(value.getTime() / 1000, `prices__${index}__from`);
                              }}
                              disablePast
                            />

                            <MDBTooltip placement="top">
                              <QuestionBtn color="transparent">
                                <MDBIcon icon="question" />
                              </QuestionBtn>
                              <div>INFO</div>
                            </MDBTooltip>
                          </div>

                          {index > 0 ? (
                            <MDBBtn
                              onClick={() => {
                                onDeletePrice(index);
                              }}
                              size="sm"
                              floating
                              color="dark-purple"
                              className="dark-purple"
                              style={{ position: 'absolute', top: '-20px', right: '-20px' }}
                            >
                              <MDBIcon icon="times" size="lg" />
                            </MDBBtn>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBBtnFixed
              onClick={onAddPrice}
              floating
              size="lg"
              color="dark-purple"
              icon="plus"
              style={{ bottom: '45px', right: '24px' }}
            />
          </MDBTabPane>
        </MDBTabContent>

        <Button className="dark-purple" type="submit" color="dark-purple">
          Zapisz
        </Button>
      </form>
    );
  }
}

ServiceForm.defaultProps = {
  visible: true,
};

ServiceForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
    vats: state.vats,
  };
};

export default connect(mapStateToProps, {
  getVat: getVatAction,
})(ServiceForm);
