import React, { Component } from 'react';
import {
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBRow,
  MDBSelect,
  MDBTable,
} from 'mdbreact';
import { connect } from 'react-redux';
import _ from 'lodash';
import { StyledMDBCard } from '../../components/StyledCard';
import { StyledTr } from '../../components/StyledTr';
import {exportPaymentsAction, exportPaymentsCancelAction, getPartnersAllAction, getPaymentsAction} from '../../actions';
import { Button } from '../../components/Button';
import AdminPanelTemplate from '../../templates/AdminPanelTemplate';
import DatePicker from '../../components/DatePicker';
import {exportPaymentsCashAction} from "../../actions/partnersPaymentActions";

class InfoListAdmin extends Component {
  state = {
    page: 1,
    perPage: 10,
    options: [],
    partners: [],
    search: '',
    from: null,
    to: null
  };

  search = _.debounce(() => {
    this.fetch();
  }, 1000);

  componentDidMount() {
    const { getPartnersAll, token } = this.props;
    getPartnersAll(token).then(() => {
      const { partnersAll } = this.props;
      const newOptions = partnersAll.map(item => {
        return {
          value: item.id,
          text: item.name,
        };
      });

      this.setState({
        options: newOptions,
      });

      setTimeout(this.fetch, 100);
    });
  }

  fetch = (getPage = 0) => {
    const { getData, token } = this.props;
    const { page, perPage, partners, search, from, to } = this.state;
    if (getPage) {
      getData(token, { page: getPage, perPage, partners, search, from, to });
    } else {
      getData(token, { page, perPage, partners, search, from, to });
    }
  };

  getPrevPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setState({
        page: page - 1,
      });
      this.fetch(page - 1);
    }
  };

  getNextPage = () => {
    const { page } = this.state;
    const { items } = this.props;
    if (page < items.last_page) {
      this.setState({
        page: page + 1,
      });
      this.fetch(page + 1);
    }
  };

  getBuildingsPage = newPage => {
    const { page } = this.state;
    if (page !== newPage) {
      this.setState({
        page: newPage,
      });

      this.fetch(newPage);
    }
  };

  pagination = () => {
    const { items } = this.props;
    const { page } = this.state;
    const { last_page } = items;
    const pagesNum = last_page || 1;

    const pages = [];

    let isBreak = true;

    for (let i = 1; i <= pagesNum; i++) {
      if (i < 4 || i > pagesNum - 3 || (i > page - 2 && i < page + 2)) {
        isBreak = true;
        if (page === i) {
          pages.push(
            <MDBPageItem key={i} active>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        } else {
          pages.push(
            <MDBPageItem key={i}>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        }
      } else if (isBreak) {
        isBreak = false;
        pages.push(
          <MDBPageItem key={i}>
            <MDBPageNav>...</MDBPageNav>
          </MDBPageItem>,
        );
      }
    }

    return (
      <MDBPagination circle className="my-4 float-right">
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Poprzednia</span>
          </MDBPageNav>
        </MDBPageItem>
        {pages}
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Następna</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    );
  };

  downloadExcel = () => {
    const { partners, search, from, to } = this.state;
    const { exportPayments, token } = this.props;
    exportPayments(token, { partners, search, from, to }).then(response => {
      const { data } = response;
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `raport.xls`);
      document.body.appendChild(link);
      link.click();
    });
  };

  downloadExcelCash = () => {
    const { partners, search, from, to } = this.state;
    const { exportPaymentsCash, token } = this.props;
    exportPaymentsCash(token, { partners, search, from, to }).then(response => {
      const { data } = response;
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `raport.xls`);
      document.body.appendChild(link);
      link.click();
    });
  };

  downloadExcelCancel = () => {
    const { partners, search, from, to } = this.state;
    const { exportPaymentsCancel, token } = this.props;
    exportPaymentsCancel(token, { partners, search, from, to }).then(response => {
      const { data } = response;
      const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `raport.xls`);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    const { options, search } = this.state;
    const { items } = this.props;
    return (
      <AdminPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <h3 className="py-2 color-dark-purple">Lista transakcji</h3>
                  <div>
                    <Button color="dark-purple" onClick={this.downloadExcel}>
                      <MDBIcon icon="file-excel" /> EXPORTUJ DO EXCEL WYBRANY RAPORT P24
                    </Button>

                    <Button color="dark-purple" onClick={this.downloadExcelCash}>
                      <MDBIcon icon="file-excel" /> EXPORTUJ DO EXCEL WYBRANY RAPORT GOTÓWKOWY
                    </Button>

                    <Button color="dark-purple" onClick={this.downloadExcelCancel}>
                      <MDBIcon icon="file-excel" /> EXPORTUJ DO EXCEL ANULOWANE
                    </Button>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <MDBSelect
                      options={options}
                      multiple
                      label="Wybierz partnera"
                      getValue={value => {
                        this.setState({ partners: value });
                        setTimeout(this.fetch, 100);
                      }}
                    />
                    <DatePicker
                      getValue={value => {
                        this.setState({
                          from: value.getTime() / 1000,
                        });
                        setTimeout(this.fetch, 100);
                      }}
                    />
                    <DatePicker
                      getValue={value => {
                        this.setState({
                          to: value.getTime() / 1000,
                        });
                        setTimeout(this.fetch, 100);
                      }}
                    />
                    <MDBInput
                      label="Szukaj..."
                      name="search"
                      value={search}
                      getValue={this.search}
                      onChange={e => {
                        this.setState({ search: e.target.value });
                      }}
                    />
                  </div>

                  <div style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}>
                    <span>Kwota transakcji brutto: {items.amount} zł</span>
                    <span>Kwota po prowizji dla partnera brutto: {items.partnerAmount} zł</span>
                    <span>Kwota prowizji dla JAR brutto: {items.JARAmount} zł</span>
                    <span>Kwota prowizji dla PRZELEWY24 brutto: {items.przelewy24Amount} zł</span>
                    <span>Suma prowizji: {items.feeAll} zł</span>
                  </div>

                  <MDBTable hover>
                    <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Data</th>
                        <th>ID sesji</th>
                        <th>ID transakcji</th>
                        <th>Kwota transakcji brutto</th>
                        <th>Kwota prowizji dla partnera brutto</th>
                        <th>Kwota prowizji dla JAR brutto</th>
                        <th>Kwota prowizji dla PRZELEWY24 brutto</th>
                        <th>Suma prowizji</th>
                      </StyledTr>
                    </thead>
                    <tbody>
                      {items.data.map(value => {
                        const {
                          created_at,
                          partnerAmount,
                          id,
                          amount,
                          brokerPaymentId,
                          przelewy24OrderId,
                          JARAmount,
                          przelewy24Amount,
                          feeAll,
                        } = value;
                        return (
                          <tr key={id}>
                            <td style={{ verticalAlign: 'middle' }}>{created_at}</td>
                            <td style={{ verticalAlign: 'middle' }}>{brokerPaymentId}</td>
                            <td style={{ verticalAlign: 'middle' }}>{przelewy24OrderId} </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {parseFloat(amount).toFixed(2)} zł
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {parseFloat(partnerAmount).toFixed(2)} zł
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {parseFloat(JARAmount).toFixed(2)} zł
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {parseFloat(przelewy24Amount).toFixed(2)} zł
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {parseFloat(feeAll).toFixed(2)} zł
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MDBTable>
                  {this.pagination()}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    items: state.payments,
    partnersAll: state.partnersAll,
  };
};

export default connect(mapStateToProps, {
  getData: getPaymentsAction,
  exportPayments: exportPaymentsAction,
  exportPaymentsCash: exportPaymentsCashAction,
  getPartnersAll: getPartnersAllAction,
  exportPaymentsCancel: exportPaymentsCancelAction,
})(InfoListAdmin);
