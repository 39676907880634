import axios from 'axios';
import { GETDATADASHBOARD, TOKENEXPIRED, adminAPI } from './index';

export function getDashboardDataAdminAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}admin/dashboard`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETDATADASHBOARD, dashboardData: res.data });
      return res.data.userData;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}
