import React, {useState} from "react";
import {MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader} from "mdbreact";
import Loader from "react-loader-spinner";
import moment from "moment";
import {Button} from "./Button";
import DatePicker from "./DatePicker";

export const InvoiceButton = ({syncInvoices}) => {
    const [modal, setModal] = useState(null);
    const [modalDate, setModalDate] = useState(null);
    const [loader, setLoader] = useState(false);

    const toggle = () => {
        setLoader(false)
        setModal(null)
    }

    return (
        <>
            <Button
                color="dark-purple"
                onClick={() => {
                    setModal('invoice-all');
                }}
            >
                <MDBIcon icon="sync"/> Insert GT
            </Button>
            <MDBModal
                isOpen={modal === `invoice-all`}
                toggle={toggle}
            >
                <MDBModalHeader
                    toggle={toggle}>
                    Wybierz miesiąc z którego zostaną wysłane faktury.
                </MDBModalHeader>
                <MDBModalBody>
                    Status importu oraz ewentualne błędy są w zakładce "Insert"
                    {loader ? (
                        <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    ) : (
                        <DatePicker
                            disableFuture
                            getValue={(date) => {
                                setModalDate(moment(date).format('YYYY-MM-DD'));
                            }}
                        />
                    )}
                </MDBModalBody>

                <MDBModalFooter>
                    {loader === false && (
                        <>
                            <Button
                                className="btn-default"
                                onClick={toggle}
                            >
                                Anuluj
                            </Button>
                            <Button
                                className="btn-default"
                                onClick={() => {
                                    if (modalDate) {
                                        setLoader(true);
                                        syncInvoices(modalDate).finally(() => {
                                            toggle()
                                        });
                                    }
                                }}
                            >
                                Tak
                            </Button>
                        </>
                    )}

                </MDBModalFooter>
            </MDBModal>
        </>
    );
}
