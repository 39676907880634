import React, {useState} from "react";
import {
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBSelect
} from "mdbreact";
import Loader from "react-loader-spinner";
import moment from "moment";
import {Button} from "./Button";
import DatePicker from "./DatePicker";

export const InvoiceVisibleButton = ({actionInvoices}) => {
    const [modal, setModal] = useState(null);
    const [modalDate, setModalDate] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [loader, setLoader] = useState(false);

    const toggle = () => {
        setLoader(false)
        setModal(null)
    }

    return (
        <>
            <Button
                color="dark-purple"
                onClick={() => {
                    setModal('invoice-all');
                }}
            >
                <MDBIcon icon="bell"/> Pokaż faktury
            </Button>
            <MDBModal
                isOpen={modal === `invoice-all`}
                toggle={toggle}
            >
                <MDBModalHeader
                    toggle={toggle}>
                    Wybierz miesiąc z którego zostaną wybrane faktury i zmieniona ich widoczność dla klienta.
                </MDBModalHeader>
                <MDBModalBody>
                    {loader ? (
                        <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    ) : (
                        <>
                            <DatePicker
                                disableFuture
                                getValue={(date) => {
                                    setModalDate(moment(date).format('YYYY-MM-DD'));
                                }}
                            />

                            <MDBSelect label="Pokaż faktury" value={modalType ? 'Tak' : 'Nie'} getValue={(value)=>{
                                setModalType(value[0] ?? null)
                            }} options={[{text:'Nie', value:0, checked: modalType === 0},{text:'Tak', value:1,checked: modalType === 1}]}>

                            </MDBSelect>

                        </>
                    )}
                </MDBModalBody>

                <MDBModalFooter>
                    {loader === false && (
                        <>
                            <Button
                                className="btn-default"
                                onClick={toggle}
                            >
                                Anuluj
                            </Button>
                            <Button
                                className="btn-default"
                                onClick={() => {
                                    if (modalDate && modalType !== null) {
                                        setLoader(true);
                                        actionInvoices(modalDate, modalType).finally(() => {
                                            toggle()
                                        });
                                    }
                                }}
                            >
                                Tak
                            </Button>
                        </>
                    )}

                </MDBModalFooter>
            </MDBModal>
        </>
    );
}
