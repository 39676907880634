import React, { Component } from 'react';
import {
  MDBBtnFixed,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBRow,
  MDBTable,
} from 'mdbreact';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { StyledMDBCard } from '../../../components/StyledCard';
import { StyledTr } from '../../../components/StyledTr';
import { Button } from '../../../components/Button';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { getPoliciesAction } from '../../../actions/privacyPolicyActions';

class RodoListAdmin extends Component {
  state = {
    page: 1,
    perPage: 10,
    redirect: null,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = (getPage = 0) => {
    const { getData, token } = this.props;
    const { page, perPage } = this.state;
    if (getPage) {
      getData(token, { page: getPage, perPage });
    } else {
      getData(token, { page, perPage });
    }
  };

  getPrevPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setState({
        page: page - 1,
      });
      this.fetch(page - 1);
    }
  };

  getNextPage = () => {
    const { page } = this.state;
    const { items } = this.props;
    const { last_page } = items;
    if (page < last_page) {
      this.setState({
        page: page + 1,
      });
      this.fetch(page + 1);
    }
  };

  getBuildingsPage = newPage => {
    const { page } = this.state;
    if (page !== newPage) {
      this.setState({
        page: newPage,
      });

      this.fetch(newPage);
    }
  };

  pagination = () => {
    const { items } = this.props;
    const { page } = this.state;
    const { last_page } = items;
    const pagesNum = last_page || 1;

    const pages = [];

    let isBreak = true;

    for (let i = 1; i <= pagesNum; i++) {
      if (i < 4 || i > pagesNum - 3 || (i > page - 2 && i < page + 2)) {
        isBreak = true;
        if (page === i) {
          pages.push(
            <MDBPageItem key={i} active>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        } else {
          pages.push(
            <MDBPageItem key={i}>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        }
      } else if (isBreak) {
        isBreak = false;
        pages.push(
          <MDBPageItem key={i}>
            <MDBPageNav>...</MDBPageNav>
          </MDBPageItem>,
        );
      }
    }

    return (
      <MDBPagination circle className="my-4 float-right">
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Poprzednia</span>
          </MDBPageNav>
        </MDBPageItem>
        {pages}
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Następna</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    );
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { items } = this.props;
    return (
      <AdminPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <h3 className="py-2 color-dark-purple">Lista polityk prywatności</h3>

                  <MDBTable hover>
                    <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Nazwa</th>
                        <th>Akcja</th>
                      </StyledTr>
                    </thead>
                    <tbody>
                      {items.data.map(value => {
                        return (
                          <>
                            <tr>
                              <td style={{ verticalAlign: 'middle' }}>{value.name}</td>
                              <td>
                                <Button
                                  title="Edytuj"
                                  as="a"
                                  href={`/panel/admin/polityka-prywatnosci/${value.id}`}
                                  className="btn btn-default Ripple-parent"
                                  pay
                                >
                                  <MDBIcon icon="pen" />
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </MDBTable>
                  {this.pagination()}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
        <MDBBtnFixed
          topSection="/panel/admin/polityka-prywatnosci/dodaj"
          floating
          size="lg"
          color="dark-purple"
          icon="plus"
          style={{ bottom: '45px', right: '24px' }}
        />
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    items: state.policies,
    scope: state.scope,
  };
};

export default connect(mapStateToProps, {
  getData: getPoliciesAction,
})(RodoListAdmin);
