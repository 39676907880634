import React, { Component } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact';
import styled from 'styled-components';
import { Button } from '../components/Button';

const StyledMDBCardInfo = styled(MDBCard)`
  border-radius: 20px;
  background-color: #f0f0f0;
`;

class OwnCarouselItem extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState(state => {
      return {
        modal: !state.modal,
      };
    });
  };

  render() {
    const { modal } = this.state;
    const { id, sneakPeak, date, title, contents } = this.props;
    return (
      <MDBCol key={id} md="4">
        <StyledMDBCardInfo className="mb-2">
          <MDBCardBody>
            <MDBCardTitle>{date}</MDBCardTitle>
            <MDBCardText>{title}</MDBCardText>
            <MDBCardText>{sneakPeak}</MDBCardText>
            <Button color="dark-purple" onClick={this.toggle}>
              Więcej
            </Button>

            <MDBModal isOpen={modal} centered toggle={this.toggle} size="lg">
              <MDBModalHeader toggle={this.toggle}>{title}</MDBModalHeader>
              <MDBModalBody>{contents}</MDBModalBody>
              <MDBModalFooter>
                <Button color="dark-purple" onClick={this.toggle}>
                  Zamknij
                </Button>
              </MDBModalFooter>
            </MDBModal>
          </MDBCardBody>
        </StyledMDBCardInfo>
      </MDBCol>
    );
  }
}

export default OwnCarouselItem;
