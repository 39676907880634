import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBMask,
  MDBNavbar,
  MDBRow,
  MDBView,
} from 'mdbreact';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { logInAction } from '../actions';
import { Button } from '../components/Button';
import Logo from '../static/logo.png';
import Background from '../static/background.jpg';

const StyledImg = styled.img`
  height: 120px;
`;
const StyledWrapper = styled.div`
  background-image: url(${Background});
`;
const StyledMDBView = styled(MDBView)`
  background-image: url(${Background});
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  display: flex;
  justify-content: center;
  :hover {
    color: #dadada;
  }
`;

const StyledH4 = styled.h4`
color: #ffffff;
text-align: center;
display: none;
`;

class Login extends React.Component {
  state = {
    login: '',
    password: '',
  };

  login = event => {
    event.preventDefault();
    const { logIn } = this.props;
    const { login, password } = this.state;
    logIn(login, password);
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  errorMessage() {
    const { errorMessage } = this.props;
    return errorMessage ? <div className="info-red">{errorMessage}</div> : <></>;
  }

  render() {
    const { email, password } = this.state;
    const { name } = this.props;
    return (
      <StyledWrapper className="classic-form-page" id="login">
        <MDBNavbar color="deep-purple darken-4" dark expand="md" fixed="top" scrolling transparent>
          <MDBContainer className="d-flex align-items-center justify-content-center">
            <StyledImg src={Logo} alt="" />
          </MDBContainer>
        </MDBNavbar>
        <StyledMDBView>
          <MDBMask className="d-flex justify-content-center align-items-center">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="9" xl="7" sm="12" className="mt-5 mx-auto">
                  <MDBCard style={{ borderRadius: '100px' }}>
                    <MDBCardBody className="d-flex align-items-center justify-content-center flex-column">
                      <div
                        className="form-header dark-purple px-5"
                        style={{ borderRadius: '100000px' }}
                      >
                        <h4>
                          <MDBIcon
                            icon="user"
                            className="mt-2 mb-2 mr-2 text-white font-weight-bold"
                          />
                          PANEL {name} - LOGOWANIE
                        </h4>
                      </div>
                      {this.errorMessage()}
                      <StyledH4>
                        Aktualnie trwają prace konserwacyjne w aplikacji. Wkrótce działanie strony zostanie przywrócone.
                      </StyledH4>
                      <form
                        id="myForm"
                        className="needs-validation w-100 pb-5 px-5"
                        onSubmit={this.login}
                        noValidate
                      >
                        <MDBInput
                          type="text"
                          label="Login"
                          icon="envelope"
                          iconClass="white-text"
                          name="login"
                          value={email}
                          onChange={this.changeHandler}
                        />
                        <MDBInput
                          type="password"
                          label="Hasło"
                          icon="lock"
                          iconClass="white-text"
                          value={password}
                          onChange={this.changeHandler}
                          name="password"
                        />
                        <div className="text-center mt-5 black-text">
                          <Button type="submit" className="font-weight-bold" size="lg">
                            Zaloguj
                          </Button>
                        </div>

                        <StyledLink to="/reset">Reset hasła</StyledLink>
                      </form>

                      <div className="d-flex justify-content-around w-100">
                        <StyledLink to="/regulamin">Regulamin</StyledLink>

                        <StyledLink to="/polityka-prywatnosci">Polityka prywatności</StyledLink>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </StyledMDBView>
      </StyledWrapper>
    );
  }
}

Login.defaultProps = {
  name: 'KLIENTA',
};

const mapStateToProps = state => {
  return {
    token: state.token,
    errorMessage: state.error,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { logIn: logInAction },
  )(Login),
);
