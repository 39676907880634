import axios from 'axios';
import { GETINVOICESADMIN, TOKENEXPIRED, adminAPI } from './index';

const url = `admin/invoice`;

export function getInvoicesAdminAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETINVOICESADMIN, invoices: res.data.invoices });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function createInvoiceAction(token, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function remindInvoiceAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${id}/sendRemind`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}

export function setPaidInvoiceAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${id}/setPaid`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}

export function setVisibleInvoiceAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${id}/setVisible`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}

export function setNotPaidInvoiceAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${id}/setNotPaid`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}

export function deleteInvoiceAction(token, id) {
  return async dispatch => {
    try {
      const res = await axios.delete(`${adminAPI}${url}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function getInvoiceAction(token, id) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.invoice;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function updateInvoiceAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export async function deleteServiceInvoiceAction(token, id) {
  try {
    const res = await axios.delete(`${adminAPI}${url}/service/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
    });
    return res.data.status;
  } catch (error) {
    return error.response.data.errors;
  }
}
