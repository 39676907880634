import React, { Component } from 'react';
import { MDBCardBody, MDBCol, MDBInput, MDBRow, toast } from 'mdbreact';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { StyledMDBCard } from '../../components/StyledCard';
import { getDataAction, updateDataAction } from '../../actions';
import { Button } from '../../components/Button';
import PartnerPanelTemplate from '../../templates/PartnerPanelTemplate';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  div {
    width: 100%;
  }
`;

class Settings extends Component {
  state = {
    login: '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
    errors: {},
  };

  componentDidMount() {
    const { getData, token, userData } = this.props;
    if (!userData.id) {
      getData(token).then(response => {
        const { login } = response;
        this.setState({ login });
      });
    } else {
      const { login } = userData;
      this.setState({ login });
    }
  }

  saveData = e => {
    e.preventDefault();
    const { login, currentPassword, password, passwordConfirmation } = this.state;
    const { updateData, token, getData } = this.props;
    const data = {
      login,
      currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    };
    updateData(token, data).then(response => {
      if (response === 'updated') {
        getData(token);
        toast.success('Zaktualizowano');
        this.setState({
          currentPassword: '',
          password: '',
          passwordConfirmation: '',
          errors: {},
        });
      } else {
        const { errors } = response;
        this.setState({ errors });
      }
    });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { userData } = this.props;
    const { companyName, buildingNumber, city, flatNumber, nip, postCode, street } =
      userData.partnerAccount || {};
    const { login, currentPassword, password, passwordConfirmation, errors } = this.state;
    const {
      password: password1,
      currentPassword: currentPassword1,
      login: login1,
      password_confirmation,
    } = errors;
    return (
      <PartnerPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="4">
                <MDBCardBody className="pt-0">
                  <MDBInput label="Nazwa firmy" value={companyName} background disabled size="sm" />
                  <MDBInput label="Nip" value={nip} background disabled size="sm" />
                  <MDBInput label="Kod pocztowy" value={postCode} background disabled size="sm" />
                  <MDBInput label="Miejscowość" value={city} background disabled size="sm" />
                  <MDBInput label="Ulica" value={street} background disabled size="sm" />
                  <MDBInput
                    label="Numer budynku"
                    value={buildingNumber}
                    background
                    disabled
                    size="sm"
                  />
                  <MDBInput
                    label="Numer mieszkania"
                    value={flatNumber}
                    background
                    disabled
                    size="sm"
                  />
                  <p>Zmień dane logowania</p>
                  <StyledForm onSubmit={this.saveData}>
                    <MDBInput
                      label="Login"
                      type="text"
                      name="login"
                      size="sm"
                      value={login}
                      className={login1 === undefined ? '' : 'is-invalid'}
                      onChange={this.changeHandler}
                    >
                      {login1 !== undefined && <div className="invalid-feedback">{login1[0]}</div>}
                    </MDBInput>

                    <MDBInput
                      label="Obecne hasło"
                      type="password"
                      name="currentPassword"
                      size="sm"
                      value={currentPassword}
                      className={currentPassword1 === undefined ? '' : 'is-invalid'}
                      onChange={this.changeHandler}
                    >
                      {currentPassword1 !== undefined && (
                        <div className="invalid-feedback">{currentPassword1[0]}</div>
                      )}
                    </MDBInput>

                    <MDBInput
                      label="Nowe hasło"
                      type="password"
                      name="password"
                      size="sm"
                      value={password}
                      className={password1 === undefined ? '' : 'is-invalid'}
                      onChange={this.changeHandler}
                    >
                      {password1 !== undefined && (
                        <div className="invalid-feedback">{password1[0]}</div>
                      )}
                    </MDBInput>

                    <MDBInput
                      label="Powtórz nowe hasło"
                      type="password"
                      name="passwordConfirmation"
                      size="sm"
                      value={passwordConfirmation}
                      className={password_confirmation === undefined ? '' : 'is-invalid'}
                      onChange={this.changeHandler}
                    >
                      {password_confirmation !== undefined && (
                        <div className="invalid-feedback">{password_confirmation[0]}</div>
                      )}
                    </MDBInput>

                    <Button color="dark-purple" className="dark-purple" type="submit">
                      ZAPISZ
                    </Button>
                  </StyledForm>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
      </PartnerPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    userData: state.userData,
    errorMessage: state.error,
  };
};

export default connect(
  mapStateToProps,
  {
    getData: getDataAction,
    updateData: updateDataAction,
  },
)(ifAcceptRegulations(Settings));
