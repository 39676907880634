import React, { Component } from 'react';
import {
  MDBBtnFixed,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSelect,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import { connect } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '../../../components/Button';
import { adminAPI, endClientsServiceAction, getAllServicesAction } from '../../../actions';
import DatePicker from '../../../components/DatePicker';

const StyledP = styled.p`
  padding: 0;
  margin: 0;
`;

const StyledDiv = styled.div`
  margin-bottom: 10px;
`;

class ClientForm extends Component {
  state = {
    amountToPay: 0,
    activeItem: '4',
    options: [],
    date: null,
    modal: null,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { token, getServices } = this.props;
    getServices(token).then(() => {
      const { services } = this.props;
      this.setState({
        options: services.map(value => {
          return {
            value: value.id,
            text: value.name,
          };
        }),
      });
    });
  };

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  toggleModal = (modal = null) => {
    this.setState({
      modal,
      date: null,
    });
  };

  render() {
    const { activeItem, options, modal, amountToPay } = this.state;
    const { form, onSubmit, onChange, onChangeName, onAddService, edit, onFetch } = this.props;
    const {
      id,
      name,
      login,
      email,
      phone,
      street,
      street_purchaser: streetPurchaser,
      buildingNumber,
      buildingNumber_purchaser: buildingNumberPurchaser,
      flatNumber,
      flatNumber_purchaser: flatNumberPurchaser,
      postCode,
      postCode_purchaser: postCodePurchaser,
      city,
      city_purchaser: cityPurchaser,
      nip,
      nip_purchaser: nipPurchaser,
      pesel,
      companyName,
      password,
      password_confirmation,
      name_purchaser: namePurchaser,
      services,
      newServices,
      invoiceReminds,
      piggyBankAmount,
      piggy_bank,
      days,
    } = form.data;
    const { errors } = form;

    const {
      phone: phone1,
      password_confirmation: password_confirmation1,
      street: street1,
      street_purchaser: streetPurchaser1,
      password: password1,
      email: email1,
      login: login1,
      nip: nip1,
      nip_purchaser: nipPurchaser1,
      pesel: pesel1,
      postCode: postCode1,
      postCode_purchaser: postCodePurchaser1,
      name: name1,
      name_purchaser: namePurchaser1,
      buildingNumber: buildingNumber1,
      buildingNumber_purchaser: buildingNumberPurchaser1,
      companyName: companyName1,
      flatNumber: flatNumber1,
      flatNumber_purchaser: flatNumberPurchaser1,
      city: city1,
      city_purchaser: cityPurchaser1,
      days: days1,
    } = errors;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              to="/asd1"
              active={activeItem === '1'}
              onClick={this.toggle('1')}
              role="tab"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink
              to="/asd2"
              active={activeItem === '3'}
              onClick={this.toggle('3')}
              role="tab"
            >
              <MDBIcon icon="database" /> Nabywca
            </MDBNavLink>
          </MDBNavItem>

          {edit && (
            <>
              <MDBNavItem>
                <MDBNavLink
                  to="/asd3"
                  active={activeItem === '2'}
                  onClick={this.toggle('2')}
                  role="tab"
                >
                  <MDBIcon icon="price" /> Usługi
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  to="/asd3"
                  active={activeItem === '4'}
                  onClick={this.toggle('4')}
                  role="tab"
                >
                  <MDBIcon icon="price" /> Przypomniania
                </MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink
                  to="/asd3"
                  active={activeItem === '5'}
                  onClick={this.toggle('5')}
                  role="tab"
                >
                  <MDBIcon icon="price" /> Skarbonka
                </MDBNavLink>
              </MDBNavItem>
            </>
          )}
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Imię i nazwisko"
                      name="name"
                      className={name1 === undefined ? '' : 'is-invalid'}
                      value={name}
                      onChange={onChange}
                    >
                      {name1 !== undefined && <div className="invalid-feedback">{name1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Login"
                      name="login"
                      className={login1 === undefined ? '' : 'is-invalid'}
                      value={login}
                      type="text"
                      onChange={onChange}
                    >
                      {login1 !== undefined && <div className="invalid-feedback">{login1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Email"
                      name="email"
                      className={email1 === undefined ? '' : 'is-invalid'}
                      value={email}
                      type="email"
                      onChange={onChange}
                    >
                      {email1 !== undefined && <div className="invalid-feedback">{email1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Telefon"
                      name="phone"
                      className={phone1 === undefined ? '' : 'is-invalid'}
                      value={phone}
                      onChange={onChange}
                    >
                      {phone1 !== undefined && <div className="invalid-feedback">{phone1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Ulica"
                      name="street"
                      className={street1 === undefined ? '' : 'is-invalid'}
                      value={street}
                      onChange={onChange}
                    >
                      {street1 !== undefined && (
                        <div className="invalid-feedback">{street1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Numer budynku"
                      name="buildingNumber"
                      className={buildingNumber1 === undefined ? '' : 'is-invalid'}
                      value={buildingNumber}
                      onChange={onChange}
                    >
                      {buildingNumber1 !== undefined && (
                        <div className="invalid-feedback">{buildingNumber1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Numer mieszkania"
                      name="flatNumber"
                      className={flatNumber1 === undefined ? '' : 'is-invalid'}
                      value={flatNumber}
                      onChange={onChange}
                    >
                      {flatNumber1 !== undefined && (
                        <div className="invalid-feedback">{flatNumber1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Kod pocztowy"
                      name="postCode"
                      className={postCode1 === undefined ? '' : 'is-invalid'}
                      value={postCode}
                      onChange={onChange}
                    >
                      {postCode1 !== undefined && (
                        <div className="invalid-feedback">{postCode1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Miejscowość"
                      name="city"
                      className={city1 === undefined ? '' : 'is-invalid'}
                      value={city}
                      onChange={onChange}
                    >
                      {city1 !== undefined && <div className="invalid-feedback">{city1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="NIP"
                      name="nip"
                      className={nip1 === undefined ? '' : 'is-invalid'}
                      value={nip}
                      onChange={onChange}
                    >
                      {nip1 !== undefined && <div className="invalid-feedback">{nip1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="PESEL"
                      name="pesel"
                      className={pesel1 === undefined ? '' : 'is-invalid'}
                      value={pesel}
                      onChange={onChange}
                    >
                      {pesel1 !== undefined && <div className="invalid-feedback">{pesel1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa firmy"
                      name="companyName"
                      className={companyName1 === undefined ? '' : 'is-invalid'}
                      value={companyName}
                      onChange={onChange}
                    >
                      {companyName1 !== undefined && (
                        <div className="invalid-feedback">{companyName1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Ilość dni na zapłatę"
                      name="days"
                      className={days1 === undefined ? '' : 'is-invalid'}
                      value={days}
                      onChange={onChange}
                      type="number"
                      min={1}
                    >
                      {days1 !== undefined && <div className="invalid-feedback">{days1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                {!edit && (
                  <>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Hasło"
                          name="password"
                          className={password1 === undefined ? '' : 'is-invalid'}
                          value={password}
                          type="password"
                          onChange={onChange}
                        >
                          {password1 !== undefined && (
                            <div className="invalid-feedback">{password1[0]}</div>
                          )}
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Powtórz hasło"
                          name="password_confirmation"
                          className={password_confirmation1 === undefined ? '' : 'is-invalid'}
                          value={password_confirmation}
                          type="password"
                          onChange={onChange}
                        >
                          {password_confirmation1 !== undefined && (
                            <div className="invalid-feedback">{password_confirmation1[0]}</div>
                          )}
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBCol>
            </MDBRow>
          </MDBTabPane>

          <MDBTabPane tabId="3" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="nazwa"
                      name="name_purchaser"
                      className={namePurchaser1 === undefined ? '' : 'is-invalid'}
                      value={namePurchaser}
                      onChange={onChange}
                    >
                      {namePurchaser1 !== undefined && (
                        <div className="invalid-feedback">{namePurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Ulica"
                      name="street_purchaser"
                      className={streetPurchaser1 === undefined ? '' : 'is-invalid'}
                      value={streetPurchaser}
                      onChange={onChange}
                    >
                      {streetPurchaser1 !== undefined && (
                        <div className="invalid-feedback">{streetPurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Numer budynku"
                      name="buildingNumber_purchaser"
                      className={buildingNumberPurchaser1 === undefined ? '' : 'is-invalid'}
                      value={buildingNumberPurchaser}
                      onChange={onChange}
                    >
                      {buildingNumberPurchaser1 !== undefined && (
                        <div className="invalid-feedback">{buildingNumberPurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Numer mieszkania"
                      name="flatNumber_purchaser"
                      className={flatNumberPurchaser1 === undefined ? '' : 'is-invalid'}
                      value={flatNumberPurchaser}
                      onChange={onChange}
                    >
                      {flatNumberPurchaser1 !== undefined && (
                        <div className="invalid-feedback">{flatNumberPurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Kod pocztowy"
                      name="postCode_purchaser"
                      className={postCodePurchaser1 === undefined ? '' : 'is-invalid'}
                      value={postCodePurchaser}
                      onChange={onChange}
                    >
                      {postCodePurchaser1 !== undefined && (
                        <div className="invalid-feedback">{postCodePurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Miejscowość"
                      name="city_purchaser"
                      className={cityPurchaser1 === undefined ? '' : 'is-invalid'}
                      value={cityPurchaser}
                      onChange={onChange}
                    >
                      {cityPurchaser1 !== undefined && (
                        <div className="invalid-feedback">{cityPurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="NIP"
                      name="nip_purchaser"
                      className={nipPurchaser1 === undefined ? '' : 'is-invalid'}
                      value={nipPurchaser}
                      onChange={onChange}
                    >
                      {nipPurchaser1 !== undefined && (
                        <div className="invalid-feedback">{nipPurchaser1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>

          {edit && (
            <>
              <MDBTabPane tabId="2" role="tabpanel">
                <MDBRow>
                  <MDBCol lg={6}>
                    <MDBRow>
                      <MDBCol>
                        {services.map(value => {
                          const { pivot, name: name2 } = value;
                          const { to, from, address, id: pivotId, user_id } = pivot;
                          return (
                            <>
                              <StyledDiv>
                                <StyledP>Usługa: {name2} </StyledP>
                                <StyledP>Adres: {address} </StyledP>
                                <StyledP>
                                  Okres: {from} - {to}{' '}
                                </StyledP>
                              </StyledDiv>
                              <Button
                                pay
                                onClick={() => {
                                  this.toggleModal(`pay${pivotId}`);
                                }}
                              >
                                Zakończ
                              </Button>

                              <MDBModal
                                isOpen={modal === `pay${pivotId}`}
                                toggle={this.toggleModal}
                              >
                                <MDBModalHeader toggle={this.toggle}>Zakończyć?</MDBModalHeader>
                                <MDBModalBody>
                                  <DatePicker
                                    getValue={date => {
                                      this.setState({
                                        date: date.getTime() / 1000,
                                      });
                                    }}
                                  />
                                </MDBModalBody>
                                <MDBModalFooter>
                                  <Button className="btn-default" onClick={this.toggleModal}>
                                    Anuluj
                                  </Button>
                                  <Button
                                    className="btn-default"
                                    onClick={() => {
                                      const { endService, token } = this.props;
                                      const { date } = this.state;
                                      endService(token, user_id, pivotId, { date }).then(
                                        response => {
                                          console.log(response);
                                          onFetch();
                                          this.toggleModal();
                                        },
                                      );
                                    }}
                                  >
                                    Tak
                                  </Button>
                                </MDBModalFooter>
                              </MDBModal>
                            </>
                          );
                        })}

                        {newServices.map((item, index) => {
                          return (
                            <>
                              <MDBInput
                                label="Adres"
                                name={`newServices__${index}__address`}
                                className={
                                  errors.password_confirmation === undefined ? '' : 'is-invalid'
                                }
                                value={item.address}
                                onChange={onChange}
                              />
                              <MDBSelect
                                options={options}
                                selected="Wybierz usługę"
                                label="Usługa"
                                getValue={value => {
                                  onChangeName(value[0], `newServices__${index}__service_id`);
                                }}
                              />

                              <DatePicker
                                getValue={value => {
                                  onChangeName(
                                    value.getTime() / 1000,
                                    `newServices__${index}__from`,
                                  );
                                }}
                              />
                              <DatePicker
                                getValue={value => {
                                  onChangeName(value.getTime() / 1000, `newServices__${index}__to`);
                                }}
                                disablePast
                              />
                            </>
                          );
                        })}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBBtnFixed
                  onClick={onAddService}
                  floating
                  size="lg"
                  color="dark-purple"
                  icon="plus"
                  style={{ bottom: '45px', right: '24px' }}
                />
              </MDBTabPane>
              <MDBTabPane tabId="4" role="tabpanel">
                {invoiceReminds.map(item => (
                  <MDBRow>
                    <MDBCol>{item.content}</MDBCol>
                    <MDBCol>{item.created_at}</MDBCol>
                  </MDBRow>
                ))}
              </MDBTabPane>

              <MDBTabPane tabId="5" role="tabpanel">
                <h1>{piggyBankAmount} zł</h1>
                {piggy_bank.map(piggyBankItem => (
                  <p>
                    {piggyBankItem.addedAt} - {piggyBankItem.description} - kwota:{' '}
                    {piggyBankItem.amount} zł
                  </p>
                ))}

                <MDBBtnFixed
                  onClick={() => {
                    this.toggleModal('addPiggyBank');
                  }}
                  floating
                  size="lg"
                  color="dark-purple"
                  icon="plus"
                  style={{ bottom: '45px', right: '24px' }}
                />

                <MDBModal isOpen={modal === `addPiggyBank`} toggle={this.toggleModal}>
                  <MDBModalHeader toggle={this.toggleModal}>Dodać do skarbonki?</MDBModalHeader>
                  <MDBModalBody>
                    <DatePicker
                      disableFuture
                      getValue={date => {
                        this.setState({
                          date: date.getTime() / 1000,
                        });
                      }}
                    />

                    <MDBInput
                      label="Kwota"
                      name="net"
                      required
                      value={amountToPay}
                      type="number"
                      min="1"
                      step="0.01"
                      onChange={e => {
                        this.setState({ amountToPay: e.target.value });
                      }}
                    />
                  </MDBModalBody>
                  <MDBModalFooter>
                    <Button className="btn-default" onClick={this.toggleModal}>
                      Anuluj
                    </Button>
                    <Button
                      className="btn-default"
                      onClick={() => {
                        const { date } = this.state;
                        const { token } = this.props;
                        axios
                          .post(
                            `${adminAPI}admin/client/${id}/addPiggyBank`,
                            {
                              addedAt: date,
                              amount: amountToPay,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            },
                          )
                          .then(() => {
                            toast.success('Dodano płatność ze skarbonki');
                            onFetch();
                            this.toggleModal();
                          })
                          .catch(e => {
                            console.log(e.response);
                            toast.error(e.response.data.message);
                            this.toggleModal();
                          });
                      }}
                    >
                      Tak
                    </Button>
                  </MDBModalFooter>
                </MDBModal>
              </MDBTabPane>
            </>
          )}
        </MDBTabContent>

        <Button className="dark-purple" type="submit" color="dark-purple">
          Zapisz
        </Button>
      </form>
    );
  }
}

ClientForm.defaultProps = {
  visible: true,
};

ClientForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
    services: state.servicesAll,
  };
};

export default connect(mapStateToProps, {
  getServices: getAllServicesAction,
  endService: endClientsServiceAction,
})(ClientForm);
