import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { hasScope } from '../helpers';
import { logOutAction } from '../actions';

export default function(ComposedComponent, searchedScope = '') {
  class Authentication extends Component {
    state = {
      redirect: null,
    };

    PropTypes = {
      router: PropTypes.object,
    };

    componentWillMount() {
      const { authenticated, scope } = this.props;
      if (!authenticated) {
        this.setState({
          redirect: '/klient',
        });
      } else if (!hasScope(scope, searchedScope)) {
        this.setState({
          redirect: '/klient',
        });
      }
      /* else if(authenticated) {
          onLogout()
      } */
    }

    componentWillUpdate(nextProps) {
      const { scope, authenticated } = nextProps;
      if (!authenticated) {
        this.setState({
          redirect: '/klient',
        });
      } else if (!hasScope(scope, searchedScope)) {
        this.setState({
          redirect: '/klient',
        });
      }
    }


    render() {
      const { redirect } = this.state;
      if (redirect) return <Redirect to={redirect}/>;

      return (
        <>
          <ComposedComponent {...this.props} />
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: !!state.token,
      token: state.token,
      scope: state.scope,
    };
  }

  return connect(mapStateToProps,
    { onLogout: logOutAction })(Authentication);
}
