import axios from 'axios';
import {GETCLIENTSALL, GETCLIENT, GETCLIENTS, TOKENEXPIRED, adminAPI } from './index';

const url = `admin/client`;

export function getClientsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETCLIENTS, clients: res.data.clients });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}
export function getClientsAllAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/all`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETCLIENTSALL, clients: res.data.clients });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function createClientAction(token, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.user;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function getClientAction(token, id, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETCLIENT, client: res.data.user });
      return res.data.user;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return [];
    }
  };
}

export function updateClientAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.put(`${adminAPI}${url}/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function deleteClientAction(token, id) {
  return async dispatch => {
    try {
      const res = await axios.delete(`${adminAPI}${url}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}

export function endClientsServiceAction(token, userId, serviceId, data={}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}/${userId}/${serviceId}`,data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}
