import axios from 'axios';
import { GETINFOALL, GETINFOS, GETINFO, TOKENEXPIRED, adminAPI } from './index';

const url = `admin/info`;

export function getAllInfosAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}info`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETINFOALL, infos: res.data.infos });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}
export function getInfosAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETINFOS, infos: res.data.infos });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function createInfoAction(token, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function getInfoAction(token, id, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETINFO, info: res.data.info });
      return res.data.info;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return [];
    }
  };
}

export function updateInfoAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.put(`${adminAPI}${url}/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function deleteInfoAction(token, id) {
  return async dispatch => {
    try {
      const res = await axios.delete(`${adminAPI}${url}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.message;
    }
  };
}
