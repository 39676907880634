import axios from 'axios';
import { GETPAYMENTS, TOKENEXPIRED, adminAPI } from './index';

export function getPaymentsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}partner/payments`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      res.data.payments.JARAmount = res.data.JARAmount ?? 0.0;
      res.data.payments.amount = res.data.amount ?? 0.0;
      res.data.payments.partnerAmount = res.data.partnerAmount ?? 0.0;
      res.data.payments.przelewy24Amount = res.data.przelewy24Amount ?? 0.0;
      res.data.payments.feeAll = res.data.feeAll ?? 0.0;
      dispatch({ type: GETPAYMENTS, payments: res.data.payments });
      return res.data.payments;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}

export function exportPaymentsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}partner/payments/export`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      });
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}

export function exportPaymentsCashAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}partner/payments/export/cash`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      });
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}

export function exportPaymentsCancelAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}partner/payments/export/cancel`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      });
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}

export function exportPaymentsPaidAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}admin/invoice/export/paid`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
        // responseType: 'blob',
      });
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response;
    }
  };
}
