import React, { Component } from 'react';
import {
  MDBCardBody,
  MDBCol, MDBIcon,
  MDBRow,
  MDBTable,
} from 'mdbreact';
import { connect } from 'react-redux';
import { StyledMDBCard } from '../../../components/StyledCard';
import { StyledTr } from '../../../components/StyledTr';
import { Button } from '../../../components/Button';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { getPagesAction } from '../../../actions';
import { hasScope } from '../../../helpers';

class InfoListAdmin extends Component {
  state = {};

  componentDidMount() {
    this.fetch();
  }

  fetch = (getPage = 0) => {
    const { getData, token } = this.props;
    if (getPage) {
      getData(token, {});
    } else {
      getData(token, {});
    }
  };

  render() {
    const { items, scope } = this.props;
    return (
      <AdminPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">

                  <h3 className="py-2 color-dark-purple">Lista stron z treścią</h3>

                  <MDBTable hover>
                    <thead>
                    <StyledTr className="rgba-stylish-strong white-text">
                      <th>Tytuł</th>
                      <th>Akcja</th>
                    </StyledTr>
                    </thead>
                    <tbody>

                    {items.map((value) => {
                      const { title, id } = value;
                      return <>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>{title}</td>
                          <td>

                            {hasScope(scope, 'admin-site-edit') &&
                            <Button title="Edytuj" as="a" href={`/panel/admin/strony/${id}`}
                                    className="btn btn-default Ripple-parent" pay><MDBIcon icon="pen"/></Button>
                            }
                          </td>
                        </tr>
                      </>;
                    })}
                    </tbody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>

      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    items: state.pages,
    scope: state.scope,
  };
};

export default connect(
  mapStateToProps,
  {
    getData: getPagesAction,
  },
)(InfoListAdmin);

