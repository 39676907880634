import React, { Component } from 'react';
import {
  MDBBtnFixed,
  MDBCardBody,
  MDBCol,
  MDBCollapse,
  MDBIcon, MDBInput,
  MDBModal,
  MDBModalFooter,
  MDBModalHeader,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBRow,
  MDBTable,
  toast,
} from 'mdbreact';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { StyledMDBCard } from '../../../components/StyledCard';
import { StyledTr } from '../../../components/StyledTr';
import { Button } from '../../../components/Button';
import {adminAPI, deleteClientAction, getClientsAction} from '../../../actions';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { hasScope } from '../../../helpers';
import axios from "axios";

class ClientListAdmin extends Component {
  state = {
    page: 1,
    perPage: 10,
    modal: null,
    redirect: null,
    collapseID: null,
    search: '',
  };

  search = _.debounce(() => {
    this.setState({ page: 1 });
    this.fetch(1);
  }, 1000);

  componentDidMount() {
    this.fetch();
  }

  fetch = (getPage = 0) => {
    const { getData, token } = this.props;
    const { page, perPage, search } = this.state;
    if (getPage) {
      getData(token, { page: getPage, perPage, search });
    } else {
      getData(token, { page, perPage, search });
    }
  };

  getPrevPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setState({
        page: page - 1,
      });
      this.fetch(page - 1);
    }
  };

  getNextPage = () => {
    const { page } = this.state;
    const { items } = this.props;
    const { last_page } = items;
    if (page < last_page) {
      this.setState({
        page: page + 1,
      });
      this.fetch(page + 1);
    }
  };

  getBuildingsPage = newPage => {
    const { page } = this.state;
    if (page !== newPage) {
      this.setState({
        page: newPage,
      });

      this.fetch(newPage);
    }
  };

  pagination = () => {
    const { items } = this.props;
    const { page } = this.state;
    const { last_page } = items;
    const pagesNum = last_page || 1;

    const pages = [];

    let isBreak = true;

    for (let i = 1; i <= pagesNum; i++) {
      if (i < 4 || i > pagesNum - 3 || (i > page - 2 && i < page + 2)) {
        isBreak = true;
        if (page === i) {
          pages.push(
            <MDBPageItem key={i} active>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        } else {
          pages.push(
            <MDBPageItem key={i}>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        }
      } else if (isBreak) {
        isBreak = false;
        pages.push(
          <MDBPageItem key={i}>
            <MDBPageNav>...</MDBPageNav>
          </MDBPageItem>,
        );
      }
    }

    return (
      <MDBPagination circle className="my-4 float-right">
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Poprzednia</span>
          </MDBPageNav>
        </MDBPageItem>
        {pages}
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Następna</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    );
  };

  delete = id => {
    const { deleteItem, token } = this.props;
    deleteItem(token, id).then(response => {
      this.toggle();
      if (response === 'deleted') {
        toast.success('Usunięto klienta');
        this.fetch();
      } else {
        toast.error(response);
      }
    });
  };

  toggle = (modal = null) => {
    this.setState({
      modal,
    });
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }));
  };


  render() {
    const { modal, redirect, collapseID, search } = this.state;

    if (redirect) {
      return <Redirect to={redirect}/>;
    }

    const { items, scope } = this.props;
    return (
      <AdminPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <h3 className="py-2 color-dark-purple">Lista klientów</h3>
                  <div>
                    <MDBInput
                      label="Szukaj..."
                      name="search"
                      value={search}
                      getValue={this.search}
                      onChange={e => {
                        this.setState({ search: e.target.value });
                      }}
                    />
                  </div>

                  <MDBTable hover>
                    <thead>
                    <StyledTr className="rgba-stylish-strong white-text">
                      <th>Imię i nazwisko</th>
                      <th>Login</th>
                      <th>Telefon</th>
                      <th></th>
                      <th>Akcja</th>
                    </StyledTr>
                    </thead>
                    <tbody>
                    {items.data.map(value => {
                      console.log(value);
                      return (
                        <>
                          <tr>
                            <td style={{ verticalAlign: 'middle' }}>{value.name}</td>
                            <td style={{ verticalAlign: 'middle' }}>{value.login}</td>
                            <td style={{ verticalAlign: 'middle' }}>{value.account?.phone}</td>
                            <td style={{ verticalAlign: 'middle' }}>
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={this.toggleCollapse(`basicCollapse${value.id}`)}
                                >
                                  Zobacz usługi
                                  <MDBIcon icon="angle-double-right"/>
                                </span>
                            </td>
                            <td>
                              {hasScope(scope, 'admin-client-edit') && (
                                <Button
                                  title="Edytuj"
                                  as="a"
                                  href={`/panel/admin/klienci/${value.id}`}
                                  className="btn btn-default Ripple-parent"
                                  pay
                                >
                                  <MDBIcon icon="pen"/>
                                </Button>
                              )}

                              {hasScope(scope, 'admin-client-edit') && (
                                  <Button
                                      title="Pobierz plik"
                                      as="a"
                                      className="btn btn-default Ripple-parent"
                                      pay
                                      onClick={() => {
                                        const {token} = this.props;
                                        axios
                                            .get(`${adminAPI}admin/client/${value.id}/file`, {
                                              headers: {
                                                Authorization: `Bearer ${token}`,
                                              },
                                              responseType: 'blob',
                                            })
                                            .then(response => {
                                              const {data} = response;
                                              const url = window.URL.createObjectURL(new Blob([data]));
                                              const link = document.createElement('a');
                                              link.href = url;
                                              link.setAttribute('download', `${value.name}.docx`);
                                              document.body.appendChild(link);
                                              link.click();
                                            });
                                      }}
                                  >
                                    <MDBIcon icon="file-word"/>
                                  </Button>
                              )}

                              {hasScope(scope, 'admin-invoices') && (
                                <Button
                                  title="Faktury"
                                  className="btn btn-default Ripple-parent"
                                  onClick={() => {
                                    this.setState({
                                      redirect: {
                                        pathname: '/panel/admin/faktury',
                                        state: { id: value.name },
                                      },
                                    });
                                  }}
                                  pay
                                >
                                  <MDBIcon icon="file-invoice"/>
                                </Button>
                              )}

                              {hasScope(scope, 'admin-client-delete') && (
                                <>
                                  <Button
                                    title="Usuń"
                                    pay
                                    onClick={() => {
                                      this.toggle(value.id);
                                    }}
                                  >
                                    <MDBIcon icon="trash-alt"/>
                                  </Button>

                                  <MDBModal isOpen={modal === value.id} toggle={this.toggle}>
                                    <MDBModalHeader toggle={this.toggle}>Usunąć?</MDBModalHeader>
                                    <MDBModalFooter>
                                      <Button className="btn-default" onClick={this.toggle}>
                                        Anuluj
                                      </Button>
                                      <Button
                                        className="btn-default"
                                        onClick={() => {
                                          this.delete(value.id);
                                        }}
                                      >
                                        Usuń
                                      </Button>
                                    </MDBModalFooter>
                                  </MDBModal>
                                </>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td
                              colSpan="5"
                              style={{ borderTop: 'none', paddingBottom: 0, paddingTop: 0 }}
                            >
                              <MDBCollapse
                                id={`basicCollapse${value.id}`}
                                isOpen={collapseID === `basicCollapse${value.id}`}
                              >
                                {value.otherServices.map(service => {
                                  return (
                                    <>
                                      <div>
                                        {service.name} {service.vatName}{' '}
                                        {service.prices?.from}
                                      </div>
                                    </>
                                  );
                                })}
                              </MDBCollapse>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    </tbody>
                  </MDBTable>
                  {this.pagination()}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
        {hasScope(scope, 'admin-client-add') && (
          <MDBBtnFixed
            topSection="/panel/admin/klienci/dodaj"
            floating
            size="lg"
            color="dark-purple"
            icon="plus"
            style={{ bottom: '45px', right: '24px' }}
          />
        )}
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    items: state.clients,
    scope: state.scope,
  };
};

export default connect(mapStateToProps, {
  getData: getClientsAction,
  deleteItem: deleteClientAction,
})(ClientListAdmin);
