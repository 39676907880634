import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { connect } from 'react-redux';
import { Button } from '../../../components/Button';

class AdminForm extends Component {
  state = {
    activeItem: '2',
  };

  componentDidMount() {}

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange, edit, changePermissions } = this.props;
    const { name, login, password, password_confirmation, permissions } = form.data;
    const { errors } = form;

    const {
      password: password1,
      login: login1,
      name: name1,
      password_confirmation: password_confirmation1,
    } = errors;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              to="/qwdwqdwq1"
              active={activeItem === '1'}
              onClick={this.toggle('1')}
              role="tab"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              to="/qwdwqdqwdwq2"
              onClick={this.toggle('2')}
              active={activeItem === '2'}
              role="tab"
            >
              <MDBIcon icon="database" /> Uprawnienia
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa"
                      name="name"
                      className={name1 === undefined ? '' : 'is-invalid'}
                      value={name}
                      onChange={onChange}
                    >
                      {name1 !== undefined && <div className="invalid-feedback">{name1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Login"
                      name="login"
                      className={login1 === undefined ? '' : 'is-invalid'}
                      value={login}
                      type="text"
                      onChange={onChange}
                    >
                      {login1 !== undefined && <div className="invalid-feedback">{login1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                {!edit && (
                  <>
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Hasło"
                          name="password"
                          className={password1 === undefined ? '' : 'is-invalid'}
                          value={password}
                          type="password"
                          onChange={onChange}
                        >
                          {password1 !== undefined && (
                            <div className="invalid-feedback">{password1[0]}</div>
                          )}
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="Powtórz hasło"
                          name="password_confirmation"
                          className={password_confirmation1 === undefined ? '' : 'is-invalid'}
                          value={password_confirmation}
                          type="password"
                          onChange={onChange}
                        >
                          {password_confirmation1 !== undefined && (
                            <div className="invalid-feedback">{password_confirmation1[0]}</div>
                          )}
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
            <MDBRow>
              <MDBCol lg={3} md={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Panel"
                      checked={permissions.indexOf('admin-dashboard') !== -1}
                      disabled
                      type="checkbox"
                      id="checkbox4"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Usługi - lista"
                      checked={permissions.indexOf('admin-services') !== -1}
                      onChange={() => {
                        changePermissions('admin-services');
                      }}
                      type="checkbox"
                      id="checkbox-admin-services"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Usługi - dodaj"
                      checked={permissions.indexOf('admin-service-add') !== -1}
                      onChange={() => {
                        changePermissions('admin-service-add');
                      }}
                      type="checkbox"
                      id="checkbox-admin-service-add"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Usługi - edytuj"
                      checked={permissions.indexOf('admin-service-edit') !== -1}
                      onChange={() => {
                        changePermissions('admin-service-edit');
                      }}
                      type="checkbox"
                      id="checkbox-admin-service-edit"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Usługi - usuń"
                      checked={permissions.indexOf('admin-service-delete') !== -1}
                      onChange={() => {
                        changePermissions('admin-service-delete');
                      }}
                      type="checkbox"
                      id="checkbox-admin-service-delete"
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Faktury - lista"
                      checked={permissions.indexOf('admin-invoices') !== -1}
                      onChange={() => {
                        changePermissions('admin-invoices');
                      }}
                      type="checkbox"
                      id="checkbox-admin-invoices"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Faktury - wyślij przypomnienie"
                      checked={permissions.indexOf('admin-invoice-send-remind') !== -1}
                      onChange={() => {
                        changePermissions('admin-invoice-send-remind');
                      }}
                      type="checkbox"
                      id="checkbox-admin-invoice-send-remind"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Faktury - oznacz jako zapłacone"
                      checked={permissions.indexOf('admin-invoice-set-paid') !== -1}
                      onChange={() => {
                        changePermissions('admin-invoice-set-paid');
                      }}
                      type="checkbox"
                      id="checkbox-admin-invoice-set-paid"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Faktury - usuń"
                      checked={permissions.indexOf('admin-invoice-delete') !== -1}
                      onChange={() => {
                        changePermissions('admin-invoice-delete');
                      }}
                      type="checkbox"
                      id="checkbox-admin-invoice-delete"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Faktury - dodaj"
                      checked={permissions.indexOf('admin-invoice-add') !== -1}
                      onChange={() => {
                        changePermissions('admin-invoice-add');
                      }}
                      type="checkbox"
                      id="checkbox-admin-invoice-add"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg={3} md={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Klienci - lista"
                      checked={permissions.indexOf('admin-clients') !== -1}
                      onChange={() => {
                        changePermissions('admin-clients');
                      }}
                      type="checkbox"
                      id="checkbox-admin-clients"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Klienci - dodaj"
                      checked={permissions.indexOf('admin-client-add') !== -1}
                      onChange={() => {
                        changePermissions('admin-client-add');
                      }}
                      type="checkbox"
                      id="checkbox-admin-client-add"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Klienci - edytuj"
                      checked={permissions.indexOf('admin-client-edit') !== -1}
                      onChange={() => {
                        changePermissions('admin-client-edit');
                      }}
                      type="checkbox"
                      id="checkbox-admin-client-edit"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Klienci - usuń"
                      checked={permissions.indexOf('admin-client-delete') !== -1}
                      onChange={() => {
                        changePermissions('admin-client-delete');
                      }}
                      type="checkbox"
                      id="checkbox-admin-client-delete"
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Partnerzy - lista"
                      checked={permissions.indexOf('admin-partners') !== -1}
                      onChange={() => {
                        changePermissions('admin-partners');
                      }}
                      type="checkbox"
                      id="checkbox-admin-partners"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Partnerzy - dodaj"
                      checked={permissions.indexOf('admin-partner-add') !== -1}
                      onChange={() => {
                        changePermissions('admin-partner-add');
                      }}
                      type="checkbox"
                      id="checkbox-admin-partner-add"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Partnerzy - edytuj"
                      checked={permissions.indexOf('admin-partner-edit') !== -1}
                      onChange={() => {
                        changePermissions('admin-partner-edit');
                      }}
                      type="checkbox"
                      id="checkbox-admin-partner-edit"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Partnerzy - usuń"
                      checked={permissions.indexOf('admin-partner-delete') !== -1}
                      onChange={() => {
                        changePermissions('admin-partner-delete');
                      }}
                      type="checkbox"
                      id="checkbox-admin-partner-delete"
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Raporty - lista"
                      checked={permissions.indexOf('admin-reports') !== -1}
                      onChange={() => {
                        changePermissions('admin-reports');
                      }}
                      type="checkbox"
                      id="checkbox-admin-reports"
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Raporty własne - lista"
                      checked={permissions.indexOf('admin-own-reports') !== -1}
                      onChange={() => {
                        changePermissions('admin-own-reports');
                      }}
                      type="checkbox"
                      id="checkbox-admin-own-reports"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg={3} md={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Administratorzy - lista"
                      checked={permissions.indexOf('admin-admins') !== -1}
                      onChange={() => {
                        changePermissions('admin-admins');
                      }}
                      type="checkbox"
                      id="checkbox-admin-admins"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Administratorzy - dodaj"
                      checked={permissions.indexOf('admin-admin-add') !== -1}
                      onChange={() => {
                        changePermissions('admin-admin-add');
                      }}
                      type="checkbox"
                      id="checkbox-admin-admin-add"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Administratorzy - edytuj"
                      checked={permissions.indexOf('admin-admin-edit') !== -1}
                      onChange={() => {
                        changePermissions('admin-admin-edit');
                      }}
                      type="checkbox"
                      id="checkbox-admin-admin-edit"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Administratorzy - usuń"
                      checked={permissions.indexOf('admin-admin-delete') !== -1}
                      onChange={() => {
                        changePermissions('admin-admin-delete');
                      }}
                      type="checkbox"
                      id="checkbox-admin-admin-delete"
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Informacje - lista"
                      checked={permissions.indexOf('admin-infos') !== -1}
                      onChange={() => {
                        changePermissions('admin-infos');
                      }}
                      type="checkbox"
                      id="checkbox-admin-infos"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Informacje - dodaj"
                      checked={permissions.indexOf('admin-info-add') !== -1}
                      onChange={() => {
                        changePermissions('admin-info-add');
                      }}
                      type="checkbox"
                      id="checkbox-admin-info-add"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Informacje - edytuj"
                      checked={permissions.indexOf('admin-info-edit') !== -1}
                      onChange={() => {
                        changePermissions('admin-info-edit');
                      }}
                      type="checkbox"
                      id="checkbox-admin-info-edit"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Informacje - usuń"
                      checked={permissions.indexOf('admin-info-delete') !== -1}
                      onChange={() => {
                        changePermissions('admin-info-delete');
                      }}
                      type="checkbox"
                      id="checkbox-admin-info-delete"
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Strony - lista"
                      checked={permissions.indexOf('admin-sites') !== -1}
                      onChange={() => {
                        changePermissions('admin-sites');
                      }}
                      type="checkbox"
                      id="checkbox-admin-sites"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Strony - edytuj"
                      checked={permissions.indexOf('admin-site-edit') !== -1}
                      onChange={() => {
                        changePermissions('admin-site-edit');
                      }}
                      type="checkbox"
                      id="checkbox-admin-site-edit"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg={3} md={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Logi - lista"
                      checked={permissions.indexOf('admin-logs') !== -1}
                      onChange={() => {
                        changePermissions('admin-logs');
                      }}
                      type="checkbox"
                      id="checkbox-admin-logs"
                    />
                    {permissions}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <Button className="dark-purple" type="submit" color="dark-purple">
          Zapisz
        </Button>
      </form>
    );
  }
}

AdminForm.defaultProps = {};

AdminForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(AdminForm);
