import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'mdbreact';
import { getPartnerAction, updatePartnerAction } from '../../../actions';
import ServiceForm from './Form';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import { StyledMDBCard } from '../../../components/StyledCard';

class PartnerAdminEdit extends Component {
  state = {
    form: {
      data: {
        client: {},
        p24: {},
        contracts: [],
      },
      errors: {},
    },
    itemId: 0,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { match, getItem, token } = this.props;
    this.setState({
      itemId: match.params.id,
    });

    getItem(token, match.params.id).then(item => {
      const { form } = this.state;
      const newForm = { ...form };
      Object.keys(item).forEach(key => {
        if (key === 'partnerAccount') {
          const { partnerAccount } = item;
          if (partnerAccount) {
            Object.keys(partnerAccount).forEach(key1 => {
              newForm.data[key1] = partnerAccount[key1];
            });
          }
        } else {
          newForm.data[key] = item[key];
        }
      });

      this.setState({ form: newForm });
    });
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else if (splited.length === 2) {
      newForm.data[splited[0]][splited[1]] = value;
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeName = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleVisibleChange = value => {
    this.setState(state => {
      const { form } = state;
      const newForm = { ...form };
      newForm.data.visible = value ? 1 : 0;
      return {
        form: newForm,
      };
    });
  };

  handleVatChanged = value => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.data.vat_id = value;
    this.setState({ form: newForm });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { updateItem, token } = this.props;
    const { form, itemId } = this.state;
    const { data } = form;

    updateItem(token, itemId, data).then(response => {
      if (response === 'updated') {
        toast.success('Zaktualizowano dane');
        const newForm = { ...form };
        newForm.data.newServices = [];
        this.setState({ form: newForm });
        this.fetch();
      } else {
        toast.error('Nie udało się zapisać zmian. Sprawdź błędy.');
        const newForm = { ...form };
        newForm.errors = response;
        this.setState({ form: newForm });
      }
    });
  };

  handleAddService = () => {
    const { form } = this.state;
    const newForm = { ...form };
    const { newServices } = newForm.data;
    newServices.push({
      service_id: null,
      address: '',
      from: parseInt(new Date().getTime() / 1000, 0),
      to: parseInt(new Date().getTime() / 1000, 0),
    });

    newForm.data.newServices = newServices;
    this.setState({ form: newForm });
  };

  render() {
    const { form, date } = this.state;

    return (
      <AdminPanelTemplate>
        <section>
          <StyledMDBCard className="mb-5 p-4">
            <h3 className="py-2 color-dark-purple">Edytuj partnera</h3>
            <ServiceForm
              form={form}
              date={date}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onVisibleChange={this.handleVisibleChange}
              onChangeName={this.handleChangeName}
              onVatChanged={this.handleVatChanged}
              onAddService={this.handleAddService}
              onFetch={this.fetch}
              edit
            />
          </StyledMDBCard>
        </section>
      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  getItem: getPartnerAction,
  updateItem: updatePartnerAction,
})(PartnerAdminEdit);
