import React, { Component } from 'react';
import { MDBCardBody, MDBCol, MDBIcon, MDBRow, MDBTable } from 'mdbreact';
import { connect } from 'react-redux';
import { StyledMDBCard } from '../../components/StyledCard';
import { StyledTr } from '../../components/StyledTr';
import { getDataAction } from '../../actions';
import PartnerPanelTemplate from '../../templates/PartnerPanelTemplate';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

class ContractsList extends Component {
  componentDidMount() {
    const { getData, token, userData } = this.props;
    if (!userData.id) {
      getData(token);
    }
  }

  render() {
    const { userData } = this.props;
    const { contracts } = userData;

    return (
      <PartnerPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <h3 className="py-2 color-dark-purple">Lista umów</h3>

                  <MDBTable hover>
                    <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Data</th>
                        <th>Plik</th>
                      </StyledTr>
                    </thead>
                    <tbody>
                      {contracts.map(value => {
                        const { created_at, id, name, path } = value;
                        return (
                          <tr key={id}>
                            <td style={{ verticalAlign: 'middle' }}>{created_at}</td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <a href={path} target="_blank" rel="noopener noreferrer">
                                <MDBIcon size="2x" icon="file-alt" /> {name}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
      </PartnerPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    userData: state.userData,
  };
};

export default connect(mapStateToProps, {
  getData: getDataAction,
})(ifAcceptRegulations(ContractsList));
