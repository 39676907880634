import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBMask,
  MDBNavbar,
  MDBRow,
  MDBView,
  ToastContainer,
  toast,
} from 'mdbreact';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { resetAction } from '../actions';
import { Button } from '../components/Button';
import Logo from '../static/logo.png';
import Background from '../static/background.jpg';

const StyledImg = styled.img`
  height: 120px;
`;
const StyledWrapper = styled.div`
  background-image: url(${Background});
`;
const StyledMDBView = styled(MDBView)`
  background-image: url(${Background});
`;
const StyledLink = styled(Link)`
  color: #ffffff;
  display: flex;
  justify-content: center;
  :hover {
    color: #dadada;
  }
`;

class Reset extends React.Component {
  state = {
    login: '',
  };

  reset = event => {
    event.preventDefault();
    const { reset } = this.props;
    const { login } = this.state;
    reset({ login }).then(response => {
      if (response === 'Wysłano') {
        toast.success('Na podany adres email zostało wysłane nowe hasło');
      } else {
        toast.error(response);
      }
    });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  errorMessage() {
    const { errorMessage } = this.props;
    return errorMessage ? <div className="info-red">{errorMessage}</div> : <></>;
  }

  render() {
    const { login } = this.state;
    return (
      <StyledWrapper className="classic-form-page" id="login">
        <MDBNavbar color="deep-purple darken-4" dark expand="md" fixed="top" scrolling transparent>
          <MDBContainer className="d-flex align-items-center justify-content-center">
            <StyledImg src={Logo} alt="" />
          </MDBContainer>
        </MDBNavbar>
        <StyledMDBView>
          <MDBMask className="d-flex justify-content-center align-items-center">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="9" xl="7" sm="12" className="mt-5 mx-auto">
                  <MDBCard style={{ borderRadius: '100px' }}>
                    <MDBCardBody className="d-flex align-items-center justify-content-center flex-column">
                      <div
                        className="form-header dark-purple px-5"
                        style={{ borderRadius: '100000px' }}
                      >
                        <h4>
                          <MDBIcon
                            icon="user"
                            className="mt-2 mb-2 mr-2 text-white font-weight-bold"
                          />
                          PANEL KLIENTA - RESET HASŁA
                        </h4>
                      </div>
                      {this.errorMessage()}
                      <form
                        id="myForm"
                        className="needs-validation w-100 pb-5 px-5"
                        onSubmit={this.reset}
                        noValidate
                      >
                        <MDBInput
                          type="text"
                          label="Login"
                          icon="envelope"
                          iconClass="white-text"
                          name="login"
                          value={login}
                          onChange={this.changeHandler}
                        />
                        <div className="text-center mt-5 black-text">
                          <Button type="submit" className="font-weight-bold" size="lg">
                            Zresetuj hasło
                          </Button>
                        </div>
                        <StyledLink to="/klient">Logowanie</StyledLink>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </StyledMDBView>
        <ToastContainer hideProgressBar newestOnTop />
      </StyledWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    errorMessage: state.error,
  };
};

export default withRouter(connect(mapStateToProps, { reset: resetAction })(Reset));
