import React, { Component } from 'react';
import {
  MDBCardBody,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCol,
  MDBRow,
} from 'mdbreact';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { StyledMDBCard } from '../components/StyledCard';
import { getAllInfosAction } from '../actions';
import OwnCarouselItem from './CarouselItem';

const StyledMDBCarouselInner = styled(MDBCarouselInner)`
  width: auto !important;
  margin: 0 30px;
`;

class ImportantInfo extends Component {
  componentDidMount() {
    const { getInfo, token } = this.props;
    getInfo(token);
  }

  chunk = array => {
    const sets = [];
    const groupSize = 3;
    const chunks = array.length / groupSize;
    let i = 0;

    while (i < chunks) {
      sets[i] = array.splice(0, groupSize);
      i += 1;
    }

    return sets;
  };

  render() {
    const { info } = this.props;

    const chunked = this.chunk(info);
    const { length } = chunked;

    if (length) {
      return (
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow>
              <MDBCol>
                <MDBCardBody>
                  <h4 className="text-center">Ważne informacje</h4>
                  <MDBCarousel
                    activeItem={0}
                    length={length - 1}
                    interval={99999999999999}
                    showIndicators={false}
                    slide
                    showControls
                    multiItem
                  >
                    <StyledMDBCarouselInner>
                      <MDBRow>
                        {chunked.map((value, index) => {
                          // eslint-disable-next-line react/no-array-index-key
                          return (
                            <MDBCarouselItem key={`MDBCarouselItem${index}`} itemId={index}>
                              {value.map(item => {
                                return <OwnCarouselItem {...item} />;
                              })}
                            </MDBCarouselItem>
                          );
                        })}
                      </MDBRow>
                    </StyledMDBCarouselInner>
                  </MDBCarousel>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
      );
    }

    return <></>;
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    info: state.infosAll,
  };
};

export default connect(
  mapStateToProps,
  {
    getInfo: getAllInfosAction,
  },
)(ImportantInfo);
