import React from 'react';
import { Redirect } from 'react-router-dom';

function ifAcceptRegulations(WrappedComponent) {
  return class extends React.Component {
    state = {
      redirect: null,
    };

    componentDidMount() {
      const AcceptRegulations = localStorage.getItem('ifAcceptRegulations');
      if (AcceptRegulations === 'false') {
        this.setState({ redirect: '/regulamin/akceptacja' });
      }
    }

    render() {
      const { redirect } = this.state;
      console.log(redirect);
      if (redirect) return <Redirect to={redirect} />;
      return <WrappedComponent {...this.props} />;
    }
  };
}
export default ifAcceptRegulations;
