import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { connect } from 'react-redux';
import { Button } from '../../../components/Button';

class PageForm extends Component {
  state = {
    activeItem: '1',
  };

  toggle = tab => e => {
    e.preventDefault();
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChangeName } = this.props;
    const { content } = form.data;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              to="/asd1"
              active={activeItem === '1'}
              onClick={this.toggle('1')}
              role="tab"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <CKEditor
                        editor={ ClassicEditor }
                        data={content}
                        onChange={evt => {
                          if(typeof evt.editor !== "undefined"){
                            onChangeName(evt.editor.getData(), 'content');
                          }
                        }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <Button className="dark-purple" type="submit" color="dark-purple">
          Zapisz
        </Button>
      </form>
    );
  }
}

PageForm.defaultProps = {};

PageForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(PageForm);
