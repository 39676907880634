import axios from "axios";
import {adminAPI, TOKENEXPIRED} from "./index";

const url = `admin/insert/sync`;

export function syncInvoiceAction(token, id, data = {}) {
    return async dispatch => {
        try {
            const res = await axios.post(`${adminAPI}${url}/invoice/${id}`, data, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return res.data.status;
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: TOKENEXPIRED });
            }
            return error.response.data.message;
        }
    };
}

export function syncServiceAction(token, id, data = {}) {
    return async dispatch => {
        try {
            const res = await axios.post(`${adminAPI}${url}/service/${id}`, data, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return res.data.status;
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: TOKENEXPIRED });
            }
            return error.response.data.message;
        }
    };
}
