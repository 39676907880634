import React from 'react';
import { connect } from 'react-redux';
import { MDBIcon, MDBSideNavLink, MDBSideNavNav } from 'mdbreact';
import PanelTemplate from './PanelTemplate';

function PartnerPanelTemplate(props) {
  const { children } = props;
  const SideNavChilds = [
    <p className="text-center">PANEL PARTNERA</p>,
    <MDBSideNavLink topLevel exact to="/panel/partner">
      <MDBIcon fixed icon="th-large mr-2" />
      START
    </MDBSideNavLink>,
    <MDBSideNavLink topLevel exact to="/panel/partner/umowy">
      <MDBIcon fixed icon="file-signature mr-2" />
      UMOWY
    </MDBSideNavLink>,
    <MDBSideNavLink topLevel exact to="/panel/partner/raporty">
      <MDBIcon fixed icon="file-medical-alt mr-2" />
      RAPORTY
    </MDBSideNavLink>,
    <MDBSideNavLink topLevel exact to="/panel/partner/dane">
      <MDBIcon fixed icon="user mr-2" />
      DANE
    </MDBSideNavLink>,
  ];
  const SideNav = <MDBSideNavNav>{SideNavChilds.map(item => item)}</MDBSideNavNav>;

  const TopMenu = <></>;

  return (
    <PanelTemplate SideNav={SideNav} TopMenu={TopMenu}>
      {children}
    </PanelTemplate>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(PartnerPanelTemplate);
