import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import MainTemplate from './templates/MainTemplate';
import Login from './views/Login';
import Manage from './views/Manage';
import requireAuth from './middlewares/require_auth';
import noRequireAuth from './middlewares/require_guest';
import NotFound from './views/NotFound';
import Services from './views/User/Services';

import MainPanel from './views/User/MainPanel';
import Settlement from './views/User/Settlement';
import Settings from './views/User/Settings';
import Contact from './views/User/Contact';
import Support from './views/User/Support';
import RODO from './views/User/RODO';
import Regulations from './views/User/Regulations';
import PrivacyPolicy from './views/User/PrivacyPolicy';

import PartnerMainPanel from './views/Partner/MainPanel';
import AdminMainPanel from './views/Admin/MainPanel';

import ServiceList from './views/Admin/Service/List';
import ServiceCreate from './views/Admin/Service/Create';
import ServiceEdit from './views/Admin/Service/Edit';

import InvoiceListAdmin from './views/Admin/Invoice/List';
import InvoiceAddAdmin from './views/Admin/Invoice/Create';
import InvoiceEditAdmin from './views/Admin/Invoice/Edit';

import ClientsListAdmin from './views/Admin/Client/List';
import ClientAdminCreate from './views/Admin/Client/Create';
import ClientAdminEdit from './views/Admin/Client/Edit';

import PartnersListAdmin from './views/Admin/Partner/List';
import PartnerAdminCreate from './views/Admin/Partner/Create';
import PartnerAdminEdit from './views/Admin/Partner/Edit';

import AdminsListAdmin from './views/Admin/Admin/List';
import AdminAdminCreate from './views/Admin/Admin/Create';
import AdminAdminEdit from './views/Admin/Admin/Edit';

import InfoListAdmin from './views/Admin/Info/List';
import InfoAdminCreate from './views/Admin/Info/Create';
import InfoAdminEdit from './views/Admin/Info/Edit';

import PartnerReportsList from './views/Partner/Report';
import PartnerContractsList from './views/Partner/Contract';
import PartnerSettings from './views/Partner/Settings';

import RegulationsListAdmin from './views/Admin/Regulations/List';
import RegulationsAdminCreate from './views/Admin/Regulations/Create';
import RegulationsAdminEdit from './views/Admin/Regulations/Edit';

import RodoListAdmin from './views/Admin/Rodo/List';
import RodoAdminCreate from './views/Admin/Rodo/Create';
import RodoAdminEdit from './views/Admin/Rodo/Edit';

import PolicyListAdmin from './views/Admin/PrivacyPolicy/List';
import PolicyAdminCreate from './views/Admin/PrivacyPolicy/Create';
import PolicyAdminEdit from './views/Admin/PrivacyPolicy/Edit';

import AdminReportsList from './views/Admin/Report';

import PagesListAdmin from './views/Admin/Page/List';
import PageEditAdmin from './views/Admin/Page/Edit';
import Reset from './views/Reset';
import Thanks from './views/Thanks';
import ReportSelf from './views/Admin/ReportSelf';

import LogList from './views/Admin/Log/List';

import LoginPartner from './views/LoginPartner';
import LoginAdmin from './views/LoginAdmin';
import AcceptRegulations from './views/AcceptRegulations';

import Policy from './views/PrivacyPolicy';
import NotLoggedRegulations from './views/Regulations';

import {List as RemindsList} from './views/Admin/Reminds/List';
import ExportList from "./views/Admin/Insert/List";

function Root() {
  return (
    <Provider store={store}>
      <MainTemplate>
        <BrowserRouter>
          <Switch>
            <Route
              key="main"
              path="/"
              exact
              component={() => {
                return <Redirect to="/klient" />;
              }}
            />
            <Route key="klient" path="/klient" exact component={noRequireAuth(Login)} />
            <Route key="partner" path="/partner" exact component={noRequireAuth(LoginPartner)} />
            <Route key="admin" path="/admin" exact component={noRequireAuth(LoginAdmin)} />

            <Route key="Reset" path="/reset" exact component={noRequireAuth(Reset)} />

            <Route
              key="Panel"
              path="/panel"
              exact
              component={requireAuth(MainPanel, 'user-dashboard')}
            />
            <Route
              key="Services"
              path="/uslugi"
              exact
              component={requireAuth(Services, 'user-dashboard')}
            />
            <Route
              key="Settlement"
              path="/rozliczenia"
              exact
              component={requireAuth(Settlement, 'user-dashboard')}
            />
            <Route
              key="Settings"
              path="/dane"
              exact
              component={requireAuth(Settings, 'user-dashboard')}
            />
            <Route
              key="Contact"
              path="/kontakt"
              exact
              component={requireAuth(Contact, 'user-dashboard')}
            />
            <Route
              key="Support"
              path="/wsparcie"
              exact
              component={requireAuth(Support, 'user-dashboard')}
            />
            <Route key="RODO" path="/rodo" exact component={requireAuth(RODO, 'user-dashboard')} />
            <Route
              key="privacyPolicy"
              path="/panel/polityka-prywatnosci"
              exact
              component={requireAuth(PrivacyPolicy, 'user-dashboard')}
            />
            <Route
              key="regulations"
              path="/panel/regulamin"
              exact
              component={requireAuth(Regulations, 'user-dashboard')}
            />

            <Route
              key="PanelAdmin"
              path="/panel/admin"
              exact
              component={requireAuth(AdminMainPanel, 'admin-dashboard')}
            />

            <Route
              key="PanelAdmin"
              path="/panel/admin/przypomnienia"
              exact
              component={requireAuth(RemindsList, 'admin-dashboard')}
            />

            <Route
              key="ServiceList"
              path="/panel/admin/uslugi"
              exact
              component={requireAuth(ServiceList, 'admin-services')}
            />
            <Route
              key="ServiceCreate"
              path="/panel/admin/uslugi/dodaj"
              exact
              component={requireAuth(ServiceCreate, 'admin-service-add')}
            />
            <Route
              key="ServiceEdit"
              path="/panel/admin/uslugi/:id"
              component={requireAuth(ServiceEdit, 'admin-service-edit')}
            />

            <Route
              key="PagesListAdmin"
              path="/panel/admin/strony"
              exact
              component={requireAuth(PagesListAdmin, 'admin-sites')}
            />
            <Route
              key="PageEditAdmin"
              path="/panel/admin/strony/:id"
              exact
              component={requireAuth(PageEditAdmin, 'admin-site-edit')}
            />

            <Route
              key="InvoiceListAdmin"
              path="/panel/admin/faktury"
              exact
              component={requireAuth(InvoiceListAdmin, 'admin-invoices')}
            />

            <Route
                key="InvoiceListAdmin"
                path="/panel/admin/export"
                exact
                component={requireAuth(ExportList, 'admin-invoices')}
            />
            <Route
              key="InvoiceAddAdmin"
              path="/panel/admin/faktury/dodaj"
              exact
              component={requireAuth(InvoiceAddAdmin, 'admin-invoice-add')}
            />
            <Route
              key="InvoiceEditAdmin"
              path="/panel/admin/faktury/:id"
              exact
              component={requireAuth(InvoiceEditAdmin, 'admin-invoice-add')}
            />

            <Route
              key="ClientsListAdmin"
              path="/panel/admin/klienci"
              exact
              component={requireAuth(ClientsListAdmin, 'admin-clients')}
            />
            <Route
              key="ClientAdminCreate"
              path="/panel/admin/klienci/dodaj"
              exact
              component={requireAuth(ClientAdminCreate, 'admin-client-add')}
            />
            <Route
              key="ClientAdminEdit"
              path="/panel/admin/klienci/:id"
              exact
              component={requireAuth(ClientAdminEdit, 'admin-client-edit')}
            />

            <Route
              key="PartnersListAdmin"
              path="/panel/admin/partnerzy"
              exact
              component={requireAuth(PartnersListAdmin, 'admin-partners')}
            />
            <Route
              key="PartnerAdminCreate"
              path="/panel/admin/partnerzy/dodaj"
              exact
              component={requireAuth(PartnerAdminCreate, 'admin-partner-edit')}
            />
            <Route
              key="PartnerAdminEdit"
              path="/panel/admin/partnerzy/:id"
              exact
              component={requireAuth(PartnerAdminEdit, 'admin-partner-edit')}
            />

            <Route
              key="AdminsListAdmin"
              path="/panel/admin/administratorzy"
              exact
              component={requireAuth(AdminsListAdmin, 'admin-admins')}
            />
            <Route
              key="AdminAdminCreate"
              path="/panel/admin/administratorzy/dodaj"
              exact
              component={requireAuth(AdminAdminCreate, 'admin-admin-add')}
            />
            <Route
              key="AdminAdminEdit"
              path="/panel/admin/administratorzy/:id"
              exact
              component={requireAuth(AdminAdminEdit, 'admin-admin-edit')}
            />

            <Route
              key="InfoListAdmin"
              path="/panel/admin/informacje"
              exact
              component={requireAuth(InfoListAdmin, 'admin-infos')}
            />
            <Route
              key="InfoAdminCreate"
              path="/panel/admin/informacje/dodaj"
              exact
              component={requireAuth(InfoAdminCreate, 'admin-info-add')}
            />
            <Route
              key="InfoAdminEdit"
              path="/panel/admin/informacje/:id"
              exact
              component={requireAuth(InfoAdminEdit, 'admin-info-edit')}
            />

            <Route
              key="AdminReportsList"
              path="/panel/admin/raporty"
              exact
              component={requireAuth(AdminReportsList, 'admin-reports')}
            />
            <Route
              key="ReportSelf"
              path="/panel/admin/raporty/wlasne"
              exact
              component={requireAuth(ReportSelf, 'admin-own-reports')}
            />

            <Route
              key="LogList"
              path="/panel/admin/logi"
              exact
              component={requireAuth(LogList, 'admin-logs')}
            />

            <Route
              key="PanelPartner"
              path="/panel/partner"
              exact
              component={requireAuth(PartnerMainPanel, 'partner-dashboard')}
            />
            <Route
              key="PartnerReportsList"
              path="/panel/partner/raporty"
              exact
              component={requireAuth(PartnerReportsList, 'partner-dashboard')}
            />
            <Route
              key="PartnerContractsList"
              path="/panel/partner/umowy"
              exact
              component={requireAuth(PartnerContractsList, 'partner-dashboard')}
            />
            <Route
              key="PartnerSettings"
              path="/panel/partner/dane"
              exact
              component={requireAuth(PartnerSettings, 'partner-dashboard')}
            />

            <Route key="Thanks" path="/podziekowania" exact component={Thanks} />

            <Route key="Manage" path="/Manage" exact component={requireAuth(Manage)} />
            <Route
              key="AcceptRegulations"
              path="/regulamin/akceptacja"
              exact
              component={requireAuth(AcceptRegulations)}
            />

            <Route
              key="RegulationsListAdmin"
              path="/panel/admin/regulaminy"
              exact
              component={requireAuth(RegulationsListAdmin, 'admin-dashboard')}
            />
            <Route
              key="RegulationsAdminCreate"
              path="/panel/admin/regulaminy/dodaj"
              exact
              component={requireAuth(RegulationsAdminCreate, 'admin-dashboard')}
            />
            <Route
              key="RegulationsAdminEdit"
              path="/panel/admin/regulaminy/:id"
              exact
              component={requireAuth(RegulationsAdminEdit, 'admin-dashboard')}
            />

            <Route
              key="RodoListAdmin"
              path="/panel/admin/rodo"
              exact
              component={requireAuth(RodoListAdmin, 'admin-dashboard')}
            />
            <Route
              key="RodoAdminCreate"
              path="/panel/admin/rodo/dodaj"
              exact
              component={requireAuth(RodoAdminCreate, 'admin-dashboard')}
            />
            <Route
              key="RodoAdminEdit"
              path="/panel/admin/rodo/:id"
              exact
              component={requireAuth(RodoAdminEdit, 'admin-dashboard')}
            />

            <Route
              key="PolicyListAdmin"
              path="/panel/admin/polityka-prywatnosci"
              exact
              component={requireAuth(PolicyListAdmin, 'admin-dashboard')}
            />
            <Route
              key="PolicyAdminCreate"
              path="/panel/admin/polityka-prywatnosci/dodaj"
              exact
              component={requireAuth(PolicyAdminCreate, 'admin-dashboard')}
            />
            <Route
              key="PolicyAdminEdit"
              path="/panel/admin/polityka-prywatnosci/:id"
              exact
              component={requireAuth(PolicyAdminEdit, 'admin-dashboard')}
            />
            <Route key="Policy" path="/polityka-prywatnosci" exact component={Policy} />
            <Route key="Regulations" path="/regulamin" exact component={NotLoggedRegulations} />

            <Route key="NotFound" path="*" exact component={NotFound} />
          </Switch>
        </BrowserRouter>
      </MainTemplate>
    </Provider>
  );
}

export default Root;
