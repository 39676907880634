import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Button } from '../components/Button';
import { acceptAction, adminAPI, logOutAction } from '../actions';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
const ContentDiv = styled.div`
  height: 80%;
  overflow: scroll;
  max-width: 1200px;
`;
const AcceptRegulations = ({ token, onLogout, onAccept }) => {
  const [redirect, setRedirect] = useState(null);
  const [text, setText] = useState('');

  useEffect(() => {
    axios
      .get(`${adminAPI}regulations`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { regulations } = response.data;
        setText(regulations.content);
      });
  });

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <StyledWrapper>
        <h3>Regulamin</h3>
        <ContentDiv
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <ButtonsWrapper>
          <Button
            color="dark-purple"
            className="dark-purple"
            type="button"
            onClick={() => {
              onLogout(token);
            }}
          >
            ODRZUCAM
          </Button>
          <Button
            color="dark-purple"
            className="dark-purple"
            type="button"
            onClick={() => {
              onAccept(token).then(() => {
                setRedirect('/');
              });
            }}
          >
            AKCEPTUJE
          </Button>
        </ButtonsWrapper>
      </StyledWrapper>
    </>
  );
};
const mapStateToProps = ({ token }) => {
  return { token };
};
export default connect(
  mapStateToProps,
  {
    onLogout: logOutAction,
    onAccept: acceptAction,
  },
)(AcceptRegulations);
