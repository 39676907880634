import React, { Component } from 'react';
import styled from 'styled-components';
import {
  MDBCardBody,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBRow,
  MDBTable,
  toast,
} from 'mdbreact';
import { connect } from 'react-redux';
import axios from 'axios';
import { StyledMDBCard } from '../../components/StyledCard';
import { StyledTr } from '../../components/StyledTr';
import { Button } from '../../components/Button';
import { getInvoicesAction, payInvoiceAction } from '../../actions';
import UserPanelTemplate from '../../templates/UserPanelTemplate';
import ifAcceptRegulations from '../../middlewares/ifAcceptRegulations';

const StyledP = styled.p`
  margin: 0;
  padding: 0;
`;

class Settlement extends Component {
  state = {
    collapseID: '',
    page: 1,
    perPage: 10,
    disabled: false,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = (getPage = 0) => {
    const { getData, token } = this.props;
    const { page, perPage } = this.state;
    if (getPage) {
      getData(token, { page: getPage, perPage });
    } else {
      getData(token, { page, perPage });
    }
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }));
  };

  getPrevPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setState({
        page: page - 1,
      });
      this.fetch(page - 1);
    }
  };

  getNextPage = () => {
    const { page } = this.state;
    const { invoices } = this.props;
    if (page < invoices.last_page) {
      this.setState({
        page: page + 1,
      });
      this.fetch(page + 1);
    }
  };

  getBuildingsPage = newPage => {
    const { page } = this.state;
    if (page !== newPage) {
      this.setState({
        page: newPage,
      });

      this.fetch(newPage);
    }
  };

  pagination = () => {
    const { invoices } = this.props;
    const { page } = this.state;
    const itemsData = invoices;
    const pagesNum = itemsData.last_page ? itemsData.last_page : 1;

    const pages = [];

    let isBreak = true;

    for (let i = 1; i <= pagesNum; i++) {
      if (i < 4 || i > pagesNum - 3 || (i > page - 2 && i < page + 2)) {
        isBreak = true;
        if (page === i) {
          pages.push(
            <MDBPageItem key={i} active>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        } else {
          pages.push(
            <MDBPageItem key={i}>
              <MDBPageNav
                className="page-link"
                onClick={() => {
                  this.getBuildingsPage(i);
                }}
              >
                {i}
              </MDBPageNav>
            </MDBPageItem>,
          );
        }
      } else if (isBreak) {
        isBreak = false;
        pages.push(
          <MDBPageItem key={i}>
            <MDBPageNav>...</MDBPageNav>
          </MDBPageItem>,
        );
      }
    }

    return (
      <MDBPagination circle className="my-4 float-right">
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Poprzednia</span>
          </MDBPageNav>
        </MDBPageItem>
        {pages}
        <MDBPageItem>
          <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Następna</span>
          </MDBPageNav>
        </MDBPageItem>
      </MDBPagination>
    );
  };

  render() {
    const { collapseID, disabled } = this.state;
    const { invoices } = this.props;
    return (
      <UserPanelTemplate pageTitle="">
        <section>
          <StyledMDBCard className="mb-5">
            <MDBRow className="my-4">
              <MDBCol md="12">
                <MDBCardBody className="pt-0">
                  <MDBTable hover>
                    <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Usługa</th>
                        <th>Numer faktury</th>
                        <th>Status</th>
                        <th>Termin płatności</th>
                        <th>Szczegóły</th>
                        <th>Akcja</th>
                      </StyledTr>
                    </thead>
                    <tbody>
                      {invoices.data.map(value => {
                        const {
                          status,
                          id,
                          period,
                          paymentDeadline,
                          amount,
                          number,
                          isPaid,
                          serviceName: serviceName1,
                          created_at,
                        } = value;
                        return (
                          <>
                            <tr>
                              <td style={{ verticalAlign: 'middle' }}>{serviceName1}</td>
                              <td style={{ verticalAlign: 'middle' }}>{number}</td>
                              <td style={{ verticalAlign: 'middle' }}>{status}</td>
                              <td style={{ verticalAlign: 'middle' }}>{paymentDeadline}</td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={this.toggleCollapse(`basicCollapse${id}`)}
                                >
                                  Zobacz <MDBIcon icon="angle-double-right" />
                                </span>
                              </td>
                              <td>
                                {isPaid ? (
                                  ''
                                ) : (
                                  <Button
                                    title="Zapłać"
                                    pay
                                    disabled={disabled}
                                    onClick={() => {
                                      this.setState({
                                        disabled: true,
                                      });
                                      const { payInvoice, token } = this.props;
                                      payInvoice(token, id).then(response => {
                                        if (response.url) {
                                          const win = window.open(response.url, '_blank');
                                          win.focus();
                                        } else {
                                          toast.error(response.data.message);
                                        }
                                        this.setState({
                                          disabled: false,
                                        });
                                      });
                                    }}
                                  >
                                    {disabled ? (
                                      <MDBIcon icon="spinner" spin />
                                    ) : (
                                      <MDBIcon icon="credit-card" />
                                    )}
                                  </Button>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={6}
                                style={{ borderTop: 'none', paddingBottom: 0, paddingTop: 0 }}
                              >
                                <MDBCollapse
                                  id={`basicCollapse${id}`}
                                  isOpen={collapseID === `basicCollapse${id}`}
                                >
                                  <div className="d-flex justify-content-between mr-5 py-3 pr-5 align-items-center">
                                    <div>
                                      <StyledP>Kwota faktury: {amount}</StyledP>
                                      <StyledP>Data wystawienia faktury: {created_at}</StyledP>
                                      <StyledP>Termin płatności: {paymentDeadline}</StyledP>
                                      <StyledP>Okres: {period}</StyledP>
                                      <StyledP>Usługa: {serviceName1}</StyledP>
                                    </div>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      key={`invoice-${id}`}
                                      role="button"
                                      onClick={() => {
                                        const { token } = this.props;
                                        axios
                                          .get(value.url, {
                                            headers: {
                                              Authorization: `Bearer ${token}`,
                                            },
                                            responseType: 'blob',
                                          })
                                          .then(response => {
                                            const { data } = response;
                                            const url = window.URL.createObjectURL(
                                              new Blob([data]),
                                            );
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `${number}.pdf`);
                                            document.body.appendChild(link);
                                            link.click();
                                          });
                                      }}
                                      className="d-flex align-items-center justify-content-center flex-column color-dark-purple"
                                    >
                                      <MDBIcon icon="file-alt" size="4x" />
                                      <p>Pobierz fakturę</p>
                                    </div>
                                  </div>
                                </MDBCollapse>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </MDBTable>
                  {this.pagination()}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </StyledMDBCard>
        </section>
      </UserPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    userData: state.userData,
    invoices: state.invoices,
    errorMessage: state.error,
  };
};

export default connect(mapStateToProps, {
  getData: getInvoicesAction,
  payInvoice: payInvoiceAction,
})(ifAcceptRegulations(Settlement));
