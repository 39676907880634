import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';
import { MDBCardBody, MDBCol, MDBCollapse, MDBIcon, MDBRow, MDBTable, toast } from 'mdbreact';
import { Button } from '../../components/Button';
import { StyledMDBCard } from '../../components/StyledCard';
import { StyledTr } from '../../components/StyledTr';
import { getDashboardDataAdminAction, remindInvoiceAction } from '../../actions';
import AdminPanelTemplate from '../../templates/AdminPanelTemplate';
import { hasScope } from '../../helpers';

const StyledP = styled.p`
margin: 0;
padding: 0;
`;
const StyledMDBIcon = styled(MDBIcon)`
border-radius: 20px!important;
`;

class MainPanel extends Component {

  state = {
    collapseID: null,
  };

  componentDidMount() {
    const { getData, token } = this.props;
    getData(token);

  }

  remind = id => {
    const { remind, token } = this.props;
    remind(token, id)
      .then((response) => {
        if (response === 'sended') {
          toast.success('Powiadomienie zostało wysłane');
        } else {
          toast.error(response);
        }
      });
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }));
  };


  render() {
    const { collapseID } = this.state;
    const { dashboardData, scope } = this.props;
    const { InvoicesNotPaidCount, balance, clients, notPaid, paid } = dashboardData;
    return (
      <AdminPanelTemplate pageTitle="">
        <section>
          <MDBRow>
            <MDBCol xl="6" md="12" className="mb-5">
              <StyledMDBCard cascade className="cascading-admin-card">
                <div className="admin-up d-flex align-items-center justify-content-center">
                  <StyledMDBIcon icon="coins" className="dark-purple mr-5 z-depth-2" style={{ marginTop: '-40px' }}/>
                  <div className="data w-100 ">
                    <h3
                      className="font-weight-bold dark-grey-text text-left pt-3">Saldo: {parseFloat(balance).toFixed(2)} zł</h3>
                    <p className="text-left">Saldo nieopłaconych faktur: {InvoicesNotPaidCount}</p>
                  </div>
                </div>
              </StyledMDBCard>
            </MDBCol>
            <MDBCol xl="6" md="12" className="mb-5">
              <StyledMDBCard cascade className="cascading-admin-card">
                <div className="admin-up d-flex align-items-center justify-content-center">
                  <StyledMDBIcon icon="users" className="dark-purple mr-5 z-depth-2" style={{ marginTop: '-40px' }}/>
                  <div className="data w-100 ">
                    <h3 className="font-weight-bold dark-grey-text text-left pt-3">Klienci: {clients}</h3>
                    <p className="text-left">Liczba klientów systemu JAR</p>
                  </div>
                </div>
              </StyledMDBCard>
            </MDBCol>
          </MDBRow>
        </section>

        {hasScope(scope, 'admin-invoices') &&

        <>
          <section>
            <StyledMDBCard className="mb-5">
              <MDBRow className="my-4">
                <MDBCol md="12">
                  <MDBCardBody className="pt-0">
                    <h6 className="color-dark-purple font-weight-bold">Ostatnie nieopłacone faktury</h6>
                    <MDBTable hover>
                      <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Usługa</th>
                        <th>Klient</th>
                        <th>Numer fakruty</th>
                        <th>Status</th>
                        <th>Termin płatności</th>
                        <th>Szczegóły</th>
                        <th>Akcja</th>
                      </StyledTr>
                      </thead>
                      <tbody>
                      {notPaid.map((value) => {
                        const { id,name, number, paymentDeadline, isPaid, serviceName, status, amount, created_at, period, serviceName: serviceName1 } = value;
                        return <>
                          <tr className="none-top-border">
                            <td style={{ verticalAlign: 'middle' }}>{serviceName}</td>
                            <td style={{ verticalAlign: 'middle' }}>{name}</td>
                            <td style={{ verticalAlign: 'middle' }}>{number}</td>
                            <td style={{ verticalAlign: 'middle' }}>{status}</td>
                            <td style={{ verticalAlign: 'middle' }}>{paymentDeadline}</td>
                            <td style={{ verticalAlign: 'middle' }}>
                            <span style={{ cursor: 'pointer' }}
                                  onClick={this.toggleCollapse(`basicCollapse${number}`)}>
                              Zobacz <MDBIcon icon="angle-double-right"/>
                            </span>
                            </td>
                            <td>
                              {isPaid ? '' : <Button title="Wyślij przypomnienie" pay onClick={() => {
                                this.remind(id);
                              }}>
                                <MDBIcon icon="paper-plane"/>
                              </Button>}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6} style={{ borderTop: 'none', paddingBottom: 0, paddingTop: 0 }}>
                              <MDBCollapse id={`basicCollapse${number}`} isOpen={collapseID}>
                                <div className="d-flex justify-content-between mr-5 py-3 pr-5 align-items-center">
                                  <div>
                                    <StyledP>Kwota faktury: {amount}</StyledP>
                                    <StyledP>Data wystawienia faktury: {created_at}</StyledP>
                                    <StyledP>Termin płatności: {paymentDeadline}</StyledP>
                                    <StyledP>Okres: {period}</StyledP>
                                    <StyledP>Usługa: {serviceName1}</StyledP>
                                  </div>
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    key={`invoice-${id}`}
                                    role="button"
                                    onClick={() => {
                                      const { token } = this.props;
                                      axios.get(value.url, {
                                        headers: {
                                          Authorization: `Bearer ${token}`,
                                        },
                                        responseType: 'blob',
                                      }).then(response => {
                                        const { data } = response;
                                        const url = window.URL.createObjectURL(new Blob([data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `${number}.pdf`);
                                        document.body.appendChild(link);
                                        link.click();
                                      });
                                    }}
                                    className="d-flex align-items-center justify-content-center flex-column color-dark-purple">
                                    <MDBIcon icon="file-alt" size="4x"/>
                                    <p>Pobierz fakturę</p>
                                  </div>
                                </div>
                              </MDBCollapse>
                            </td>
                          </tr>
                        </>;
                      })}
                      </tbody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </StyledMDBCard>
          </section>


          <section>
            <StyledMDBCard className="mb-5">
              <MDBRow className="my-4">
                <MDBCol md="12">
                  <MDBCardBody className="pt-0">
                    <h6 className="color-dark-purple font-weight-bold">Ostatnie opłacone faktury</h6>
                    <MDBTable hover>
                      <thead>
                      <StyledTr className="rgba-stylish-strong white-text">
                        <th>Usługa</th>
                        <th>Klient</th>
                        <th>Numer fakruty</th>
                        <th>Status</th>
                        <th>Termin płatności</th>
                        <th>Zapłacono</th>
                        <th>Szczegóły</th>
                      </StyledTr>
                      </thead>
                      <tbody>
                      {paid.map((value) => {
                        const { id, name,number, paymentDeadline, serviceName, status, amount, created_at, period, serviceName: serviceName1, paid_at:paidAt } = value;
                        return <>
                          <tr className="none-top-border">
                            <td style={{ verticalAlign: 'middle' }}>{serviceName}</td>
                            <td style={{ verticalAlign: 'middle' }}>{name}</td>
                            <td style={{ verticalAlign: 'middle' }}>{number}</td>
                            <td style={{ verticalAlign: 'middle' }}>{status}</td>
                            <td style={{ verticalAlign: 'middle' }}>{paymentDeadline}</td>
                            <td style={{ verticalAlign: 'middle' }}>{paidAt}</td>
                            <td style={{ verticalAlign: 'middle' }}>
                            <span style={{ cursor: 'pointer' }}
                                  onClick={this.toggleCollapse(`basicCollapse${number}`)}>
                              Zobacz <MDBIcon icon="angle-double-right"/>
                            </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6} style={{ borderTop: 'none', paddingBottom: 0, paddingTop: 0 }}>
                              <MDBCollapse id={`basicCollapse${number}`} isOpen={collapseID}>
                                <div className="d-flex justify-content-between mr-5 py-3 pr-5 align-items-center">
                                  <div>
                                    <StyledP>Kwota faktury: {amount}</StyledP>
                                    <StyledP>Data wystawienia faktury: {created_at}</StyledP>
                                    <StyledP>Termin płatności: {paymentDeadline}</StyledP>
                                    <StyledP>Okres: {period}</StyledP>
                                    <StyledP>Usługa: {serviceName1}</StyledP>
                                  </div>
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    key={`invoice-${id}`}
                                    role="button"
                                    onClick={() => {
                                      const { token } = this.props;
                                      axios.get(value.url, {
                                        headers: {
                                          Authorization: `Bearer ${token}`,
                                        },
                                        responseType: 'blob',
                                      }).then(response => {
                                        const { data } = response;
                                        const url = window.URL.createObjectURL(new Blob([data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `${number}.pdf`);
                                        document.body.appendChild(link);
                                        link.click();
                                      });
                                    }}
                                    className="d-flex align-items-center justify-content-center flex-column color-dark-purple">
                                    <MDBIcon icon="file-alt" size="4x"/>
                                    <p>Pobierz fakturę</p>
                                  </div>
                                </div>
                              </MDBCollapse>
                            </td>
                          </tr>
                        </>;
                      })}
                      </tbody>
                    </MDBTable>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </StyledMDBCard>
          </section>
        </>

        }

      </AdminPanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    dashboardData: state.dashboardData,
    errorMessage: state.error,
    scope: state.scope,
  };
};

export default connect(
  mapStateToProps,
  {
    getData: getDashboardDataAdminAction,
    remind: remindInvoiceAction,
  },
)(MainPanel);
