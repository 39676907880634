import React, {Component} from 'react';
import {
    MDBBtnFixed,
    MDBCardBody,
    MDBCol,
    MDBCollapse,
    MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBPageItem,
    MDBPageNav,
    MDBPagination,
    MDBRow,
    MDBSelect,
    MDBTable,
    toast,
} from 'mdbreact';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import {StyledMDBCard} from '../../../components/StyledCard';
import {StyledTr} from '../../../components/StyledTr';
import {Button} from '../../../components/Button';
import AdminPanelTemplate from '../../../templates/AdminPanelTemplate';
import {
    adminAPI,
    deleteInvoiceAction,
    getClientsAllAction,
    getInvoicesAdminAction,
    remindInvoiceAction,
} from '../../../actions';
import DatePicker from '../../../components/DatePicker';
import {setNotPaidInvoiceAction, setPaidInvoiceAction, setVisibleInvoiceAction} from '../../../actions/invoiceActions';
import {hasScope} from '../../../helpers';
import {PiggyBankModal} from "../Modals/piggyBank";
import {syncInvoiceAction} from "../../../actions/insertActions";
import {InvoiceButton} from "../../../components/InvoiceButton";
import {InvoiceVisibleButton} from "../../../components/InvoiceVisibleButton";

const StyledP = styled.p`
  margin: 0;
  padding: 0;
`;

class InvoiceListAdmin extends Component {
    state = {
        amountToPay: 0,
        page: 1,
        perPage: 10,
        // options: [],
        clients: [],
        search: '',
        from: null,
        to: null,
        date: null,
        modal: false,
        redirect: null,
        collapseID: null,
        paid: 0,
    };

    search = _.debounce(() => {
        this.fetch();
    }, 1000);

    componentDidMount() {
        const {location} = this.props;
        if (location.state) {
            this.setState({
                search: location.state.id,
            });
        }
        setTimeout(this.fetch, 100);
    }

    fetch = (getPage = 0) => {
        const {getData, token} = this.props;
        const {page, perPage, search, clients, from, to, paid} = this.state;
        if (getPage) {
            getData(token, {page: getPage, perPage, search, clients, from, to, paid});
        } else {
            getData(token, {page, perPage, search, clients, from, to, paid});
        }
    };

    getPrevPage = () => {
        const {page} = this.state;
        if (page > 1) {
            this.setState({
                page: page - 1,
            });
            this.fetch(page - 1);
        }
    };

    getNextPage = () => {
        const {page} = this.state;
        const {items} = this.props;
        const {last_page} = items;
        if (page < last_page) {
            this.setState({
                page: page + 1,
            });
            this.fetch(page + 1);
        }
    };

    getBuildingsPage = newPage => {
        const {page} = this.state;
        if (page !== newPage) {
            this.setState({
                page: newPage,
            });

            this.fetch(newPage);
        }
    };

    pagination = () => {
        const {items} = this.props;
        const {page} = this.state;
        const {last_page} = items;
        const pagesNum = last_page || 1;

        const pages = [];

        let isBreak = true;

        for (let i = 1; i <= pagesNum; i++) {
            if (i < 4 || i > pagesNum - 3 || (i > page - 2 && i < page + 2)) {
                isBreak = true;
                if (page === i) {
                    pages.push(
                        <MDBPageItem key={i} active>
                            <MDBPageNav
                                className="page-link"
                                onClick={() => {
                                    this.getBuildingsPage(i);
                                }}
                            >
                                {i}
                            </MDBPageNav>
                        </MDBPageItem>,
                    );
                } else {
                    pages.push(
                        <MDBPageItem key={i}>
                            <MDBPageNav
                                className="page-link"
                                onClick={() => {
                                    this.getBuildingsPage(i);
                                }}
                            >
                                {i}
                            </MDBPageNav>
                        </MDBPageItem>,
                    );
                }
            } else if (isBreak) {
                isBreak = false;
                pages.push(
                    <MDBPageItem key={i}>
                        <MDBPageNav>...</MDBPageNav>
                    </MDBPageItem>,
                );
            }
        }

        return (
            <MDBPagination circle className="my-4 float-right">
                <MDBPageItem>
                    <MDBPageNav className="page-link" aria-label="Previous" onClick={this.getPrevPage}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Poprzednia</span>
                    </MDBPageNav>
                </MDBPageItem>
                {pages}
                <MDBPageItem>
                    <MDBPageNav className="page-link" aria-label="Next" onClick={this.getNextPage}>
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Następna</span>
                    </MDBPageNav>
                </MDBPageItem>
            </MDBPagination>
        );
    };

    remind = id => {
        const {remind, token} = this.props;
        remind(token, id).then(response => {
            if (response === 'sended') {
                toast.success('Powiadomienie zostało wysłane');
            } else {
                toast.error(response);
            }
        });
    };

    toggle = (modal = null) => {
        this.setState({
            modal,
            date: null,
        });
    };

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : '',
        }));
    };

    render() {
        const {search, modal, redirect, collapseID, paid, perPage} = this.state;

        if (redirect) {
            return <Redirect to={redirect}/>;
        }

        const {items, scope} = this.props;
        return (
            <AdminPanelTemplate pageTitle="">
                <section>
                    <StyledMDBCard className="mb-5">
                        <MDBRow className="my-4">
                            <MDBCol md="12">
                                <MDBCardBody className="pt-0">
                                    <h3 className="py-2 color-dark-purple">Lista faktur</h3>

                                    <div>
                                        <Button
                                            color="dark-purple"
                                            onClick={() => {
                                                const {token} = this.props;
                                                const {clients, from, to, page} = this.state;
                                                axios
                                                    .get(`${adminAPI}admin/invoice/getPDF`, {
                                                        params: {
                                                            search,
                                                            clients,
                                                            from,
                                                            to,
                                                            paid,
                                                            page,
                                                        },
                                                        headers: {
                                                            Authorization: `Bearer ${token}`,
                                                        },
                                                        responseType: 'blob',
                                                    })
                                                    .then(response => {
                                                        const {data} = response;
                                                        const url = window.URL.createObjectURL(new Blob([data]));
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.setAttribute('download', `faktury.pdf`);
                                                        document.body.appendChild(link);
                                                        link.click();
                                                    });
                                            }}
                                        >
                                            <MDBIcon icon="file-pdf"/> EXPORTUJ DO PDF WYBRANE FV
                                        </Button>

                                        <Button
                                            color="dark-purple"
                                            onClick={() => {
                                                const {token} = this.props;
                                                const {clients, from, to, page} = this.state;
                                                axios
                                                    .get(`${adminAPI}admin/invoice/export`, {
                                                        params: {
                                                            search,
                                                            clients,
                                                            from,
                                                            to,
                                                            paid,
                                                            page,
                                                        },
                                                        headers: {
                                                            Authorization: `Bearer ${token}`,
                                                        },
                                                        responseType: 'blob',
                                                    })
                                                    .then(response => {
                                                        const {data} = response;
                                                        const url = window.URL.createObjectURL(new Blob([data]));
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.setAttribute('download', `export.epp`);
                                                        document.body.appendChild(link);
                                                        link.click();
                                                    });
                                            }}
                                        >
                                            <MDBIcon icon="file"/> EXPORTUJ DO epp WYBRANE FV
                                        </Button>

                                        <Button
                                            color="dark-purple"
                                            onClick={() => {
                                                const {token} = this.props;
                                                const {from, to} = this.state;
                                                axios
                                                    .get(`${adminAPI}admin/invoice/export/xls`, {
                                                        params: {
                                                            search,
                                                            from,
                                                            to,
                                                            paid,
                                                        },
                                                        headers: {
                                                            Authorization: `Bearer ${token}`,
                                                        },
                                                        responseType: 'blob',
                                                    })
                                                    .then(response => {
                                                        const {data} = response;
                                                        const url = window.URL.createObjectURL(
                                                            new Blob([data], {
                                                                type:
                                                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                            }),
                                                        );
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.setAttribute('download', `exportFV.xls`);
                                                        document.body.appendChild(link);
                                                        link.click();
                                                    });
                                            }}
                                        >
                                            <MDBIcon icon="file-pdf"/> EXPORTUJ DO XLS WYBRANE FV
                                        </Button>

                                        <InvoiceButton syncInvoices={(date) => {
                                            const {token} = this.props;

                                            return axios.post(`${adminAPI}admin/insert/sync/all/service`, {date}, {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                }
                                            }).then(() => {
                                                this.fetch();
                                            });
                                        }}/>

                                        <InvoiceVisibleButton actionInvoices={(date, type) => {
                                            const {token} = this.props;

                                            return axios.put(`${adminAPI}admin/invoice/setInvoicesVisible`, {date, type}, {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                }
                                            }).then(() => {
                                                this.fetch();
                                            });
                                        }}/>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DatePicker
                                            getValue={value => {
                                                this.setState({
                                                    from: value.getTime() / 1000,
                                                });
                                                setTimeout(this.fetch, 100);
                                            }}
                                        />
                                        <DatePicker
                                            getValue={value => {
                                                this.setState({
                                                    to: value.getTime() / 1000,
                                                });
                                                setTimeout(this.fetch, 100);
                                            }}
                                        />

                                        <MDBSelect
                                            options={[
                                                {
                                                    value: 0,
                                                    text: 'Wszystkie',
                                                    checked: paid === 0,
                                                },
                                                {
                                                    value: 1,
                                                    text: 'Opłacone',
                                                    checked: paid === 1,
                                                },
                                                {
                                                    value: 2,
                                                    text: 'Nieopłacone',
                                                    checked: paid === 2,
                                                },
                                            ]}
                                            label="Wybierz status"
                                            getValue={p => {
                                                if (p[0] !== paid) {
                                                    this.setState({paid: p[0]});
                                                    setTimeout(this.fetch, 100);
                                                }
                                            }}
                                        />

                                        <MDBSelect
                                            options={[
                                                {
                                                    value: 10,
                                                    text: '10',
                                                    checked: perPage === 10,
                                                },
                                                {
                                                    value: 20,
                                                    text: '20',
                                                    checked: perPage === 20,
                                                },
                                                {
                                                    value: 30,
                                                    text: '30',
                                                    checked: perPage === 30,
                                                },
                                            ]}
                                            label="Ilość na stronie"
                                            getValue={p => {
                                                if (p[0] !== perPage) {
                                                    this.setState({perPage: p[0]});
                                                    setTimeout(this.fetch, 100);
                                                }
                                            }}
                                        />

                                        <MDBInput
                                            label="Szukaj..."
                                            name="search"
                                            value={search}
                                            getValue={this.search}
                                            onChange={e => {
                                                this.setState({search: e.target.value});
                                            }}
                                        />
                                    </div>

                                    <MDBTable hover>
                                        <thead>
                                        <StyledTr className="rgba-stylish-strong white-text">
                                            <th>Nabywca</th>
                                            <th>Data zapłaty</th>
                                            <th>Numer fakruty</th>
                                            <th>Status</th>
                                            <th>Insert</th>
                                            <th>Widoczność</th>
                                            <th>Termin płatności</th>
                                            <th>Szczegóły</th>
                                            <th>Akcja</th>
                                        </StyledTr>
                                        </thead>
                                        <tbody>
                                        {items.data.map(value => {
                                            const {
                                                id,
                                                number,
                                                name,
                                                amount,
                                                created_at,
                                                paid_at: paidAt,
                                                period,
                                                paymentDeadline,
                                                isPaid,
                                                serviceName,
                                                status,
                                                isDeleted,
                                                isPaidOffline,
                                                phone,
                                                street,
                                                city,
                                                postCode,
                                                order_ref,
                                                visibility
                                            } = value;
                                            return (
                                                <>
                                                    <tr key={id} className="none-top-border">
                                                        <td style={{verticalAlign: 'middle'}}>{name}</td>
                                                        <td style={{verticalAlign: 'middle'}}>{paidAt}</td>
                                                        <td style={{verticalAlign: 'middle'}}>{number}</td>
                                                        <td style={{verticalAlign: 'middle'}}>{status}</td>
                                                        <td style={{verticalAlign: 'middle'}}>{order_ref ? 'Tak' : 'Nie'}</td>
                                                        <td style={{verticalAlign: 'middle'}}>{visibility ? 'Tak' : 'Nie'}</td>
                                                        <td style={{verticalAlign: 'middle'}}>{paymentDeadline}</td>
                                                        <td style={{verticalAlign: 'middle'}}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    onClick={this.toggleCollapse(`basicCollapse${id}`)}
                                >
                                  Zobacz <MDBIcon icon="angle-double-right"/>
                                </span>
                                                        </td>
                                                        <td>
                                                            {isDeleted ? (
                                                                <span>Usunięta</span>
                                                            ) : (
                                                                <>
                                                                    {isPaid ? (
                                                                        <>
                                                                            {isPaidOffline &&
                                                                            hasScope(scope, 'admin-invoice-set-paid') && (
                                                                                <>
                                                                                    <Button
                                                                                        title="Oznacz jako nieopłacona"
                                                                                        pay
                                                                                        onClick={() => {
                                                                                            this.toggle(`notPay${id}`);
                                                                                        }}
                                                                                    >
                                                                                        <MDBIcon icon="times"/>
                                                                                    </Button>
                                                                                    <MDBModal
                                                                                        isOpen={modal === `notPay${id}`}
                                                                                        toggle={this.toggle}
                                                                                    >
                                                                                        <MDBModalHeader
                                                                                            toggle={this.toggle}>
                                                                                            Oznaczyć jako nieopłacona?
                                                                                        </MDBModalHeader>
                                                                                        <MDBModalFooter>
                                                                                            <Button
                                                                                                className="btn-default"
                                                                                                onClick={this.toggle}
                                                                                            >
                                                                                                Anuluj
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="btn-default"
                                                                                                onClick={() => {
                                                                                                    const {
                                                                                                        setNotPaid,
                                                                                                        token
                                                                                                    } = this.props;
                                                                                                    setNotPaid(token, id).then(response => {
                                                                                                        if (response === 'set not paid') {
                                                                                                            toast.success('Opłacone');
                                                                                                            this.fetch();
                                                                                                        } else {
                                                                                                            toast.error(response);
                                                                                                        }
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                Tak
                                                                                            </Button>
                                                                                        </MDBModalFooter>
                                                                                    </MDBModal>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {hasScope(scope, 'admin-invoice-send-remind') && (
                                                                                <Button
                                                                                    title="Wyślij przypomnienie"
                                                                                    pay
                                                                                    onClick={() => {
                                                                                        this.remind(id);
                                                                                    }}
                                                                                >
                                                                                    <MDBIcon icon="paper-plane"/>
                                                                                </Button>
                                                                            )}

                                                                            {hasScope(scope, 'admin-invoice-set-paid') && (
                                                                                <>
                                                                                    <Button
                                                                                        title="Oznacz jako zapłacone"
                                                                                        pay
                                                                                        onClick={() => {
                                                                                            this.toggle(`pay${id}`);
                                                                                        }}
                                                                                    >
                                                                                        <MDBIcon icon="check"/>
                                                                                    </Button>
                                                                                    <MDBModal
                                                                                        isOpen={modal === `pay${id}`}
                                                                                        toggle={this.toggle}
                                                                                    >
                                                                                        <MDBModalHeader
                                                                                            toggle={this.toggle}>
                                                                                            Oznaczyć jako opłacona?
                                                                                        </MDBModalHeader>
                                                                                        <MDBModalBody>
                                                                                            <DatePicker
                                                                                                disableFuture
                                                                                                getValue={date => {
                                                                                                    this.setState({
                                                                                                        date: date.getTime() / 1000,
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        </MDBModalBody>
                                                                                        <MDBModalFooter>
                                                                                            <Button
                                                                                                className="btn-default"
                                                                                                onClick={this.toggle}
                                                                                            >
                                                                                                Anuluj
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="btn-default"
                                                                                                onClick={() => {
                                                                                                    const {
                                                                                                        setPaid,
                                                                                                        token
                                                                                                    } = this.props;
                                                                                                    const {date} = this.state;
                                                                                                    setPaid(token, id, {date}).then(response => {
                                                                                                        if (response === 'set paid') {
                                                                                                            toast.success('Opłacone');
                                                                                                            this.fetch();
                                                                                                        } else {
                                                                                                            toast.error(response);
                                                                                                        }
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                Tak
                                                                                            </Button>
                                                                                        </MDBModalFooter>
                                                                                    </MDBModal>

                                                                                    <Button
                                                                                        title="Zapłać ze skarbokni"
                                                                                        pay
                                                                                        onClick={() => {
                                                                                            this.toggle(`paypiggybank${id}`);
                                                                                        }}
                                                                                    >
                                                                                        <MDBIcon icon="piggy-bank"/>
                                                                                    </Button>
                                                                                    {modal === `paypiggybank${id}` && (
                                                                                        <PiggyBankModal
                                                                                            modal={modal}
                                                                                            id={id}
                                                                                            token={this.props.token}
                                                                                            toggleModal={this.toggle}
                                                                                            fetch={this.fetch}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            {hasScope(scope, 'admin-invoice-delete') && (
                                                                                <>
                                                                                    <Button
                                                                                        title="Edytuj"
                                                                                        as="a"
                                                                                        href={`/panel/admin/faktury/${id}`}
                                                                                        className="btn btn-default Ripple-parent"
                                                                                        pay
                                                                                    >
                                                                                        <MDBIcon icon="pen"/>
                                                                                    </Button>

                                                                                    <Button
                                                                                        title="Usuń"
                                                                                        pay
                                                                                        onClick={() => {
                                                                                            this.toggle(id);
                                                                                        }}
                                                                                    >
                                                                                        <MDBIcon icon="trash-alt"/>
                                                                                    </Button>
                                                                                    <MDBModal isOpen={modal === id}
                                                                                              toggle={this.toggle}>
                                                                                        <MDBModalHeader
                                                                                            toggle={this.toggle}>
                                                                                            Usunąć?
                                                                                        </MDBModalHeader>
                                                                                        <MDBModalFooter>
                                                                                            <Button
                                                                                                className="btn-default"
                                                                                                onClick={this.toggle}
                                                                                            >
                                                                                                Anuluj
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="btn-default"
                                                                                                onClick={() => {
                                                                                                    const {
                                                                                                        deleteItem,
                                                                                                        token
                                                                                                    } = this.props;
                                                                                                    deleteItem(token, id).then(() => {
                                                                                                        this.fetch();
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                Usuń
                                                                                            </Button>
                                                                                        </MDBModalFooter>
                                                                                    </MDBModal>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {hasScope(scope, 'admin-invoice-set-paid') && (
                                                                        <>
                                                                            <Button
                                                                                title="Wyślij do Insert"
                                                                                pay
                                                                                onClick={() => {
                                                                                    this.toggle(`insert${id}`);
                                                                                }}
                                                                            >
                                                                                <MDBIcon icon="sync"/>
                                                                            </Button>
                                                                            <MDBModal
                                                                                isOpen={modal === `insert${id}`}
                                                                                toggle={this.toggle}
                                                                            >
                                                                                <MDBModalHeader toggle={this.toggle}>
                                                                                    Wysłać do Insert GT ?
                                                                                </MDBModalHeader>
                                                                                <MDBModalFooter>
                                                                                    <Button
                                                                                        className="btn-default"
                                                                                        onClick={this.toggle}
                                                                                    >
                                                                                        Anuluj
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="btn-default"
                                                                                        onClick={() => {
                                                                                            const {
                                                                                                syncInvoice,
                                                                                                token
                                                                                            } = this.props;
                                                                                            syncInvoice(token, id).then(() => {
                                                                                                this.fetch();
                                                                                                this.toggle();
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        Tak
                                                                                    </Button>
                                                                                </MDBModalFooter>
                                                                            </MDBModal>
                                                                        </>
                                                                    )}

                                                                    {hasScope(scope, 'admin-invoice-set-paid') && (
                                                                        <>
                                                                            <Button
                                                                                title={visibility ? "Ukryj" : "Ustaw jako widoczne dla klienta"}
                                                                                pay
                                                                                onClick={() => {
                                                                                    this.toggle(`visibility${id}`);
                                                                                }}
                                                                            >
                                                                                <MDBIcon icon="bell"/>
                                                                            </Button>
                                                                            <MDBModal
                                                                                isOpen={modal === `visibility${id}`}
                                                                                toggle={this.toggle}
                                                                            >
                                                                                <MDBModalHeader toggle={this.toggle}>
                                                                                    {visibility ? (
                                                                                        <span> Czy ukryć fakturę klienta ?</span>
                                                                                    ): (
                                                                                        <span> Czy ustawić fakturę jako widoczną dla klienta ?</span>
                                                                                    )}

                                                                                </MDBModalHeader>
                                                                                <MDBModalFooter>
                                                                                    <Button
                                                                                        className="btn-default"
                                                                                        onClick={this.toggle}
                                                                                    >
                                                                                        Anuluj
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="btn-default"
                                                                                        onClick={() => {
                                                                                            const {
                                                                                                setVisible,
                                                                                                token
                                                                                            } = this.props;
                                                                                            setVisible(token, id).then(() => {
                                                                                                this.fetch();
                                                                                                this.toggle();
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        Tak
                                                                                    </Button>
                                                                                </MDBModalFooter>
                                                                            </MDBModal>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            colSpan={6}
                                                            style={{borderTop: 'none', paddingBottom: 0, paddingTop: 0}}
                                                        >
                                                            <MDBCollapse
                                                                id={`basicCollapse${id}`}
                                                                isOpen={collapseID === `basicCollapse${id}`}
                                                            >
                                                                <div
                                                                    className="d-flex justify-content-between mr-5 py-3 pr-5 align-items-center">
                                                                    <div>
                                                                        <StyledP>Kwota faktury: {amount}</StyledP>
                                                                        <StyledP>Data wystawienia
                                                                            faktury: {created_at}</StyledP>
                                                                        <StyledP>Termin
                                                                            płatności: {paymentDeadline}</StyledP>
                                                                        <StyledP>Okres: {period}</StyledP>
                                                                        <StyledP>Telefon: {phone}</StyledP>
                                                                        <StyledP>
                                                                            Adres: {street}, {postCode} {city}
                                                                        </StyledP>
                                                                        <StyledP>Usługa: {serviceName}</StyledP>
                                                                    </div>
                                                                    <div
                                                                        style={{cursor: 'pointer'}}
                                                                        key={`invoice-${id}`}
                                                                        role="button"
                                                                        onClick={() => {
                                                                            const {token} = this.props;
                                                                            axios
                                                                                .get(value.url, {
                                                                                    headers: {
                                                                                        Authorization: `Bearer ${token}`,
                                                                                    },
                                                                                    responseType: 'blob',
                                                                                })
                                                                                .then(response => {
                                                                                    const {data} = response;
                                                                                    const url = window.URL.createObjectURL(
                                                                                        new Blob([data]),
                                                                                    );
                                                                                    const link = document.createElement('a');
                                                                                    link.href = url;
                                                                                    link.setAttribute('download', `${number}.pdf`);
                                                                                    document.body.appendChild(link);
                                                                                    link.click();
                                                                                });
                                                                        }}
                                                                        className="d-flex align-items-center justify-content-center flex-column color-dark-purple"
                                                                    >
                                                                        <MDBIcon icon="file-alt" size="4x"/>
                                                                        <p>Pobierz fakturę</p>
                                                                    </div>
                                                                </div>
                                                            </MDBCollapse>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                        </tbody>
                                    </MDBTable>
                                    {this.pagination()}

                                    {hasScope(scope, 'admin-invoice-add') && (
                                        <MDBBtnFixed
                                            onClick={() => {
                                                this.setState({
                                                    redirect: '/panel/admin/faktury/dodaj',
                                                });
                                            }}
                                            floating
                                            size="lg"
                                            color="dark-purple"
                                            icon="plus"
                                            style={{bottom: '45px', right: '24px'}}
                                        />
                                    )}
                                </MDBCardBody>
                            </MDBCol>
                        </MDBRow>
                    </StyledMDBCard>
                </section>
            </AdminPanelTemplate>
        );
    }
}

const mapStateToProps = state => {
        return {
            token: state.token,
            items: state.invoices,
            clientsAll: state.clientsAll,
            scope: state.scope,
        };
    }
;

export default connect(mapStateToProps,
    {
        getData: getInvoicesAdminAction,
        remind
:
remindInvoiceAction,
    getClientsAll
:
getClientsAllAction,
    deleteItem
:
deleteInvoiceAction,
    setPaid
:
setPaidInvoiceAction,
    setNotPaid
:
setNotPaidInvoiceAction,
    syncInvoice
:
syncInvoiceAction,
    setVisible:setVisibleInvoiceAction
}
)
(InvoiceListAdmin);
