import axios from 'axios';
import { GETREGULATIONS, GETREGULATION, TOKENEXPIRED, adminAPI } from './index';

const url = `admin/regulations`;


export function getRegulationsAction(token, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETREGULATIONS, regulations: res.data.regulations });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
    }
  };
}

export function createRegulationAction(token, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.post(`${adminAPI}${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}

export function getRegulationAction(token, id, params = {}) {
  return async dispatch => {
    try {
      const res = await axios.get(`${adminAPI}${url}/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: GETREGULATION, regulation: res.data.regulations });
      return res.data.regulations;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return [];
    }
  };
}

export function updateRegulationAction(token, id, data = {}) {
  return async dispatch => {
    try {
      const res = await axios.put(`${adminAPI}${url}/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data.status;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: TOKENEXPIRED });
      }
      return error.response.data.errors;
    }
  };
}